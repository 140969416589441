import { Row, Col, message, Modal, Table, Avatar, Pagination } from 'antd'
import './index.scss'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { luckLists } from '@/request/api/index'
import { getGameName, shortNumber } from '@/utils'

let indexArr = [2, 5, 8, 11, 14, 17, 3, 6, 9, 12, 15, 18, 4, 7, 10, 13, 16, 19]
const LuckGameList = React.forwardRef((props, ref) => {
  const [search] = useSearchParams()
  const navigate = useNavigate()


  useEffect(() => {
    // 获取数据
    luckListsFun({
      page: 1
    })
  }, [search])



  // 组件已挂载，可以安全使用useImperativeHandle
  useImperativeHandle(ref, () => ({
    // 自定义的ref行为
    childMethod: () => {
      // 子组件的方法
      luckListsFun({
        page
      })
    }
  }))


  // 数据列表
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(0)
  const luckListsFun = async (params) => {
    const res = await luckLists({
      type: search.get('type'),
      page: params.page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setList(res.data.list)
      setPage(params.page)
      setTotal(res.data.total)
      setLimit(res.data.limit)
    }
  }

  // 校验
  const [isShowCheck, setIsShowCheck] = useState(false)
  const [curInfo, setCurInfo] = useState({ source: [] })

  const [sumArr, setSumArr] = useState([0, 0, 0])
  const showCheckModal = (clickItem) => {
    let _cur = { ...clickItem }
    _cur.source = _cur.source.split(',')
    indexArr.map((item, index) => {
      if (index < 6) {
        sumArr[0] += +_cur.source[item - 1]
      }
      if (index >= 6 && index < 12) {
        sumArr[1] += +_cur.source[item - 1]
      }
      if (index >= 12 && index < 18) {
        sumArr[2] += +_cur.source[item - 1]
      }
    })
    setSumArr(sumArr)
    setCurInfo({ ..._cur })
    setIsShowCheck(true)
  }
  const hideCheckModal = () => {
    setIsShowCheck(false)
    setSumArr([0, 0, 0])
    setCurInfo({ source: [] })
  }


  const toLink = (path) => {
    // window.location.href = path
    navigate(path)
  }

  return (
    <>
      <Table className='luck-table'
        columns={[
          {
            title: '期号',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: '开奖时间',
            dataIndex: 'win_time',
            key: 'win_time',
          },
          {
            title: '开奖号码',
            dataIndex: 'source',
            key: 'source',
            render: (text, item) => {
              if (item.status != 1) {
                return '— —'
              } else {
                return <>
                  <span className={`table-num ${search.get('type')}`}>{item.num1}</span>
                  <span className='table-minus'>+</span>
                  <span className={`table-num ${search.get('type')}`}>{item.num2}</span>
                  <span className='table-minus'>+</span>
                  <span className={`table-num ${search.get('type')}`}>{item.num3}</span>
                  <span className='table-minus'>=</span>
                  <span className={`table-result ${search.get('type')}`}>{item.num}</span>
                  <span className={`table-check ${search.get('type')}`} onClick={() => { showCheckModal(item) }}>校验</span>
                </>
              }
            }
          },
          {
            title: '奖池金豆',
            dataIndex: 'tot_bet',
            key: 'tot_bet',
          },
          {
            title: '猜中人数',
            dataIndex: 'winners',
            key: 'winners',
          },
          {
            title: '投注｜中奖',
            dataIndex: 'address',
            key: 'address',
            render: (text, item) => {
              return <><span>{shortNumber(item.bet)}</span> | <span className={item.prize > 0 ? `red` : ''}>{shortNumber(item.prize)}</span></>
            }
          },
          {
            title: '投注',
            dataIndex: 'status',
            key: 'status',
            render: (text, item) => {
              if (text == 1) {
                return <div className='finish'>已结束</div>
              } else {
                return <div className={`bet-btn ${search.get('type')}`} onClick={() => toLink(`/luckBet?type=${search.get('type')}&id=${item.id}&r=${new Date().getTime()}`)}>立即投注</div>
              }
            }
          }
        ]}
        dataSource={list}
        pagination={false}>
      </Table>
      {list.length != 0 && <Pagination className={`luck-page ${search.get('type')}`} current={page} pageSize={limit} total={total} onChange={(page) => { luckListsFun({ page }) }} pageSizeOptions={[]} />}
      {/* 校验 */}
      <Modal className='luckGame-modal'
        title=""
        open={isShowCheck}
        footer={null}
        onCancel={hideCheckModal}
        width={960}
        centered
        destroyOnClose={true}>
        <div className='modal-title'>【{getGameName()}】</div>
        <div className='check-box'>
          <div className={`tabel-title ${search.get('type')}`}>【{getGameName()}】 第 <span>{curInfo.id}</span> 期 官网开奖号码 20个</div>
          <Row className='table-row1'>
            <Col>号码</Col>
            {
              curInfo.source.map(item => {
                return <Col id={`source${item}`} key={`source${item}`}>{item}</Col>
              })
            }
          </Row>
          <Row className='table-row2'>
            <Col>玩法</Col>
            <Col>分 <span className={`light ${search.get('type')}`}>3</span> 区进行计算</Col>
          </Row>
          <Row className='table-row3'>
            <Col>取位</Col>
            {
              indexArr.map(item => {
                return <Col id={`index${item}`} key={`index${item}`}>{item}</Col>
              })
            }
          </Row>
          <Row className='table-row3'>
            <Col>号数</Col>
            {
              indexArr.map(item => {
                return <Col id={`num${item}`} key={`num${item}`}>{curInfo.source[item - 1]}</Col>
              })
            }
          </Row>
          <Row className='table-row4'>
            <Col>求和</Col>
            {
              sumArr.map((item, index) => {
                return <Col id={`sum${index}`} key={`sum${index}`}>{item}</Col>
              })
            }
          </Row>
          <Row className='table-row4'>
            <Col>计算</Col>
            <Col>取尾数</Col>
            <Col>取尾数</Col>
            <Col>取尾数</Col>
          </Row>
          <Row className='table-row4'>
            <Col>结果</Col>
            {
              sumArr.map((item, index) => {
                return <Col id={`last${index}`} key={`last${index}`}>{Math.floor(item % 10)}</Col>
              })
            }
          </Row>
          <Row justify='center' align='middle' className='num-box'>
            <Col className='num-bg1'>{curInfo.num1}</Col>
            <Col className='minus-icon'>+</Col>
            <Col className='num-bg2'>{curInfo.num2}</Col>
            <Col className='minus-icon'>+</Col>
            <Col className='num-bg3'>{curInfo.num3}</Col>
            <Col className='minus-icon'>=</Col>
            <Col className='num-bg4'>{curInfo.num}</Col>
          </Row>
        </div>
      </Modal>
    </>
  )
})

export default LuckGameList