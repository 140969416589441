import { makeAutoObservable } from 'mobx'
import { guessInfo } from '@/request/api'
import { getStylePath, getUrlParam } from '@/utils'


class common28Store {
  uinfo = {
    bank_gold: '',
    gold: '',
    head: '',
    id: '',
    uname: '',
    vip: [],
    down_qrcode: '',
    alert: []
  }

  constructor() {
    makeAutoObservable(this)
  }

  // 获取用户信息
  setUserInfo = async (callBack) => {
    const res = await guessInfo()
    if (res.code == 1) {
      // message.warning(res.msg)
    } else if (res.code == -1) {

    } else {
      this.uinfo = res.data
      callBack && callBack()
    }
  }
}

export default common28Store