// 首页相关接口
import service from ".."

// 获取首页数据
export function indexInfo (params) {
  return service({
    method: 'GET',
    url: "/index/pc",
    params
  })
}