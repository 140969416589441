import { Route, Routes, Navigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { HistoryRouter, history } from '@/utils/history'
import Layout from '@/views/Layout'
const Register = lazy(() => import('@/views/Register'))
const Login = lazy(() => import('@/views/Login'))
const Forget = lazy(() => import('@/views/Forget'))
const Home = lazy(() => import('@/views/Home'))
const Notice = lazy(() => import('@/views/Notice'))
const Rank = lazy(() => import('@/views/Rank'))
const Change = lazy(() => import('@/views/Change'))
const ChangeDetail = lazy(() => import('@/views/ChangeDetail'))
const Invite = lazy(() => import('@/views/Invite'))
const UCenter = lazy(() => import('@/views/UCenter'))
const Activity = lazy(() => import('@/views/Activity'))
const Hbao = lazy(() => import('@/views/Hbao'))
const LuckGameLayout = lazy(() => import('@/views/LuckGameLayout'))
const LuckGameList = lazy(() => import('@/views/LuckGameList'))
const LuckGameBet = lazy(() => import('@/views/LuckGameBet'))
const LuckGameMode = lazy(() => import('@/views/LuckGameMode'))
const LuckGameBetList = lazy(() => import('@/views/LuckGameBetList'))
const LuckGameRule = lazy(() => import('@/views/LuckGameRule'))
const LuckGameAuto = lazy(() => import('@/views/LuckGameAuto'))
const LuckGameTrend = lazy(() => import('@/views/LuckGameTrend'))
function App () {
  return (
    <HistoryRouter history={history}>
      <Suspense
        fallback={
          <div
            style={{
              textAlign: 'center',
              marginTop: 200
            }}
          >
            loading...
          </div>
        }
      >
        <Routes>
          <Route path='/' element={<Layout />} exact={false}>
            <Route index element={<Home />}></Route>
            <Route path='/forget' element={<Forget />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/notice' element={<Notice />}></Route>
            <Route path='/rank' element={<Rank />}></Route>
            <Route path='/change' element={<Change />}></Route>
            <Route path='/changeDetail' element={<ChangeDetail />}></Route>
            <Route path='/invite' element={<Invite />}></Route>
            <Route path='/ucenter' element={localStorage.getItem('txhAuthKey') ? <UCenter /> : <Navigate to="/login" />}></Route>
            <Route path='/activity' element={localStorage.getItem('txhAuthKey') ? <Activity /> : <Navigate to="/login" />}></Route>
            <Route path='/hbao' element={localStorage.getItem('txhAuthKey') ? <Hbao /> : <Navigate to="/login" />}></Route>
            <Route element={localStorage.getItem('txhAuthKey') ? <LuckGameLayout /> : <Navigate to="/login" />} exact={false}>
              <Route path='/luckList' element={<LuckGameList />}></Route>
              <Route path='/luckBet' element={<LuckGameBet />}></Route>
              <Route path='/luckMode' element={<LuckGameMode />}></Route>
              <Route path='/luckBetList' element={<LuckGameBetList />}></Route>
              <Route path='/luckRule' element={<LuckGameRule />}></Route>
              <Route path='/luckAuto' element={<LuckGameAuto />}></Route>
              <Route path='/luckTrend' element={<LuckGameTrend />}></Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </HistoryRouter>
  )
}

export default App
