import axios from 'axios'
import { createBrowserHistory } from 'history'
import { getUrlParam } from '@/utils'
const history = createBrowserHistory()
// window.globalT = Math.floor(Math.random() * 13 * 1000000000000)
window.baseURL = 'https://txh.me'
let service = axios.create({
  baseURL: window.baseURL,
  timeout: 3000
})


// 添加请求拦截器
service.interceptors.request.use((config) => {
  if (getUrlParam('authKey')) {
    localStorage.setItem('txhAuthKey', getUrlParam('authKey'))
  }
  const auth_key = localStorage.getItem('txhAuthKey')
  config.params = { auth_key, restype: 'json', ...config.params }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
  // if (!response.data.code) {
  //   response.data = decrypt(response.data)
  // }

  if (response.data.code == -1) {
    localStorage.removeItem('txhAuthKey')
    if (window.location.pathname == '/' || window.location.pathname == '/notice' || window.location.pathname == '/login' || window.location.pathname == '/register' || window.location.pathname == '/forget' || window.location.pathname == '/rank' || window.location.pathname == '/change' || window.location.pathname == '/changeDetail') {
      return response.data
    }
    window.location.href = '/login'
  }
  return response.data
}, (error) => {
  return Promise.reject(error)
})


// 解密
function decrypt (data) {
  data = data.split('').reverse().join('')
  data = data.substring(data.length - Math.floor(data.length / 3)) + data.substring(0, data.length - Math.floor(data.length / 3))
  data = atob(data)
  if (data.indexOf('%') > -1) {
    data = data.replace(/%/g, '%25')
  }
  data = JSON.parse(decodeURIComponent(data))
  // console.log(data)
  return data
}

export default service