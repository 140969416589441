import { Row, Col, Table, Modal, message, Checkbox, Carousel } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'
import clipboard from 'clipboard'
import './index.scss'
import { vip, dealVip, newChargeInfo, newChargeDeal, dailyChargeInfo, loseRebateInfo, flowRebateInfo, friendRebateInfo, monthRebateInfo } from '@/request/api'
import { useEffect, useState } from 'react'
import bannerImg from '@/assets/activity/banner.png'
import nav1Icon from '@/assets/activity/nav1.png'
import nav2Icon from '@/assets/activity/nav2.png'
import nav3Icon from '@/assets/activity/nav3.png'
import nav4Icon from '@/assets/activity/nav4.png'
import nav5Icon from '@/assets/activity/nav5.png'
import nav6Icon from '@/assets/activity/nav6.png'
import nav7Icon from '@/assets/activity/nav7.png'
import titleIcon from '@/assets/activity/title-icon.png'
import alipayIcon from '@/assets/activity/alipay.png'
import wechatIcon from '@/assets/activity/wechat.png'


function Activity () {
  const { common28Store } = useStore()

  useEffect(() => {
    vipFun()

    const copy = new clipboard('.copy-text')
    copy.on('success', e => {
      if (e.text == '复制已选') {
        message.warning('请先选择要复制的卡密')
        return
      }
      if (e.text == '一键复制') {
        message.warning('本页没有未使用的卡密')
        return
      }
      message.success('复制成功')
    })
    copy.on('error', e => {
      message.error('复制失败')
    })
    return () => {
      copy.destroy()
    }
  }, [])

  let [nav, setNav] = useState('nav1')
  const changeNav = (clickStr) => {
    setNav(clickStr)
    if (clickStr == 'nav1') {
      vipFun()
    } else if (clickStr == 'nav2') {
      newChargeInfoFun()
    } else if (clickStr == 'nav3') {
      dailyChargeInfoFun()
    } else if (clickStr == 'nav4') {
      loseRebateInfoFun()
    } else if (clickStr == 'nav5') {
      monthRebateInfoFun()
    } else if (clickStr == 'nav6') {
      flowRebateInfoFun()
    } else if (clickStr == 'nav7') {
      friendRebateInfoFun()
    }
  }


  // VIP列表
  const [vipList, setVipList] = useState([])
  const [vipRule, setVipRule] = useState([])
  const vipFun = async () => {
    const res = await vip()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setVipList(res.data.vip)
      setVipRule(res.data.rule)
    }
  }
  // 购买弹窗
  const [isShowPayModal, setIsShowPayModal] = useState(false)
  const [curPayObj, setCurPayObj] = useState({})
  const showPayModal = (curItem) => {
    setCurPayObj(curItem)
    setIsShowPayModal(true)
  }
  const hidePayModal = () => {
    setIsShowPayModal(false)
  }
  // 购买
  const [payType, setPayType] = useState('')
  const changePayType = (e) => {
    setPayType(e.target.value)
  }
  const dealVipFun = async () => {
    if (!payType) {
      message.warning('请选择支付方式')
      return
    }
    const res = await dealVip({
      vid: curPayObj.id,
      pay_type: payType
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      vipFun()
      common28Store.setUserInfo()
      hidePayModal()
      setPayType('')
      setCurPayObj('')
      window.open(res.data.pay_url)
    }
  }
  //新人首送
  const [newList, setNewList] = useState([])
  const [newRule, setNewRule] = useState([])
  const newChargeInfoFun = async () => {
    const res = await newChargeInfo()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setNewList(res.data.list)
      setNewRule(res.data.rule)
    }
  }
  // 加入活动弹窗
  const [isShowJoinModal, setIsShowJoinModal] = useState(false)
  const [curJoinObj, setCurJoinObj] = useState({})
  const showJoinModal = (curJoin) => {
    setCurJoinObj(curJoin)
    setIsShowJoinModal(true)
  }
  const hideJoinModal = () => {
    setIsShowJoinModal(false)
  }
  // 加入
  const [newPayType, setNewPayType] = useState('')
  const changeNewPayType = (e) => {
    setNewPayType(e.target.value)
  }
  const newChargeDealFun = async () => {
    const res = await newChargeDeal({
      money: curJoinObj.money.replace(',', ''),
      pay_type: newPayType
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      vipFun()
      common28Store.setUserInfo()
      hidePayModal()
      setPayType('')
      setCurPayObj('')
      window.open(res.data.pay_url)
    }
  }
  // 首充返利
  const [newJoinList, setNewJoinList] = useState([])
  const [newJoinRule, setNewJoinRule] = useState([])
  const dailyChargeInfoFun = async () => {
    const res = await dailyChargeInfo()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setNewJoinList(res.data.list)
      setNewJoinRule(res.data.rule)
    }
  }
  // 亏损返利
  const [loseList, setLoseList] = useState([])
  const [loseRule, setLoseRule] = useState([])
  const loseRebateInfoFun = async () => {
    const res = await loseRebateInfo()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setLoseList(res.data.list)
      setLoseRule(res.data.rule)
    }
  }
  // 投注返利
  const [flowList, setFlowList] = useState([])
  const [flowRule, setFlowRule] = useState([])
  const flowRebateInfoFun = async () => {
    const res = await flowRebateInfo()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setFlowList(res.data.list)
      setFlowRule(res.data.rule)
    }
  }
  // 月度返利
  const [monthList, setMonthList] = useState([])
  const [monthRule, setMonthRule] = useState([])
  const monthRebateInfoFun = async () => {
    const res = await monthRebateInfo()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setMonthList(res.data.list)
      setMonthRule(res.data.rule)
    }
  }
  // 下线返利
  const [friendList, setFriendList] = useState([])
  const [friendRule, setFriendRule] = useState([])
  const friendRebateInfoFun = async () => {
    const res = await friendRebateInfo()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setFriendList([{ 'rebate': res.data.rebate }])
      setFriendRule(res.data.rule)
    }
  }

  return (
    <div className='activity-container'>
      <div className="banner-contaner">
        <Carousel autoplay>
          <div className='banner-img'><img src={bannerImg} /></div>
        </Carousel>
      </div>
      <Row className='activity-main' justify='space-between'>
        <Col className='left-nav'>
          <h6 className='nav-title'>活动专区</h6>
          <div className='nav-menu'>
            <Row justify='center' align='middle' className={nav == 'nav1' ? 'current' : ''} onClick={() => { changeNav('nav1') }}>
              <Col><img src={nav1Icon} className='nav-icon' /></Col>
              <Col>VIP月卡</Col>
            </Row>
            <Row justify='center' align='middle' className={nav == 'nav2' ? 'current' : ''} onClick={() => { changeNav('nav2') }}>
              <Col><img src={nav2Icon} className='nav-icon' /></Col>
              <Col>新人首送</Col>
            </Row>
            <Row justify='center' align='middle' className={nav == 'nav3' ? 'current' : ''} onClick={() => { changeNav('nav3') }}>
              <Col><img src={nav3Icon} className='nav-icon' /></Col>
              <Col>首充返利</Col>
            </Row>
            <Row justify='center' align='middle' className={nav == 'nav4' ? 'current' : ''} onClick={() => { changeNav('nav4') }}>
              <Col><img src={nav4Icon} className='nav-icon' /></Col>
              <Col>亏损返利</Col>
            </Row>
            <Row justify='center' align='middle' className={nav == 'nav5' ? 'current' : ''} onClick={() => { changeNav('nav5') }}>
              <Col><img src={nav5Icon} className='nav-icon' /></Col>
              <Col>月度返利</Col>
            </Row>
            <Row justify='center' align='middle' className={nav == 'nav6' ? 'current' : ''} onClick={() => { changeNav('nav6') }}>
              <Col><img src={nav6Icon} className='nav-icon' /></Col>
              <Col>投注返利</Col>
            </Row>
            <Row justify='center' align='middle' className={nav == 'nav7' ? 'current' : ''} onClick={() => { changeNav('nav7') }}>
              <Col><img src={nav7Icon} className='nav-icon' /></Col>
              <Col>下线返利</Col>
            </Row>
          </div>
        </Col>
        {
          nav == 'nav1' &&
          <Col className='right-main'>
            <Row className='right-title' align='middle'>
              <Col><img src={titleIcon} className='title-icon' /></Col>
              <Col>VIP月卡</Col>
            </Row>
            <Row className='vip-box'>
              {
                vipList.map((item, index) => {
                  return (
                    <Col key={`vip${index}`}>
                      <div className='vip-img'><img src={item.icon} /></div>
                      <Row className='vip-btn' justify='space-between' align='middle'>
                        <Col className='price'>售价：{item.price}</Col>
                        {
                          item.status == 1 && <Col className='btn' onClick={() => showPayModal(item)}>续费</Col>
                        }
                        {
                          item.status == 2 && <Col className='btn' onClick={() => showPayModal(item)}>购买</Col>
                        }
                      </Row>
                      <div className='name-box'>{item.name}</div>
                      {
                        item.status == 1 && <div className='vip-time'>{item.expire}到期</div>
                      }
                    </Col>
                  )
                })
              }
            </Row>
            <Row className='activity-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>购买说明</Col>
            </Row>
            <ul className='activity-rule'>
              {
                vipRule.map((item, index) => {
                  return <li dangerouslySetInnerHTML={{ __html: item }} key={`activityRule${index}`}></li>
                })
              }
            </ul>
            {/* 购买 */}
            <Modal className='activity-modal'
              title=""
              open={isShowPayModal}
              footer={null}
              onCancel={hidePayModal}
              width={320}>
              <p className='modal-price'>¥{curPayObj.price}</p>
              <p className='modal-title'>开通会员：</p>
              <Row justify='space-between' className='modal-vip'>
                <Col>{curPayObj.name}</Col>
                <Col>¥{curPayObj.price}/30天</Col>
              </Row>
              <p className='modal-title'>支付方式：</p>
              {
                curPayObj.alipay &&
                <Checkbox className='modal-pay' value={1} onChange={(e) => changePayType(e)} checked={payType == 1}>
                  <img src={alipayIcon} className='pay-icon' />
                  <span>支付宝</span>
                </Checkbox>
              }
              {
                curPayObj.wechat &&
                <Checkbox className='modal-pay' value={2} onChange={(e) => changePayType(e)} checked={payType == 2}>
                  <img src={wechatIcon} className='pay-icon' />
                  <span>微信</span>
                </Checkbox>
              }
              {
                curPayObj.usdt &&
                <Checkbox className='modal-pay' value={3} onChange={(e) => changePayType(e)} checked={payType == 3}>
                  <img src={require('@/assets/activity/usdt.png')} className='pay-icon' />
                  <span>USTD（Trc20）</span>
                </Checkbox>
              }
              <div className='modal-btn' onClick={dealVipFun}>立即支付</div>
            </Modal>
          </Col>
        }
        {
          nav == 'nav2' &&
          <Col className='right-main'>
            <Row className='right-title' align='middle'>
              <Col><img src={titleIcon} className='title-icon' /></Col>
              <Col>新人首送</Col>
            </Row>
            <div className='activity-tip'>新手首次充值，充多少送多少</div>
            <Table className='activity-table'
              columns={[
                {
                  title: '首充金额',
                  dataIndex: 'money',
                  key: 'money'
                },
                {
                  title: '赠送天数',
                  dataIndex: 'day',
                  key: 'day'
                },
                {
                  title: '每日赠送/金豆',
                  dataIndex: 'gold',
                  key: 'gold'
                },
                {
                  title: '有效流水',
                  dataIndex: 'flow',
                  key: 'flow'
                },
                {
                  title: '活动状态',
                  dataIndex: 'status',
                  key: 'status',
                  render: (text, item) => {
                    if (text == 1) {
                      return <div className='table-btn' onClick={() => showJoinModal(item)}>{item.name}</div>
                    }
                    if (text == 2) {
                      return <div className='table-btn disabled'>{item.name}</div>
                    }
                  }
                }
              ]}
              dataSource={newList}
              pagination={false}
            >
            </Table>
            <Row className='activity-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>活动说明</Col>
            </Row>
            <ul className='activity-rule'>
              {
                newRule.map((item, index) => {
                  return <li dangerouslySetInnerHTML={{ __html: item }} key={`newRule${index}`}></li>
                })
              }
            </ul>
            {/* 加入 */}
            <Modal className='activity-modal'
              title=""
              open={isShowJoinModal}
              footer={null}
              onCancel={hideJoinModal}
              width={420}>
              <p className='modal-price'>
                <span>¥</span>
                <span>{curJoinObj.money}</span>
              </p>
              <p className='modal-title'>支付方式：</p>
              {

                <Checkbox className='modal-pay' value={1} onChange={(e) => changeNewPayType(e)} checked={newPayType == 1}>
                  <img src={alipayIcon} className='pay-icon' />
                  <span>支付宝</span>
                </Checkbox>
              }
              {

                <Checkbox className='modal-pay' value={2} onChange={(e) => changeNewPayType(e)} checked={newPayType == 2}>
                  <img src={wechatIcon} className='pay-icon' />
                  <span>微信</span>
                </Checkbox>
              }
              {

                <Checkbox className='modal-pay' value={3} onChange={(e) => changeNewPayType(e)} checked={newPayType == 3}>
                  <img src={alipayIcon} className='pay-icon' />
                  <span>USTD（Trc20）</span>
                </Checkbox>
              }
              <div className='modal-btn' onClick={newChargeDealFun}>立即参加</div>
            </Modal>
          </Col>
        }
        {
          nav == 'nav3' &&
          <Col className='right-main'>
            <Row className='right-title' align='middle'>
              <Col><img src={titleIcon} className='title-icon' /></Col>
              <Col>首充返利</Col>
            </Row>
            <div className='activity-tip'>以每天第一笔充值为准，次日零点自动发放，规则如下：</div>
            <Table className='activity-table'
              columns={[
                {
                  title: '有效流水',
                  dataIndex: 'flow',
                  key: 'flow'
                },
                {
                  title: '返佣比例',
                  dataIndex: 'rebate',
                  key: 'rebate'
                }
              ]}
              dataSource={newJoinList}
              pagination={false}
            >
            </Table>
            <Row className='activity-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>活动说明</Col>
            </Row>
            <ul className='activity-rule'>
              {
                newJoinRule.map((item, index) => {
                  return <li dangerouslySetInnerHTML={{ __html: item }} key={`newJoinRule${index}`}></li>
                })
              }
            </ul>
          </Col>
        }
        {
          nav == 'nav4' &&
          <Col className='right-main'>
            <Row className='right-title' align='middle'>
              <Col><img src={titleIcon} className='title-icon' /></Col>
              <Col>亏损返利</Col>
            </Row>
            <div className='activity-tip'>合计当天游戏投注亏损金豆，次日零点自动发放，规则如下：</div>
            <Table className='activity-table'
              columns={[
                {
                  title: '有效流水',
                  dataIndex: 'flow',
                  key: 'flow'
                },
                {
                  title: '返佣比例',
                  dataIndex: 'rebate',
                  key: 'rebate'
                }
              ]}
              dataSource={loseList}
              pagination={false}
            >
            </Table>
            <Row className='activity-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>活动说明</Col>
            </Row>
            <ul className='activity-rule'>
              {
                loseRule.map((item, index) => {
                  return <li dangerouslySetInnerHTML={{ __html: item }} key={`loseRule${index}`}></li>
                })
              }
            </ul>
          </Col>
        }
        {
          nav == 'nav5' &&
          <Col className='right-main'>
            <Row className='right-title' align='middle'>
              <Col><img src={titleIcon} className='title-icon' /></Col>
              <Col>月度返利</Col>
            </Row>
            <Table className='activity-table'
              columns={[
                {
                  title: '有效流水',
                  dataIndex: 'flow',
                  key: 'flow'
                },
                {
                  title: '金豆奖励',
                  dataIndex: 'prize',
                  key: 'prize'
                }
              ]}
              dataSource={monthList}
              pagination={false}
            >
            </Table>
            <Row className='activity-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>活动说明</Col>
            </Row>
            <ul className='activity-rule'>
              {
                monthRule.map((item, index) => {
                  return <li dangerouslySetInnerHTML={{ __html: item }} key={`monthRule${index}`}></li>
                })
              }
            </ul>
          </Col>
        }
        {
          nav == 'nav6' &&
          <Col className='right-main'>
            <Row className='right-title' align='middle'>
              <Col><img src={titleIcon} className='title-icon' /></Col>
              <Col>投注返利</Col>
            </Row>
            <div className='activity-tip'>合计当天游戏投注金豆，次日零点自动发放，规则如下：</div>
            <Table className='activity-table'
              columns={[
                {
                  title: '会员等级',
                  dataIndex: 'level',
                  key: 'level'
                },
                {
                  title: '返佣比例',
                  dataIndex: 'rebate',
                  key: 'rebate'
                }
              ]}
              dataSource={flowList}
              pagination={false}
            >
            </Table>
            <Row className='activity-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>活动说明</Col>
            </Row>
            <ul className='activity-rule'>
              {
                flowRule.map((item, index) => {
                  return <li dangerouslySetInnerHTML={{ __html: item }} key={`flowRule${index}`}></li>
                })
              }
            </ul>
          </Col>
        }
        {
          nav == 'nav7' &&
          <Col className='right-main'>
            <Row className='right-title' align='middle'>
              <Col><img src={titleIcon} className='title-icon' /></Col>
              <Col>下线返利</Col>
            </Row>
            <div className='activity-tip'>合计邀请好友的游戏投注金豆，次日零点自动发放，规则如下：</div>
            <Table className='activity-table'
              columns={[
                {
                  title: '下线流水返利比例',
                  dataIndex: 'rebate',
                  key: 'rebate'
                }
              ]}
              dataSource={friendList}
              pagination={false}
            >
            </Table>
            <Row className='activity-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>活动说明</Col>
            </Row>
            <ul className='activity-rule'>
              {
                friendRule.map((item, index) => {
                  return <li dangerouslySetInnerHTML={{ __html: item }} key={`friendRule${index}`}></li>
                })
              }
            </ul>
          </Col>
        }
      </Row>
    </div>
  )
}

export default observer(Activity)