import { message, Row, Col, Avatar, Switch, Table, Pagination, Modal, Tooltip } from 'antd'
import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'
import { useEffect, useState } from 'react'
import { hbaoGroupInfo, hbaoMyToday, hbaoMyGrab, hbaoMyRobb, hbaoSetAuto, hbaoInfo, hbaoLast } from '@/request/api'
import { getUrlParam, shortNumber } from '@/utils'
import nameIconWhite from '@/assets/hbao/name-icon-white.png'
import coinIconWhite from '@/assets/hbao/coin-icon.png'
import howSetIcon from '@/assets/hbao/how-set.png'
import nav2ActiveIcon from '@/assets/hbao/nav2-active.png'
import coinIcon from '@/assets/coin-icon-big.png'



// socket初始化
let wsServer = 'wss://txh.me/worker'
let ws = null
let auth_key//测试用uid，正式环境替换为用户auth_key
//红包群id
let qid
//最后一条消息id
let minMsgId = 0
let maxMsgId = 0
let isOut = false
let timeoutObj = null, intervalObj = null
let isHistory = false
// 发红包赔率
let oddsFont = {
  5: 2,
  6: 1.67,
  7: 1.43,
  8: 1.25,
  9: 1.11,
  10: 1
}
let r
let scrollTopNum
let isStop
function Chat (props) {
  const { common28Store, utokenStore } = useStore()

  useEffect(() => {
    scrollTopNum = 0
    isStop = false
    hbaoGroupInfoFun(props.id)
    hbaoMyTodayFun()
    hbaoMyGrabFun({
      page: myGrabPage
    })

    // websocket
    if (!ws) {
      webSocketInit(wsServer, { 'action': 'initauth_key', 'auth_key': localStorage.getItem('txhAuthKey'), 'qid': +props.id, 'maxMsgId': maxMsgId })
    }

    // 其他事件处理...

    return () => {
      isOut = true
      ws.close()
      clearTimeout(timeoutObj)
      clearInterval(intervalObj)
    }
  }, [])

  // 获取红包信息
  const [groupInfo, setGroupInfo] = useState({})
  const hbaoGroupInfoFun = async (id) => {
    const res = await hbaoGroupInfo({
      qid: id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setGroupInfo({ ...groupInfo, ...res.data })
    }
  }

  // 获取我的今日数据
  const [myToday, setMyToday] = useState({})
  const hbaoMyTodayFun = async () => {
    const res = await hbaoMyToday({
      qid: props.id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setMyToday(res.data)
    }
  }

  // 获取抢包记录
  const [myGrab, setMyGrab] = useState([])
  const [myGrabPage, setMyGrabPage] = useState(1)
  const [myGrabTotal, setMyGrabTotal] = useState(0)
  const [myGrabLimit, setMyGrabLimit] = useState(0)
  const hbaoMyGrabFun = async (params) => {
    const { page } = params
    const res = await hbaoMyGrab({
      qid: props.id,
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setMyGrab(res.data.list)
      setMyGrabPage(page)
      setMyGrabTotal(res.data.total)
      setMyGrabLimit(res.data.limit)
    }
  }

  // 获取被抢记录
  const [myRobb, setMyRobb] = useState([])
  const [myRobbPage, setMyRobbPage] = useState(1)
  const [myRobbTotal, setMyRobbTotal] = useState(0)
  const [myRobbLimit, setMyRobbLimit] = useState(0)
  const hbaoMyRobbFun = async (params) => {
    const { page } = params
    const res = await hbaoMyRobb({
      page,
      qid: props.id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setMyRobb(res.data.list)
      setMyRobbPage(page)
      setMyRobbTotal(res.data.total)
      setMyRobbLimit(res.data.limit)
    }
  }
  // 切换记录
  const [recordType, setRecordType] = useState('grab')
  const changeRecordType = (clickType) => {
    if (clickType == recordType) {
      return
    }
    if (clickType == 'grab') {
      hbaoMyGrabFun({
        page: 1
      })
    }
    if (clickType == 'robb') {
      hbaoMyRobbFun({
        page: 1
      })
    }
    setRecordType(clickType)
  }

  const webSocketInit = (service, initObj) => {
    ws = new WebSocket(service)
    ws.onopen = function () {
      ws.send(encrypt(initObj))//传输加密后的数据
    }

    ws.onmessage = function (e) {
      let data = JSON.parse(e.data)
      // console.log("【接收服务端原始数据】：" + data)
      dealMsg(data)
    }
    ws.onclose = function () {
      if (!isOut) {
        //断线重连
        timeoutObj = setTimeout(function () {
          //断线重连需要剔除minMsgId，只有下拉页面获取历史消息需要传此参数
          delete initObj.minMsgId
          initObj['maxMsgId'] = maxMsgId
          webSocketInit(service, initObj)
        }, 1000)
      }
    }
    //心跳 * 保持连接
    intervalObj = setInterval(function () {
      ws.send('ping')
    }, 1000 * 10)
  }


  //接收服务器消息处理
  const [msgArr, setMsgArr] = useState([])
  let msgArrObj = []
  const dealMsg = (data) => {
    let _arr = [...msgArrObj]
    if (data.type == 'history') {
      _arr = [...data.list, ..._arr]
      isHistory = true
    } else if (data.type == 'group_update') {
      if (groupInfo.uname) {
        setGroupInfo({ ...groupInfo, ...data.data })
      }
    } else if (data.type == 'hbao_update') {
      _arr.map(item => {
        if (item.data.hbid == data.data.hbid) {
          item.data.num = data.data.num
          item.data.deal = data.data.deal
          item.data.status = data.data.status
        }
      })
    } else if (data.type == 'nojoin') {//尚未加入本群，退出群聊，返回上一页
      message.warning('尚未加入本群')
      window.history.go(-1)
    } else if (data.type === 'fixout') {//强制用户退出群聊，uid相对应用户不再尝试重连，返回上一页
      ws.close()
      window.history.go(-1)
    } else if (data.type == 'alert') {
      message.warning(data.data)
    } else {
      _arr = [..._arr, data]
    }
    if (_arr.length != 0) {
      //最小消息ID，拉取历史消息用
      if (minMsgId <= 0) minMsgId = _arr[0].id
      else minMsgId = minMsgId < _arr[0].id ? minMsgId : _arr[0].id
      //最大消息ID，断线重连用
      maxMsgId = maxMsgId > _arr[_arr.length - 1].id ? maxMsgId : _arr[_arr.length - 1].id
    }
    msgArrObj = [..._arr]
    setMsgArr([..._arr])
    setTimeout(() => {
      if (isHistory) {
        isHistory = false
      } else {
        if (!isStop) {
          scrollToBottom(document.getElementById('hbao-box'))
        }
      }
    }, 500)
  }

  //传输数据加密方法
  const encrypt = (sendObj) => {
    let sendStr = JSON.stringify(sendObj)
    sendStr = Base64.encode(sendStr)
    //前三个字符位置反转
    sendStr = sendStr.substring(2, 3) + sendStr.substring(1, 2) + sendStr.substring(0, 1) + sendStr.substring(3)

    return sendStr
  }
  //加密用base64
  var Base64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    encode: function (e) {
      var t = ""
      var n, r, i, s, o, u, a
      var f = 0
      e = Base64._utf8_encode(e)
      while (f < e.length) {
        n = e.charCodeAt(f++)
        r = e.charCodeAt(f++)
        i = e.charCodeAt(f++)
        s = n >> 2
        o = (n & 3) << 4 | r >> 4
        u = (r & 15) << 2 | i >> 6
        a = i & 63
        if (isNaN(r)) {
          u = a = 64
        } else if (isNaN(i)) {
          a = 64
        }
        t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
      }
      return t
    },
    decode: function (e) {
      var t = ""
      var n, r, i
      var s, o, u, a
      var f = 0
      e = e.replace(/[^A-Za-z0-9+/=]/g, "")
      while (f < e.length) {
        s = this._keyStr.indexOf(e.charAt(f++))
        o = this._keyStr.indexOf(e.charAt(f++))
        u = this._keyStr.indexOf(e.charAt(f++))
        a = this._keyStr.indexOf(e.charAt(f++))
        n = s << 2 | o >> 4
        r = (o & 15) << 4 | u >> 2
        i = (u & 3) << 6 | a
        t = t + String.fromCharCode(n)
        if (u != 64) {
          t = t + String.fromCharCode(r)
        }
        if (a != 64) {
          t = t + String.fromCharCode(i)
        }
      }
      t = Base64._utf8_decode(t)
      return t
    },
    _utf8_encode: function (e) {
      e = e.replace(/rn/g, "n")
      var t = ""
      for (var n = 0; n < e.length; n++) {
        var r = e.charCodeAt(n)
        if (r < 128) {
          t += String.fromCharCode(r)
        } else if (r > 127 && r < 2048) {
          t += String.fromCharCode(r >> 6 | 192)
          t += String.fromCharCode(r & 63 | 128)
        } else {
          t += String.fromCharCode(r >> 12 | 224)
          t += String.fromCharCode(r >> 6 & 63 | 128)
          t += String.fromCharCode(r & 63 | 128)
        }
      }
      return t
    },
    _utf8_decode: function (e) {
      var t = ""
      var n = 0
      var r = 0
      var c1 = 0
      var c2 = 0
      while (n < e.length) {
        r = e.charCodeAt(n)
        if (r < 128) {
          t += String.fromCharCode(r)
          n++
        } else if (r > 191 && r < 224) {
          c2 = e.charCodeAt(n + 1)
          t += String.fromCharCode((r & 31) << 6 | c2 & 63)
          n += 2
        } else {
          c2 = e.charCodeAt(n + 1)
          var c3 = e.charCodeAt(n + 2)
          t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63)
          n += 3
        }
      }
      return t
    }
  }

  // 红包状态
  const getHbaoBg = (status) => {
    if (status == 1) {
      return ''
    } else if (status == 2) {
      return 'end'
    } else if (status == 3) {
      return 'timeout'
    } else if (status == 4) {
      return 'get'
    }
  }

  // 红包框滚动
  const getHistory = () => {
    var sendObj = { 'action': 'history', 'auth_key': localStorage.getItem('txhAuthKey'), 'qid': +props.id, 'minMsgId': minMsgId }
    ws.send(encrypt(sendObj))
    message.success('数据已更新')
  }

  const scrollToBottom = (element) => {
    element.scrollTop = element.scrollHeight
  }

  // 发红包
  const [isShowHbaoModal, setIsShowHbaoModal] = useState(false)
  const [gold, setGold] = useState(0)
  const [number, setNumber] = useState(0)
  const [mine, setMine] = useState(0)
  const [golds, setGolds] = useState([])
  const showHbaoModal = () => {
    setIsShowHbaoModal(true)
  }
  const hideHbaoModal = () => {
    setGold(0)
    setNumber(0)
    setMine(0)
    setIsShowHbaoModal(false)
  }
  // 上一个红包
  const hbaoLastFun = async () => {
    r = new Date().getTime()
    const res = await hbaoLast({
      qid: getUrlParam('id')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setGolds(res.data.golds)
      setGold(res.data.select.gold)
      setNumber(res.data.select.num)
      setMine(res.data.select.mine)
      showHbaoModal()
    }
  }

  const sendHbaoFun = () => {
    if (gold == 0) {
      message.warning('请选择红包金额')
      return
    }
    if (number == 0) {
      message.warning('请选择红包个数')
      return
    }
    var sendObj = { 'action': 'send_hbao', 'auth_key': localStorage.getItem('txhAuthKey'), 'qid': +props.id, 'number': number, 'gold': gold, 'mine': mine, r }
    ws.send(encrypt(sendObj))
    common28Store.setUserInfo()
    hideHbaoModal()
  }

  //抢红包
  const grabHbaoFun = (hbid) => {
    var sendObj = { 'action': 'grab_hbao', 'auth_key': localStorage.getItem('txhAuthKey'), 'qid': +props.id, 'hbid': hbid }
    ws.send(encrypt(sendObj))
    common28Store.setUserInfo()
    hbaoInfoFun(hbid)
  }
  const [hbaoResult, setHbaoResult] = useState({ list: [], my: [] })
  const [isShowHbaoResult, setIsShowHbaoResult] = useState(false)
  const hbaoInfoFun = async (hbid) => {
    const res = await hbaoInfo({
      hbid
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setHbaoResult(res.data)
      showHbaoResult()
    }
  }
  const showHbaoResult = () => {
    setIsShowHbaoResult(true)
  }
  const hideHbaoResult = () => {
    setIsShowHbaoResult(false)
  }
  const getHbaoResultStatus = (my) => {
    if (!my.gold && !my.lose) {
      return 'end'
    }
    if (my.lose) {
      return 'mine'
    }
    if (my.gold && !my.lose) {
      return 'win'
    }
  }

  // 自动抢红包
  const hbaoSetAutoFun = async (auto) => {
    const res = await hbaoSetAuto({
      qid: props.id,
      auto
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setGroupInfo({ ...groupInfo, auto })

      message.success('设置成功')
    }
  }

  // 滚动
  const scrollFun = (e) => {
    let curScrollTop = e.target.scrollTop
    if (curScrollTop < scrollTopNum) {
      isStop = true
    }
    if (curScrollTop > scrollTopNum && (e.target.scrollTop + e.target.clientHeight - e.target.scrollHeight < 20)) {
      isStop = false
    }

    scrollTopNum = e.target.scrollTop
  }


  return <>
    <Row className='orange-title' justify='space-between' align='mddle'>
      <Col>ID：{groupInfo.qid || groupInfo.id}（{groupInfo.title}）</Col>
      <Col>
        <Row>
          <Col className='header-col'>
            <img src={nameIconWhite} className='icon1' />
            <span>{groupInfo.uname}</span>
          </Col>
          <Col className='header-col'>
            <img src={coinIconWhite} className='icon2' />
            <span>{shortNumber(groupInfo.max_gold)} 金豆</span>
          </Col>
          <Col className='header-col'>
            <img src={nav2ActiveIcon} className='icon3' />
            <span>{groupInfo.online}/{groupInfo.person}</span>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className='game-main' align='top' justify='space-between'>
      <Col className='hbao-box' id='hbao-box' onScroll={scrollFun}>
        <div className='history-tip'><span onClick={getHistory}>点击查看更多历史</span></div>
        {
          msgArr.map((item, index) => {
            if (item.type == 'join' || item.type == 'text') {//玩家入群
              return <>
                {(((index > 0 && item.time - msgArr[index - 1].time) > 60) || index == 0) && <div className='time'>{moment(item.time * 1000).format('YYYY-MM-DD :ss')}</div>}
                <div className='tip'>
                  <span className='orange' dangerouslySetInnerHTML={{ __html: item.data }} ></span>
                  {/* <span>来到房间</span> */}
                </div>
              </>
            } else if (item.type == 'hbao_send') {//发红包 需要根据发红包者的uid判断是自己发红包还是别人发红包
              return <>
                {(((index > 0 && item.time - msgArr[index - 1].time) > 60) || index == 0) && <div className='time'>{moment(item.time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>}
                <Row className={`hbao-item  ${item.uid == common28Store.uinfo.id ? 'mine' : ''
                  }`}>
                  <Col>
                    <Avatar size={44} src={item.data.head} />
                  </Col>
                  <Col>
                    <div className='font1'>
                      {item.uid == common28Store.uinfo.id && <span className='gray'>{moment(item.time * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>}
                      <span>{item.data.uname}</span>
                      {item.uid != common28Store.uinfo.id && <span className='gray'>{moment(item.time * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>}
                    </div>
                    <div className={`hbao-item-bg ${getHbaoBg(item.data.status)}`} onClick={() => grabHbaoFun(item.data.hbid)}>
                      <ul>
                        <li>红包金额：{shortNumber(item.data.gold)}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /> </li>
                        <li>红包数量：{item.data.deal}/{item.data.number}</li>
                        <li>地雷数字：{item.data.mine}</li>
                      </ul>
                      <div className='font2'>ID：{item.data.hbid}</div>
                    </div>
                  </Col>
                </Row>
              </>
            } else if (item.type === 'hbao_grab') {//抢红包 需要根据发红包者的uid判断是自己抢红包还是别人抢红包
              return <div className='tip'>
                <span className='orange'>“{item.data.grab_uname}” </span>
                <span>抢了</span>
                <span className='orange'>{item.data.grab_uid == item.data.send_uid ? '自己' : `“${item.data.send_uname}”`}</span>
                <span>的红包</span>
              </div>
            } else if (item.type === 'logout') {//其他用户主动退出群聊
              return <>
                <div className='tip'>
                  <span className='orange'>“{item.data.uname}” </span>
                  <span>退出房间</span>
                </div>
              </>
            }
          })
        }
      </Col>
      <Col className='hbao-data'>
        <Row justify='space-between' className='hbao-auto-set' align='middle'>
          <Col>
            <span>自动抢红包设置</span>
            <Tooltip placement="bottom" title="自动抢包设置仅对当前群有效">
              <img src={howSetIcon} className='how-set-icon' />
            </Tooltip>

          </Col>
          <Col>
            <Switch checked={groupInfo.auto == 1} onChange={(checked) => hbaoSetAutoFun(checked ? 1 : 2)} />
            {false && groupInfo.auto == 1 && <span className='tip orange'>开启</span>}
            {false && groupInfo.auto != 1 && <span className='tip'>关闭</span>}
          </Col>
        </Row>
        <div className='today-data'>
          <div className='title'>
            <span>今日本群数据</span>
            <span className='font2'>今日盈亏：<span className={myToday.win_lose >= 0 ? 'orange' : 'green'}>{myToday.win_lose}</span> <img className='coin-icon' src={require('@/assets/coin-icon.png')} /></span>
          </div>
          <Row className='today-item'>
            <Col>
              <span>抢红包数：</span>
              <span className='orange'>{myToday.grab_num} 个</span>
            </Col>
            <Col>
              <span>被抢红包：</span>
              <span className='orange'>{myToday.robb_num} 个</span>
            </Col>
          </Row>
          <Row className='today-item'>
            <Col>
              <span>抢金豆数：</span>
              <span className={myToday.grab_gold < 0 ? 'green' : 'orange'}>{myToday.grab_gold} </span>
              <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
            </Col>
            <Col>
              <span>被抢金豆：</span>
              <span className={myToday.robb_gold < 0 ? 'green' : 'orange'}>{myToday.robb_gold} </span>
              <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
            </Col>
          </Row>
          <Row className='today-item'>
            <Col>
              <span>踩雷支出：</span>
              <span className={myToday.mine_lose < 0 ? 'green' : 'orange'}>{myToday.mine_lose} </span>
              <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
            </Col>
            <Col>
              <span>埋雷收入：</span>
              <span className={myToday.mine_win < 0 ? 'green' : 'orange'}>{myToday.mine_win} </span>
              <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
            </Col>
          </Row>
        </div>
        <div className='tab-box'>
          <Row className='tab-items' align='middle'>
            <Col className={recordType == 'grab' ? 'current' : ''} onClick={() => changeRecordType('grab')}>抢包记录</Col>
            <Col className={recordType == 'robb' ? 'current' : ''} onClick={() => changeRecordType('robb')}>被抢记录</Col>
          </Row>
          {
            recordType == 'grab' &&
            <>
              <Table className='hbao-tab-table'
                columns={[
                  {
                    title: '红包ID',
                    dataIndex: 'hbid',
                    key: 'hbid'
                  },
                  {
                    title: '发包者',
                    dataIndex: 'uname',
                    key: 'uname'
                  },
                  {
                    title: '红包金豆',
                    dataIndex: 'gold',
                    key: 'gold',
                    render: (text) => {
                      return <>
                        <span>{text}</span>
                        <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
                      </>
                    }
                  },
                  {
                    title: '盈亏',
                    dataIndex: 'win_lose',
                    key: 'win_lose',
                    render: (text) => {
                      return <>
                        <span className={text < 0 ? 'green' : 'red'}>{text > 0 ? '+' : ''}{text}</span>
                        <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
                      </>
                    }
                  },
                  {
                    title: '抢包时间',
                    dataIndex: 'cdate',
                    key: 'cdate',
                    render: (text) => {
                      return moment(text * 1000).format('MM-DD HH:mm:ss')
                    }
                  }
                ]}
                dataSource={myGrab}
                pagination={false}
              >
              </Table>
              {myGrab.length != 0 && <Pagination size="small" current={myGrabPage} total={myGrabTotal} pageSize={myGrabLimit} onChange={(page) => hbaoMyGrabFun({ page })} />}
            </>
          }
          {
            recordType == 'robb' &&
            <>
              <Table className='hbao-tab-table'
                columns={[
                  {
                    title: '红包ID',
                    dataIndex: 'hbid',
                    key: 'hbid'
                  },
                  {
                    title: '抢包者',
                    dataIndex: 'uname',
                    key: 'uname'
                  },
                  {
                    title: '金豆数',
                    dataIndex: 'gold',
                    key: 'gold',
                    render: (text) => {
                      return <>
                        <span>{text}</span>
                        <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
                      </>
                    }
                  },
                  {
                    title: '盈亏',
                    dataIndex: 'win_lose',
                    key: 'win_lose',
                    render: (text) => {
                      return <>
                        <span className={text < 0 ? 'green' : 'red'}>{text > 0 ? '+' : ''}{text}</span>
                        <img className='coin-icon' src={require('@/assets/coin-icon.png')} />
                      </>
                    }
                  },
                  {
                    title: '抢包时间',
                    dataIndex: 'cdate',
                    key: 'cdate',
                    render: (text) => {
                      return moment(text * 1000).format('MM-DD HH:mm:ss')
                    }
                  }
                ]}
                dataSource={myRobb}
                pagination={false}
              >
              </Table>
              {myRobb.length != 0 && <Pagination size="small" current={myRobbPage} total={myRobbTotal} pageSize={myRobbLimit} onChange={(page) => hbaoMyRobbFun({ page })} />}
            </>
          }
        </div>
        <div className='send-btn' onClick={hbaoLastFun}>
          发个红包
        </div>
      </Col>
    </Row>
    {/* 发红包 */}
    <Modal className='hbao-modal'
      title=""
      open={isShowHbaoModal}
      footer={null}
      onCancel={hideHbaoModal}
      width={520}
      closable={false}
      centered="true">
      <h6 className='modal-title'>发红包</h6>
      <Row className='modal-form' align='top' justify='start'>
        <Col>红包金额：</Col>
        <Col>
          <Row className='modal-num'>
            {
              golds.map(item => {
                return <Col onClick={() => setGold(item)} className={gold == item ? 'current' : ''}>{shortNumber(item)}金豆</Col>
              })
            }
          </Row>
        </Col>
      </Row>
      <Row className='modal-form' align='top' justify='start'>
        <Col>红包个数：</Col>
        <Col>
          <Row className='modal-num'>
            <Col onClick={() => setNumber(5)} className={number == 5 ? 'current' : ''}>5个</Col>
            <Col onClick={() => setNumber(6)} className={number == 6 ? 'current' : ''}>6个</Col>
            <Col onClick={() => setNumber(7)} className={number == 7 ? 'current' : ''}>7个</Col>
            <Col onClick={() => setNumber(8)} className={number == 8 ? 'current' : ''}>8个</Col>
            <Col onClick={() => setNumber(9)} className={number == 9 ? 'current' : ''}>9个</Col>
            <Col onClick={() => setNumber(10)} className={number == 10 ? 'current' : ''}>10个</Col>
          </Row>
          <div className='habo-modal-tip'>踩雷赔率：{oddsFont[number]}</div>
        </Col>
      </Row>
      <Row className='modal-form' align='top' justify='start'>
        <Col>地雷数字：</Col>
        <Col>
          <Row className='modal-dl-num'>
            <Col onClick={() => setMine(0)} className={mine == 0 ? 'current' : ''}>0</Col>
            <Col onClick={() => setMine(1)} className={mine == 1 ? 'current' : ''}>1</Col>
            <Col onClick={() => setMine(2)} className={mine == 2 ? 'current' : ''}>2</Col>
            <Col onClick={() => setMine(3)} className={mine == 3 ? 'current' : ''}>3</Col>
            <Col onClick={() => setMine(4)} className={mine == 4 ? 'current' : ''}>4</Col>
            <Col onClick={() => setMine(5)} className={mine == 5 ? 'current' : ''}>5</Col>
            <Col onClick={() => setMine(6)} className={mine == 6 ? 'current' : ''}>6</Col>
            <Col onClick={() => setMine(7)} className={mine == 7 ? 'current' : ''}>7</Col>
            <Col onClick={() => setMine(8)} className={mine == 8 ? 'current' : ''}>8</Col>
            <Col onClick={() => setMine(9)} className={mine == 9 ? 'current' : ''}>9</Col>
          </Row>
        </Col>
      </Row>
      <Row className='modal-btn' align='middle' justify='center'>
        <Col className='gray-btn' onClick={hideHbaoModal}>取消</Col>
        <Col className='orange-btn' onClick={sendHbaoFun}>发红包</Col>
      </Row>
    </Modal>
    {/* 抢红包 */}
    <Modal className={`hbao-modal rob ${getHbaoResultStatus(hbaoResult.my)}`}
      title=""
      open={isShowHbaoResult}
      footer={null}
      onCancel={hideHbaoResult}
      width={651}
      closable={false}
      centered="true">
      <div className='close-icon' onClick={hideHbaoResult}></div>
      <div className='id-font'>ID：{hbaoResult.hbid}</div>
      <div className='result-font1'>“{hbaoResult.senduser}” 发的红包</div>
      <div className='result-font2' dangerouslySetInnerHTML={{ __html: hbaoResult.tip }}></div>
      <div className='result-font3'>共 {hbaoResult.gold} 金豆 已领{hbaoResult.deal}/{hbaoResult.num}</div>
      <div className='hbao-result-box'>
        {
          hbaoResult.list.map((item, index) => {
            return <Row className='result-item' justify='space-between' align='middle' key={`resultItem${index}`}>
              <Col>
                <Row align='middle'>
                  <Col className='result-avatar'>
                    <Avatar size={26} src={item.head} />
                  </Col>
                  <Col className='result-name'>{item.uname}</Col>
                  <Col className='result-gold'>
                    {
                      item.gold &&
                      <div>
                        <span>{item.gold}</span>
                        <img src={coinIcon} className='coin-icon' />
                      </div>
                    }
                    {
                      item.lose &&
                      <div>
                        <span className='green'>{item.lose}</span>
                        <img src={coinIcon} className='coin-icon' />
                      </div>
                    }
                  </Col>
                </Row>
              </Col>
              <Col className='result-time'>{moment(item.mdate * 1000).format('HH:mm:ss')}</Col>
            </Row>
          })
        }
      </div>
    </Modal>
  </>
}

export default observer(Chat)