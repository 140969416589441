import { Row, Col, message, Modal, Table, Avatar, Pagination } from 'antd'
import './index.scss'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { luckModeLists, luckModeDel } from '@/request/api/index'
import { getGameName, shortNumber } from '@/utils'

const LuckGameMode = React.forwardRef((props, ref) => {
  const [search] = useSearchParams()


  useEffect(() => {
    // 获取数据
    luckModeListsFun({
      page: 1
    })
  }, [])



  // 组件已挂载，可以安全使用useImperativeHandle
  useImperativeHandle(ref, () => ({
    // 自定义的ref行为
    childMethod: () => {
      // 子组件的方法
    }
  }))


  // 数据列表
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(0)
  const luckModeListsFun = async (params) => {
    const res = await luckModeLists({
      type: search.get('type'),
      page: params.page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setList(res.data.list)
      setPage(params.page)
      setTotal(res.data.total)
      setLimit(res.data.limit)
    }
  }

  // 删除
  const [isShowDelete, setIsShowDelete] = useState(false)
  const [curModeObj, setCurModeObj] = useState({ title: '', data: [] })
  const showDelete = (curMode) => {
    setCurModeObj(curMode)
    setIsShowDelete(true)
  }
  const hideDelete = () => {
    setCurModeObj({ title: '', data: [] })
    setIsShowDelete(false)
  }
  const luckModeDelFun = async () => {
    const res = await luckModeDel({
      id: curModeObj.id,
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('删除成功')
      luckModeListsFun({
        page
      })
      hideDelete()
    }
  }

  //查看详情
  const [isShowDetail, setIsShowDetail] = useState(false)
  const showDetail = (curMode) => {
    setCurModeObj(curMode)
    setIsShowDetail(true)
  }
  const hideDetail = () => {
    setCurModeObj({ title: '', data: [] })
    setIsShowDetail(false)
  }

  const navigate = useNavigate()
  const toLink = (path) => {
    navigate(path)
  }

  return (
    <>
      <div className={`mode-add-btn ${search.get('type')}`} onClick={() => toLink(`/luckBet?type=${search.get('type')}`)}>+添加模式</div>
      <Table className='luck-table1'
        columns={[
          {
            title: '模式名称',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: '投注数量',
            dataIndex: 'number',
            key: 'number'
          },
          {
            title: '合计金豆',
            dataIndex: 'total',
            key: 'total',
          },
          {
            title: '详情',
            dataIndex: 'data',
            key: 'data',
            render: (text, item) => {
              return <span className='blue-btn' onClick={() => { showDetail(item) }}>查看详情</span>
            }
          },
          {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => {
              return <>
                <span className='blue-btn' onClick={() => toLink(`/luckBet?type=${search.get('type')}&modeId=${item.id}`)}>编辑</span>
                <span className='red-btn' onClick={() => showDelete(item)}>删除</span>
              </>
            }
          }
        ]}
        dataSource={list}
        pagination={false}>
      </Table>
      {list.length != 0 && <Pagination className={`luck-page ${search.get('type')}`} current={page} pageSize={limit} total={total} onChange={(page) => { luckModeListsFun({ page }) }} pageSizeOptions={[]} />}
      {/* 删除确认框 */}
      <Modal className='luckGame-modal'
        title=""
        open={isShowDelete}
        footer={null}
        onCancel={hideDelete}
        width={413}
        centered
        destroyOnClose={true}>
        <div className='delete-tip'>确定删除模式？</div>
        <Row className='bottom-btns' justify='center'>
          <Col className='orange' onClick={luckModeDelFun}>确认</Col>
          <Col onClick={hideDelete}>取消</Col>
        </Row>
      </Modal>
      {/* 查看详情 */}
      <Modal className='luckGame-modal'
        title=""
        open={isShowDetail}
        footer={null}
        onCancel={hideDetail}
        width={960}
        centered
        destroyOnClose={true}>
        <div className='modal-title'>【{getGameName()}】</div>
        <div className={`bet-mode-title ${search.get('type')}`}><span>【{getGameName()}】  {curModeObj.title} 模式</span></div>
        <Row justify='center' className='num-detail'>
          {
            curModeObj.data.map(item => {
              return <Col id={`modeData${item[0]}`} key={`modeData${item[0]}`}>
                <div>{item[0]}</div>
                <div>{item[1]}</div>
              </Col>
            })
          }
        </Row>
        <div className={`bottom-tip ${search.get('type')}`}>共 <span>{(curModeObj.data.filter(item => item[1] != 0)).length}</span> 注，投注 <span>{shortNumber(curModeObj.total)}</span> 金豆</div>
      </Modal>
    </>
  )
})

export default LuckGameMode