// 扫雷红包相关接口
import service from ".."

// 获取数据
export function hbaoLists (params) {
  return service({
    method: 'GET',
    url: "/hbao/lists",
    params
  })
}

// 获取红包图标
export function hbaoIcons (params) {
  return service({
    method: 'GET',
    url: "/hbao/icons",
    params
  })
}

// 新建群
export function addGroup (params) {
  return service({
    method: 'GET',
    url: "/hbao/add_group",
    params
  })
}

// 口令加群
export function applyByPwd (params) {
  return service({
    method: 'GET',
    url: "/hbao/apply_by_pwd",
    params
  })
}

// 我创建的群
export function hbaoMyList (params) {
  return service({
    method: 'GET',
    url: "/hbao/my_list",
    params
  })
}

// 解散中的群
export function hbaoDisbandList (params) {
  return service({
    method: 'GET',
    url: "/hbao/disband_list",
    params
  })
}

// 申请加群列表
export function hbaoApplyList (params) {
  return service({
    method: 'GET',
    url: "/hbao/apply_list",
    params
  })
}

// 解散
export function hbaoDisband (params) {
  return service({
    method: 'GET',
    url: "/hbao/disband",
    params
  })
}

// 获取群信息
export function hbaoGinfo (params) {
  return service({
    method: 'GET',
    url: "/hbao/ginfo",
    params
  })
}

// 编辑群信息
export function hbaoEditGroup (params) {
  return service({
    method: 'GET',
    url: "/hbao/edit_group",
    params
  })
}

// 入群审核
export function hbaoExamine (params) {
  return service({
    method: 'GET',
    url: "/hbao/examine",
    params
  })
}

// 取消解散群
export function hbaoQuitDisband (params) {
  return service({
    method: 'GET',
    url: "/hbao/quit_disband",
    params
  })
}

// 退出红包群
export function hbaoQuitOut (params) {
  return service({
    method: 'GET',
    url: "/hbao/quit_out",
    params
  })
}

// 申请入群
export function hbaoApply (params) {
  return service({
    method: 'GET',
    url: "/hbao/apply",
    params
  })
}

// 我加入的群
export function hbaoJoinList (params) {
  return service({
    method: 'GET',
    url: "/hbao/join_list",
    params
  })
}

// 我申请的群
export function hbaoMyApply (params) {
  return service({
    method: 'GET',
    url: "/hbao/my_apply",
    params
  })
}

// 群红包统计
export function hbaoStatistic (params) {
  return service({
    method: 'GET',
    url: "/hbao/statistic",
    params
  })
}

// 群红包记录
export function hbaoDetail (params) {
  return service({
    method: 'GET',
    url: "/hbao/detail",
    params
  })
}

// 获取群信息
export function hbaoGroupInfo (params) {
  return service({
    method: 'GET',
    url: "/hbao/group_info",
    params
  })
}

// 获取我的数据
export function hbaoMyToday (params) {
  return service({
    method: 'GET',
    url: "/hbao/my_today",
    params
  })
}

// 获取抢包记录
export function hbaoMyGrab (params) {
  return service({
    method: 'GET',
    url: "/hbao/my_grab",
    params
  })
}

// 获取被抢记录
export function hbaoMyRobb (params) {
  return service({
    method: 'GET',
    url: "/hbao/my_robb",
    params
  })
}

// 自动抢红包
export function hbaoSetAuto (params) {
  return service({
    method: 'GET',
    url: "/hbao/set_auto",
    params
  })
}

// 红包详情
export function hbaoInfo (params) {
  return service({
    method: 'GET',
    url: "/hbao/info",
    params
  })
}

// 红包玩法说明
export function hbaoRule (params) {
  return service({
    method: 'GET',
    url: "/hbao/rule",
    params
  })
}

// 上一个红包
export function hbaoLast (params) {
  return service({
    method: 'GET',
    url: "/hbao/last",
    params
  })
}

// 抢红包记录
export function grabLog (params) {
  return service({
    method: 'GET',
    url: "/hbao/grab_log",
    params
  })
}

// 发红包记录
export function hbaoLog (params) {
  return service({
    method: 'GET',
    url: "/hbao/hbao_log",
    params
  })
}
