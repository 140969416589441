import { Row, Col, message, Pagination, Modal, Input, Form, Button, Empty, Carousel } from 'antd'
import './index.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'
import { inviteInfo, inviteFriends, useHbao } from '@/request/api'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import clipboard from 'clipboard'
import copyIcon from '@/assets/invite/copy-icon.png'
import redIcon from '@/assets/invite/red-icon.png'

function Invite () {
  const { utokenStore } = useStore()
  const [search] = useSearchParams()
  const navigate = useNavigate()
  useEffect(() => {
    // 获取数据
    inviteInfoFun()
    inviteFriendsFun(1)

    const copy = new clipboard('.copy-text')
    copy.on('success', e => {
      message.success('复制成功')
    })
    copy.on('error', e => {
      message.error('复制失败')
    })
    return () => {
      copy.destroy()
    }
  }, [])

  // 首页数据
  let [invite_url, setInviteUrl] = useState('')
  let [invite_img, setInviteImg] = useState('')
  let [charge, setCharge] = useState('')
  let [flow, setFlow] = useState('')
  let [invite_num, setInviteNum] = useState('')
  let [invite_flow, setInviteFlow] = useState('')
  const inviteInfoFun = async () => {
    const res = await inviteInfo()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      const { invite_url, invite_img, charge, flow, invite_num, invite_flow } = res.data
      setInviteUrl(invite_url)
      setCharge(charge)
      setFlow(flow)
      setInviteNum(invite_num)
      setInviteFlow(invite_flow)
      setInviteImg(invite_img)
    }
  }

  // 我的好友
  let [friendsList, setFriendsList] = useState([])
  let [total, setTotal] = useState(0)
  let [page, setPage] = useState(1)
  const inviteFriendsFun = async (page) => {
    const res = await inviteFriends({
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setFriendsList(res.data.list)
      setTotal(res.data.total)
      setPage(page)
    }
  }

  let [isShowDialog, setIsShowDialog] = useState(false)
  const showDialog = () => {
    setIsShowDialog(true)
  }
  const hideDialog = () => {
    setIsShowDialog(false)
  }

  const { common28Store } = useStore()
  const useHbaoFun = async (values) => {
    const res = await useHbao({
      code: values.code
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success(`成功领取${res.data.gold}个金豆`)
      common28Store.setUserInfo()
      hideDialog()
    }
  }

  return (
    <div className='invite-container'>
      <div className="banner-contaner">
        <Carousel autoplay>
          <div className='banner-img'><img src={require('@/assets/invite/banner.jpg')} /></div>
        </Carousel>
      </div>
      <div className='invite-main'>
        <h6 className='title'>邀请返利</h6>
        <Row justify="space-between" className='invite-box-container' align='center'>
          <Col>
            <Row className='invite-title' align='middle'>
              <Col className='title-icon'></Col>
              <Col>返利规则</Col>
            </Row>
          </Col>
          <Col>
            <Row justify='end' align='middle' className='invite-box'>
              <Col>{invite_url}</Col>
              <Col className='blue-btn'>
                <img src={copyIcon} className='invite-icon' />
                <span className='copy-text' data-clipboard-text={invite_url}>复制推广链接</span>
              </Col>
              <Col className='blue-btn'>
                <a href={invite_img} target='_blank'>下载召集令</a>
              </Col>
              <Col className='orange-btn' onClick={showDialog}>
                <img src={redIcon} className='invite-icon' />
                <span>使用红包</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='invite-tip'>邀请好友可获得好友充值返利以及流水返利（含娱乐游戏和扫雷红包），当日流水次日零点自动返；</div>
        <div className='table-box'>
          <table className='rule-table'>
            <tbody>
              <tr>
                <td>开通会员返利</td>
                <td>流水返利</td>
              </tr>
              <tr>
                <td>{charge}</td>
                <td>{flow}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row className='invite-title' align='middle'>
          <Col className='title-icon'></Col>
          <Col>推广奖励</Col>
        </Row>
        <div className='table-box'>
          <table className='rule-table'>
            <tbody>
              <tr>
                <td>邀请总人数</td>
                <td>下级有效流水</td>
              </tr>
              <tr>
                <td>{invite_num}</td>
                <td>{invite_flow}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row className='invite-title' align='middle'>
          <Col className='title-icon'></Col>
          <Col>下级列表</Col>
        </Row>
        <div className='table-box'>
          <table className='rule-table friends'>
            <tbody>
              <tr>
                <td>会员ID</td>
                <td>昵称</td>
                <td>有效流水</td>
              </tr>
              {
                friendsList.map((item, index) => {
                  return (
                    <tr key={`friends${index}`}>
                      <td>{item.id}</td>
                      <td>{item.uname}</td>
                      <td>{item.flow}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          {friendsList.length == 0 && <Empty style={{ marginTop: '50px' }} />}
          {friendsList.length != 0 && <Pagination className='page-box' current={page} total={total} defaultPageSize={20} onChange={(page, pageSize) => inviteFriendsFun(page)} />}
        </div>
      </div>
      < Modal className='ucenter-modal red-dialog'
        title=""
        open={isShowDialog}
        footer={null}
        maskClosable={false}
        onCancel={hideDialog}
        centered
        destroyOnClose={true}>
        <Form className='dialog-form'
          onFinish={useHbaoFun}>
          <Form.Item
            label="红包码："
            name="code"
            rules={[{ required: true, message: '请输入红包码!' }]}
          >
            <Input placeholder='请输入红包码' className='form-input' />
          </Form.Item>
          <Form.Item className='modal-btns'>
            <Button htmlType='submit' type='primary'>确认使用</Button>
            <Button className='gray' onClick={hideDialog}>取消</Button>
          </Form.Item>
        </Form>
      </Modal >
    </div >
  )
}

export default observer(Invite)