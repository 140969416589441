

import { getUrlParam } from "./getUrlParam"
const getGameName = () => {
  let type = getUrlParam('type')
  if (type == 'js30') {
    return '极速30秒'
  }
  if (type == 'xy60') {
    return '比特60秒'
  }
  if (type == 'kx90') {
    return '开心90秒'
  }
  if (type == 'sk120') {
    return '斯洛伐克120秒'
  }
  if (type == 'jnd210') {
    return '加拿大210秒'
  }
  if (type == 'tw300') {
    return '台湾蛋蛋300秒'
  }
}

export {
  getGameName
}