// 推广中心相关接口
import service from ".."

// 获取数据
export function inviteInfo (params) {
  return service({
    method: 'GET',
    url: "/invite/info",
    params
  })
}

// 我的好友
export function inviteFriends (params) {
  return service({
    method: 'GET',
    url: "/invite/friends",
    params
  })
}

// 使用红包
export function useHbao (params) {
  return service({
    method: 'GET',
    url: "/invite/use_hbao",
    params
  })
}
