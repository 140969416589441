import { Row, Col, message } from 'antd'
import './index.scss'
import { useSearchParams, useLocation, useNavigate, Outlet } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import { topInfo } from '@/request/api/index'
import { getGameName } from '@/utils/index'
import LuckGameList from '../LuckGameList'
import LuckGameBet from '../LuckGameBet'
import LuckGameMode from '../LuckGameMode'
import LuckGameBetList from '../LuckGameBetList'
import LuckGameRule from '../LuckGameRule'
import LuckGameAuto from '../LuckGameAuto'
import LuckGameTrend from '../LuckGameTrend'
import moment from 'moment'

let timeCount
let remainder = 0
let sysTime = 0
let timeInterval
function LuckGameLayout () {
  const [search] = useSearchParams()
  const childRef = useRef()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    remainder = 0
    sysTime = 0
    // 获取数据
    topInfoFun()
    timeInterval = setInterval(() => {
      topInfoFun()
      if (childRef.current) {
        childRef.current.childMethod()
      }
    }, 3000)
  }, [location])

  useEffect(() => {
    return () => {
      clearInterval(timeCount)
      clearTimeout(timeInterval)
    }
  }, [location])


  //顶部数据
  const [current, setCurrent] = useState({})
  const [previous, setPrevious] = useState({})
  const [systemTime, setSystemTime] = useState()
  const [autoBet, setAutoBet] = useState()
  const [source, setSource] = useState([])
  const [remainderArr, setRemainderArr] = useState([])
  const topInfoFun = async () => {
    const res = await topInfo({
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      const { current, previous, system_time, auto_bet } = res.data
      // if (current.remainder == 0) {
      //   clearTimeout(timeOutObj)
      //   timeOutObj = setTimeout(() => {
      //     topInfoFun()
      //   }, 3000)
      //   return
      // }
      setCurrent(current)
      setPrevious(previous)
      setSystemTime(system_time)
      setAutoBet(auto_bet)
      setSource(previous.source.split(','))
      remainder = current.remainder
      sysTime = system_time
      clearInterval(timeCount)
      startCount()
      if (childRef.current.isRule) {
        childRef.current.childMethod(previous)
      }
    }
  }


  const startCount = () => {
    setLeftTime(remainder)
    timeCount = setInterval(() => {
      let curCount = remainder - 1

      if (curCount === -1) {
        topInfoFun()
        if (childRef.current) {
          childRef.current.childMethod()
        }
      }
      setLeftTime(curCount == -1 ? 0 : curCount)
      remainder = curCount == -1 ? 0 : curCount
    }, 1000)
  }
  const setLeftTime = (seconds) => {
    let h = [], m = [], s = [], _h = 0, _m = 0, _s = 0
    _h = Math.floor(seconds / 3600)
    if (_h > 9) {
      h = String(_h).split('')
    } else {
      h = [0, _h]
    }
    _m = Math.floor((seconds - _h * 3600) / 60)
    if (_m > 9) {
      m = String(_m).split('')
    } else {
      m = [0, _m]
    }
    _s = seconds - _h * 3600 - _m * 60
    if (_s > 9) {
      s = String(_s).split('')
    } else {
      s = [0, _s]
    }
    setRemainderArr([h[0], h[1], m[0], m[1], s[0], s[1]])
    sysTime = sysTime + 1
    setSystemTime(sysTime)
  }

  const toLink = (path) => {
    // window.location.href = path
    navigate(path)
  }

  return (
    <div className='luckGame-container'>
      <Row className='game-nav' justify='space-between' align='middle'>
        <Col className={search.get('type') == 'js30' ? 'current js30' : 'js30'} onClick={() => toLink(`/luckList?type=js30`)}>极速30秒</Col>
        <Col className={search.get('type') == 'xy60' ? 'current xy60' : 'xy60'} onClick={() => toLink(`/luckList?type=xy60`)}>比特60秒 </Col>
        <Col className={search.get('type') == 'kx90' ? 'current kx90' : 'kx90'} onClick={() => toLink(`/luckList?type=kx90`)}>开心90秒</Col>
        <Col className={search.get('type') == 'sk120' ? 'current sk120' : 'sk120'} onClick={() => toLink(`/luckList?type=sk120`)}>斯洛伐克120秒</Col>
        <Col className={search.get('type') == 'jnd210' ? 'current jnd210' : 'jnd210'} onClick={() => toLink(`/luckList?type=jnd210`)}>加拿大210秒</Col>
        <Col className={search.get('type') == 'tw300' ? 'current tw300' : 'tw300'} onClick={() => toLink(`/luckList?type=tw300`)}>台湾蛋蛋300秒</Col>
      </Row>
      <Row justify='space-between'>
        <Col className='cur-left'>
          <Row justify='space-between' align='middle'>
            <Col className='font1'><span>【{getGameName()}】 第 </span><span className={`orange ${search.get('type')}`}>{previous.id}</span> <span>期 结果</span></Col>
            <Col className='font2'>服务器时间：{systemTime ? moment(systemTime * 1000).format('YYYY-MM-DD HH:mm:ss') : '--'}</Col>
          </Row>
          <Row justify='center' align='middle' className='num-box'>
            <Col className='num-bg1'>{previous.num1}</Col>
            <Col className='minus-icon'>+</Col>
            <Col className='num-bg2'>{previous.num2}</Col>
            <Col className='minus-icon'>+</Col>
            <Col className='num-bg3'>{previous.num3}</Col>
            <Col className='minus-icon'>=</Col>
            <Col className='num-bg4'>{previous.num}</Col>
          </Row>
          <Row justify='space-between' className='num-container'>
            {
              source.map((item, index) => {
                return <Col id={`source${item}`} key={`source${index}`}>{item}</Col>
              })
            }
          </Row>
        </Col>
        <Col className='cur-right'>
          <div className='font1'>
            <span>距离</span> <span>第</span> <span className={`orange ${search.get('type')}`}>{current.id}</span> <span>期 开奖剩</span>
          </div>
          <Row justify='center' align='middle' className='time-box'>
            <Col className={`num ${search.get('type')}`}>{remainderArr[0]}</Col>
            <Col className={`num ${search.get('type')}`}>{remainderArr[1]}</Col>
            <Col className='minus'>:</Col>
            <Col className={`num ${search.get('type')}`}>{remainderArr[2]}</Col>
            <Col className={`num ${search.get('type')}`}>{remainderArr[3]}</Col>
            <Col className='minus'>:</Col>
            <Col className={`num ${search.get('type')}`}>{remainderArr[4]}</Col>
            <Col className={`num ${search.get('type')}`}>{remainderArr[5]}</Col>
          </Row>
          <div className={`tip ${search.get('type')}`}>
            <span>火热投注中</span>
          </div>
        </Col>
      </Row>
      <Row className='menu-box' justify='space-between' align='middle'>
        <Col className={location.pathname == '/luckList' ? `current ${search.get('type')}` : `${search.get('type')}`} onClick={() => toLink(`/luckList?type=${search.get('type')}`)}>往期结果</Col>
        <Col className={location.pathname == '/luckBetList' ? `current ${search.get('type')}` : `${search.get('type')}`} onClick={() => toLink(`/luckBetList?type=${search.get('type')}`)}>投注记录</Col>
        <Col className={location.pathname == '/luckRule' ? `current ${search.get('type')}` : `${search.get('type')}`} onClick={() => toLink(`/luckRule?type=${search.get('type')}`)}>游戏规则</Col>
        <Col className={(location.pathname == '/luckMode') || (location.pathname == '/luckBet' && !search.get('id')) ? `current ${search.get('type')}` : `${search.get('type')}`} onClick={() => toLink(`/luckMode?type=${search.get('type')}`)}>投注模式</Col>
        <Col className={location.pathname == '/luckAuto' ? `current ${search.get('type')}` : `${search.get('type')}`} onClick={() => toLink(`/luckAuto?type=${search.get('type')}`)}><span>自动投注</span>{autoBet == 1 && <span className='status'>已开启</span>}</Col>
        <Col className={location.pathname == '/luckTrend' ? `current ${search.get('type')}` : `${search.get('type')}`} onClick={() => toLink(`/luckTrend?type=${search.get('type')}`)}>游戏走势</Col>
      </Row>
      {/* <Outlet context={{ previous }}></Outlet> */}
      {location.pathname == '/luckList' && <LuckGameList context={location} ref={childRef} />}
      {location.pathname == '/luckBet' && <LuckGameBet context={location} ref={childRef} />}
      {location.pathname == '/luckMode' && <LuckGameMode context={location} ref={childRef} />}
      {location.pathname == '/luckBetList' && <LuckGameBetList context={location} ref={childRef} />}
      {location.pathname == '/luckRule' && <LuckGameRule ref={childRef} />}
      {location.pathname == '/luckAuto' && <LuckGameAuto context={{ topInfo: topInfoFun }} ref={childRef} />}
      {location.pathname == '/luckTrend' && <LuckGameTrend context={location} ref={childRef} />}
    </div>
  )
}

export default LuckGameLayout