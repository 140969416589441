import TopNav from "@/components/TopNav"
import TopLogin from "@/components/TopLogin"
import Footer from "@/components/Footer"
import { Outlet, useLocation } from 'react-router-dom'
import './index.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'
import { ConfigProvider } from 'antd'
import Home from '@/views/Home'
import LuckList from '@/views/LuckGameLayout'
import Hbao from '@/views/Hbao'
import Activity from '@/views/Activity'
import Change from '@/views/Change'
import Rank from '@/views/Rank'
import Invite from '@/views/Invite'
import Ucenter from '@/views/UCenter'

function Layout () {
  const { utokenStore } = useStore()
  const location = useLocation()
  utokenStore.setUtoken()

  const isMenu = () => {
    return location.pathname == '/' || location.pathname == '/luckList' || location.pathname == '/hbao' || location.pathname == '/activity' || location.pathname == '/change' || location.pathname == '/rank' || location.pathname == '/invite' || location.pathname == '/ucenter'
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FF641E',
        },
        components: {
          Button: {
            colorPrimary: '#FF641E',
          },
        },
      }}
    >
      <div className="layout-container">
        {
          <TopLogin utoken={utokenStore.utoken} />
        }
        <TopNav />
        <div className="layout-main">
          {/* <Outlet></Outlet> */}
          {location.pathname == '/' && <Home />}
          {location.pathname == '/luckList' && <LuckList />}
          {location.pathname == '/hbao' && <Hbao />}
          {location.pathname == '/activity' && <Activity />}
          {location.pathname == '/change' && <Change />}
          {location.pathname == '/rank' && <Rank />}
          {location.pathname == '/invite' && <Invite />}
          {location.pathname == '/ucenter' && <Ucenter />}
          {
            !isMenu() && <Outlet></Outlet>
          }
        </div>
        <Footer />
      </div>
    </ConfigProvider>
  )
}

export default observer(Layout)