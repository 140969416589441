import { Row, Col, message, Modal, Table, Avatar, Pagination, Input, Checkbox } from 'antd'
import './index.scss'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { luckBetsInfo, luckPrevious, luckBet, luckModeLists, luckModeAdd, luckModeInfo, luckModePInfo, luckModeEdit } from '@/request/api/index'
import { getGameName, shortNumber } from '@/utils'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'
import { createBrowserHistory } from 'history'
import icon1 from '@/assets/luckGame/icon1.png'
import icon2 from '@/assets/luckGame/icon2.png'
import icon3 from '@/assets/luckGame/icon3.png'
import icon4 from '@/assets/luckGame/icon4.png'
import icon5 from '@/assets/luckGame/icon5.png'
import icon6 from '@/assets/luckGame/icon6.png'
import icon7 from '@/assets/luckGame/icon7.png'


const LuckGameBet = React.forwardRef((props, ref) => {
  const [search] = useSearchParams()
  const { common28Store } = useStore()
  const navigate = useNavigate()

  useEffect(() => {
    // 获取数据
    if (!!search.get('id')) {
      luckBetsInfoFun()
    } else {
      luckModeInfoFun()
    }

  }, [])



  // 组件已挂载，可以安全使用useImperativeHandle
  useImperativeHandle(ref, () => ({
    // 自定义的ref行为
    childMethod: () => {
    }
  }))

  // 获取数据
  const [list, setList] = useState([])
  const [leftList, setLeftList] = useState([])
  const [rightList, setRightList] = useState([])
  const [total, setTotal] = useState(0)
  const luckBetsInfoFun = async () => {
    const res = await luckBetsInfo({
      cid: search.get('id'),
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      res.data.list.map(item => {
        item.curBet = 0
        item.times = 1
      })
      setLists(res.data.list)
    }
  }
  // 模式赔率
  const luckModeInfoFun = async () => {
    const res = await luckModeInfo({
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      res.data.list.map(item => {
        item.curBet = 0
        item.times = 1
      })
      if (search.get('modeId')) {
        luckModePInfoFun([...res.data.list])
      } else {
        setLists(res.data.list)
      }
    }
  }
  // 模式详情
  const luckModePInfoFun = async (curList) => {
    const res = await luckModePInfo({
      type: search.get('type'),
      id: search.get('modeId')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      let modeListNum = res.data.list.data
      curList.map((item, index) => {
        item.curBet = modeListNum[index]
      })
      setLists(curList)
      setModeTitle(res.data.list.title)
    }
  }


  const setLists = (arr) => {
    setLeftList(arr.filter((item, index) => index < 14))
    setRightList(arr.filter((item, index) => index >= 14))
    let sum = 0
    arr.map(item => {
      sum += +item.curBet
    })

    setList(arr)
    setTotal(sum)
  }

  // 专家模式
  let [mode, setMode] = useState('')
  const changeMode = (curMode) => {
    list.forEach(item => {
      item.curBet = 0
      item.times = 1
    })
    setSunNum('')
    if (curMode == mode) {
      setMode('')
      return
    } else {
      setMode(curMode)
    }
    list.forEach((item, index) => {
      if (curMode == 'big') {
        if (index >= list.length / 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'small') {
        if (index < list.length / 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'odd') {
        if (index % 2 != 0) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'even') {
        if (index % 2 == 0) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'middle') {
        if (index > 9 && index < 18) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'side') {
        if (index < 10 || index >= 18) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'oddSide') {
        if ((index < 10 && index % 2 != 0) || (index >= 18 && index % 2 != 0)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'evenSide') {
        if ((index < 10 && index % 2 == 0) || (index >= 18 && index % 2 == 0)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'bigOdd') {
        if (index % 2 != 0 && index >= list.length / 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'smallOdd') {
        if (index % 2 != 0 && index < list.length / 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'bigEven') {
        if (index % 2 == 0 && index >= list.length / 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'smallEven') {
        if (index % 2 == 0 && index < list.length / 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'bigSide') {
        if (index <= 27 && index >= 18) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'smallSide') {
        if (index < 10) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'all') {
        item.curBet = Math.round(1000 / item.odds)
      }
      if (curMode == 'smallEnd') {
        if (index < 5 || (index > 9 && index < 15) || (index > 19 && index < 25)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'bigEnd') {
        if ((index > 4 && index < 10) || (index > 14 && index < 20) || index > 24) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'zeroEnd') {
        if (/0$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'oneEnd') {
        if (/1$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'twoEnd') {
        if (/2$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'threeEnd') {
        if (/3$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fourEnd') {
        if (/4$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fiveEnd') {
        if (/5$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'sixEnd') {
        if (/6$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'sevenEnd') {
        if (/7$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'eightEnd') {
        if (/8$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'nineEnd') {
        if (/9$/.test(index)) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'threeLeft0') {
        if (index % 3 == 0) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'threeLeft1') {
        if (index % 3 == 1) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'threeLeft2') {
        if (index % 3 == 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fourLeft0') {
        if (index % 4 == 0) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fourLeft1') {
        if (index % 4 == 1) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fourLeft2') {
        if (index % 4 == 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fourLeft3') {
        if (index % 4 == 3) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fiveLeft0') {
        if (index % 5 == 0) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fiveLeft1') {
        if (index % 5 == 1) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fiveLeft2') {
        if (index % 5 == 2) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fiveLeft3') {
        if (index % 5 == 3) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
      if (curMode == 'fiveLeft4') {
        if (index % 5 == 4) {
          item.curBet = Math.round(1000 / item.odds)
        }
      }
    })
    setLists(list)
  }


  // 修改checkbox
  const changeCheck = (curItem) => {
    if (curItem.curBet == 0) {
      list.map(item => {
        if (curItem.num == item.num) {
          item.curBet = Math.round(1000 / item.odds)
        }
      })
    } else {
      list.map(item => {
        if (curItem.num == item.num) {
          item.curBet = 0
          item.times = 1
        }
      })
    }
    setMode('')
    setSunNum('')
    setLists(list)
  }

  // 修改单个倍数
  const changeTimes = (curItem, clickTimes) => {
    list.map(item => {
      if (item.num == curItem.num) {
        if (item.times == clickTimes) {
          item.times = 1
          item.curBet = Math.round(1000 / item.odds)
        } else {
          item.times = clickTimes
          item.curBet = Math.round(Math.round(1000 / item.odds) * clickTimes)
        }
      }
    })
    setLists(list)
  }

  // 修改投注数量
  const changeCurBet = (value, curItem) => {
    list.map(item => {
      if (item.num == curItem.num) {
        item.curBet = value
      }
    })
    setMode('')
    setSunNum('')
    setLists(list)
  }

  // 设置倍数
  let [sunNum, setSunNum] = useState('')
  const setMulti = (curMulti) => {
    let curTotal = 0
    let type = curMulti
    let allNum = common28Store.uinfo.gold > 300000000 ? 300000000 : common28Store.uinfo.gold
    if (total == 0) {
      message.warning('请先设置投注金额')
      return
    }
    list.forEach((item, index) => {
      item.curBet = Math.floor(item.curBet * curMulti)
      curTotal += item.curBet

    })
    setLists(list)
  }

  // 梭哈
  const allInFun = (obj) => {
    let { type, num } = obj
    let curTotal = 0
    let allNum = common28Store.uinfo.gold > 300000000 ? 300000000 : common28Store.uinfo.gold
    let setNum = 0
    if (type == 'all') {
      setNum = allNum
    } else if (type == 'set') {
      setNum = sunNum
    } else {
      setNum = num
    }
    if (total == 0) {
      message.warning('请先设置投注金额')
      return
    }
    if (type == 'set') {
      if (sunNum == 0) {
        message.warning('请先设置定额梭哈金额')
        return
      }
    }
    list.forEach((item, index) => {
      item.curBet = Math.floor((item.curBet / total) * setNum)
      curTotal += item.curBet

    })
    if (curTotal < +setNum) {
      let setArr = list.filter(item => item.curBet != 0)
      list.filter(item => item.curBet != 0)[setArr.length - 1].curBet = +list.filter(item => item.curBet != 0)[setArr.length - 1].curBet + (+setNum - curTotal)
    }
    setLists(list)
  }

  // 获取上期信息
  const luckPreviousFun = async () => {
    const res = await luckPrevious({
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setMode('')
      setSunNum('')
      let _list = [...list]
      res.data.list.map((item, index) => {
        _list[index].num = item.no
        _list[index].times = 1
        _list[index].curBet = 0
        if (item.bet != 0) {
          _list[index].curBet = item.bet
        }
      })
      setLists(_list)
    }
  }

  // 反选
  const reverseFun = () => {
    setMode('')
    setSunNum('')
    list.forEach(item => {
      if (item.curBet == 0) {
        item.curBet = Math.round(1000 / item.odds)
        item.times = 1
      } else {
        item.curBet = 0
        item.times = 1
      }
    })
    setLists(list)
  }

  // 清空
  const clearFun = () => {
    setMode('')
    setSunNum('')
    list.forEach(item => {
      item.curBet = 0
      item.times = 1
    })
    setSunNum('')
    setLists(list)
  }

  // 投注
  const luckBetFun = async () => {
    if (total == 0) {
      message.warning('请先设置投注金额')
      return
    }
    let bet_data = []
    list.map(item => {
      bet_data.push(item.curBet)
    })
    const res = await luckBet({
      id: search.get('id'),
      data: JSON.stringify(bet_data),
      type: search.get('type'),
      r: search.get('r')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('投注成功')
      common28Store.setUserInfo()
      navigate(-1)
    }
  }

  // 模式列表
  const [modeList, setModeList] = useState([])
  const [modePage, setModePage] = useState(1)
  const luckModeListsFun = async (params) => {
    const { page } = params
    const res = await luckModeLists({
      page,
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setModeList(res.data.list)
      setModePage(page)
    }
  }
  const [isShowMode, setIsShowMode] = useState(false)
  const showModeModal = () => {
    luckModeListsFun({
      page: 1
    })
    setIsShowMode(true)
  }
  const hideModeModal = () => {
    setIsShowMode(false)
  }

  //选择模式
  const selectMode = (curItem) => {
    const curData = curItem.data

    list.map((item, index) => {
      item.curBet = 0
      item.times = 1
    })
    curData.map(item => {
      list[item[0]].curBet = item[1]
    })
    setLists(list)
    hideModeModal()
  }

  // 添加模式
  const [isShowAdd, setIsShowAdd] = useState(false)
  const showAddMode = () => {
    if (total == 0) {
      message.warning('请先设置投注金额')
      return
    }
    setIsShowAdd(true)
  }
  const hideAddMode = () => {
    if (!search.get('modeId')) {
      setModeTitle('')
    }
    setIsShowAdd(false)
  }
  const [modeTitle, setModeTitle] = useState('')
  const luckModeAddFun = async () => {
    if (!modeTitle) {
      message.warning('请输入模式名称')
      return
    }
    let bet_data = []
    list.map(item => {
      bet_data.push(item.curBet)
    })
    const res = await luckModeAdd({
      type: search.get('type'),
      title: modeTitle,
      data: JSON.stringify(bet_data)
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('添加成功')
      hideAddMode()
      navigate(-1)
    }
  }

  // 编辑模式
  const luckModeEditFun = async () => {
    if (!modeTitle) {
      message.warning('请输入模式名称')
      return
    }
    let bet_data = []
    list.map(item => {
      bet_data.push(item.curBet)
    })
    const res = await luckModeEdit({
      type: search.get('type'),
      title: modeTitle,
      data: JSON.stringify(bet_data),
      id: search.get('modeId')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('保存成功')
      hideAddMode()
      navigate(-1)
    }
  }

  // 点击数字设置投注金额
  const setNum = (curIndex) => {
    list.forEach((item, index) => {
      if (index == curIndex) {
        if (item.curBet == 0) {
          item.curBet = Math.round(1000 / item.odds)
        } else {
          item.curBet = 0
        }
      }
    })
    setLists(list)
  }



  return (
    <div className='bet-container'>
      {search.get('id') && <div className={`bet-title ${search.get('type')}`}>【{getGameName()}】 第 <span>{search.get('id')}</span> 期</div>}
      <Row className={`bet-row1 ${search.get('type')}`} justify='space-between'>
        <Col className={mode == 'odd' ? 'current' : ''} onClick={() => changeMode('odd')}>单</Col>
        <Col className={mode == 'bigOdd' ? 'current' : ''} onClick={() => changeMode('bigOdd')}>大单</Col>
        <Col className={mode == 'smallOdd' ? 'current' : ''} onClick={() => changeMode('smallOdd')}>小单</Col>
        <Col className={mode == 'oddSide' ? 'current' : ''} onClick={() => changeMode('oddSide')}>单边</Col>
        <Col className={mode == 'even' ? 'current' : ''} onClick={() => changeMode('even')}>双</Col>
        <Col className={mode == 'bigEven' ? 'current' : ''} onClick={() => changeMode('bigEven')}>大双</Col>
        <Col className={mode == 'smallEven' ? 'current' : ''} onClick={() => changeMode('smallEven')}>小双</Col>
        <Col className={mode == 'evenSide' ? 'current' : ''} onClick={() => changeMode('evenSide')}>双边</Col>
        <Col className={mode == 'big' ? 'current' : ''} onClick={() => changeMode('big')}>大</Col>
        <Col className={mode == 'small' ? 'current' : ''} onClick={() => changeMode('small')}>小</Col>
        <Col className={mode == 'middle' ? 'current' : ''} onClick={() => changeMode('middle')}>中</Col>
        <Col className={mode == 'side' ? 'current' : ''} onClick={() => changeMode('side')}>边</Col>
        <Col className={mode == 'bigSide' ? 'current' : ''} onClick={() => changeMode('bigSide')}>大边</Col>
        <Col className={mode == 'smallSide' ? 'current' : ''} onClick={() => changeMode('smallSide')}>小边</Col>
      </Row>
      <Row className={`bet-row2 ${search.get('type')}`} justify='space-between'>
        <Col className='transparent'>尾数：</Col>
        <Col className={mode == 'zeroEnd' ? 'current' : ''} onClick={() => changeMode('zeroEnd')}>0尾</Col>
        <Col className={mode == 'oneEnd' ? 'current' : ''} onClick={() => changeMode('oneEnd')}>1尾</Col>
        <Col className={mode == 'twoEnd' ? 'current' : ''} onClick={() => changeMode('twoEnd')}>2尾</Col>
        <Col className={mode == 'threeEnd' ? 'current' : ''} onClick={() => changeMode('threeEnd')}>3尾</Col>
        <Col className={mode == 'fourEnd' ? 'current' : ''} onClick={() => changeMode('fourEnd')}>4尾</Col>
        <Col className={mode == 'fiveEnd' ? 'current' : ''} onClick={() => changeMode('fiveEnd')}>5尾</Col>
        <Col className={mode == 'sixEnd' ? 'current' : ''} onClick={() => changeMode('sixEnd')}>6尾</Col>
        <Col className={mode == 'sevenEnd' ? 'current' : ''} onClick={() => changeMode('sevenEnd')}>7尾</Col>
        <Col className={mode == 'eightEnd' ? 'current' : ''} onClick={() => changeMode('eightEnd')}>8尾</Col>
        <Col className={mode == 'nineEnd' ? 'current' : ''} onClick={() => changeMode('nineEnd')}>9尾</Col>
        <Col className={mode == 'bigEnd' ? 'current' : ''} onClick={() => changeMode('bigEnd')}>大尾</Col>
        <Col className={mode == 'smallEnd' ? 'current' : ''} onClick={() => changeMode('smallEnd')}>小尾</Col>
        <Col className='transparent'></Col>
      </Row>
      <Row className={`bet-row2 ${search.get('type')}`} justify='space-between'>
        <Col className='transparent'>余数：</Col>
        <Col className={mode == 'threeLeft0' ? 'current' : ''} onClick={() => changeMode('threeLeft0')}>3 余 0</Col>
        <Col className={mode == 'threeLeft1' ? 'current' : ''} onClick={() => changeMode('threeLeft1')}>3 余 1</Col>
        <Col className={mode == 'threeLeft2' ? 'current' : ''} onClick={() => changeMode('threeLeft2')}>3 余 2</Col>
        <Col className={mode == 'fourLeft0' ? 'current' : ''} onClick={() => changeMode('fourLeft0')}>4 余 0</Col>
        <Col className={mode == 'fourLeft1' ? 'current' : ''} onClick={() => changeMode('fourLeft1')}>4 余 1</Col>
        <Col className={mode == 'fourLeft2' ? 'current' : ''} onClick={() => changeMode('fourLeft2')}>4 余 2</Col>
        <Col className={mode == 'fourLeft3' ? 'current' : ''} onClick={() => changeMode('fourLeft3')}>4 余 3</Col>
        <Col className={mode == 'fiveLeft0' ? 'current' : ''} onClick={() => changeMode('fiveLeft0')}>5 余 0</Col>
        <Col className={mode == 'fiveLeft1' ? 'current' : ''} onClick={() => changeMode('fiveLeft1')}>5 余 1</Col>
        <Col className={mode == 'fiveLeft2' ? 'current' : ''} onClick={() => changeMode('fiveLeft2')}>5 余 2</Col>
        <Col className={mode == 'fiveLeft3' ? 'current' : ''} onClick={() => changeMode('fiveLeft3')}>5 余 3</Col>
        <Col className={mode == 'fiveLeft4' ? 'current' : ''} onClick={() => changeMode('fiveLeft4')}>5 余 4</Col>
        <Col className='transparent'></Col>
      </Row>
      <Row className='bet-row2' justify='space-between' align='middle'>
        <Col className='transparent'>倍数：</Col>
        <Col onClick={() => setMulti(0.1)}>0.1倍</Col>
        <Col onClick={() => setMulti(0.5)}>0.5倍</Col>
        <Col onClick={() => setMulti(0.8)}>0.8倍</Col>
        <Col onClick={() => setMulti(1.2)}>1.2倍</Col>
        <Col onClick={() => setMulti(1.5)}>1.5倍</Col>
        <Col onClick={() => setMulti(2)}>2倍</Col>
        <Col onClick={() => setMulti(5)}>5倍</Col>
        <Col onClick={() => setMulti(10)}>10倍</Col>
        <Col onClick={() => setMulti(100)}>100倍</Col>
        <Col className='transparent'></Col>
        <Col className='transparent'></Col>
        <Col className='transparent'></Col>
        <Col className='transparent'></Col>
      </Row>
      <Row className='bet-row2' justify='space-between' align='middle'>
        <Col className='transparent'>定额：</Col>
        <Col onClick={() => allInFun({ num: 50000 })}>5万</Col>
        <Col onClick={() => allInFun({ num: 500000 })}>50万</Col>
        <Col onClick={() => allInFun({ num: 1000000 })}>100万</Col>
        <Col onClick={() => allInFun({ num: 5000000 })}>500万</Col>
        <Col onClick={() => allInFun({ num: 10000000 })}>1000万</Col>
        <Col onClick={() => allInFun({ num: 20000000 })}>2000万</Col>
        <Col onClick={() => allInFun({ num: 50000000 })}>5000万</Col>
        <Col onClick={() => allInFun({ num: 100000000 })}>1亿</Col>
        <Col onClick={() => allInFun({ num: 200000000 })}>2亿</Col>
        <Col className='btn3'>
          <Row>
            <Col className='input-box'>
              <Input value={sunNum} onChange={e => setSunNum(+e.target.value)}></Input>
            </Col>
            <Col className='btn4' onClick={() => allInFun({ type: 'set' })}><img src={icon5} /><span>定额梭哈</span></Col>
          </Row>
        </Col>
      </Row>
      <Row className='bet-row3'>
        <Col className='btn2' onClick={reverseFun}><img src={icon3} /><span>反选</span></Col>
        <Col className='btn2' onClick={clearFun}><img src={icon4} /><span>清空</span></Col>
        <Col className='btn1' onClick={showModeModal}><img src={icon2} /><span>模式投注</span></Col>
        <Col className='btn2' onClick={() => allInFun({ type: 'all' })}><img src={icon6} /><span>梭哈</span></Col>
        <Col className='btn3'>
          <Row>
            <Col className='input-box'>{shortNumber(total)}</Col>
            {search.get('id') && <Col className={`btn5 ${search.get('type')}`} onClick={luckBetFun}>确定投注</Col>}
            {!search.get('id') && <Col className={`btn5 ${search.get('type')}`} onClick={showAddMode}>{search.get('modeId') ? '保存模式' : '添加模式'}</Col>}
          </Row>
        </Col>
        <Col className='btn1' onClick={luckPreviousFun}><img src={icon1} /><span>上期投注</span></Col>
        <Col className={`btn1 back`} onClick={() => { navigate(-1) }}><img src={icon7} /><span>返回列表</span></Col>
      </Row>
      <Row justify='space-between' className='table-box'>
        <Col>
          <Table columns={search.get('id') ? [
            {
              title: '号码',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <div className={`num-box ${item.curBet != 0 ? search.get('type') : ''}`} onClick={() => setNum(text)}>{text}</div>
              }
            },
            {
              title: '赔率',
              dataIndex: 'odds',
              key: 'odds',
            },
            {
              title: '已投注',
              dataIndex: 'bet',
              key: 'bet',
            },
            {
              title: '',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <Checkbox checked={item.curBet != 0} className={`table-check ${search.get('type')}`} onClick={() => changeCheck(item)}></Checkbox>
              }
            },
            {
              title: '投注',
              dataIndex: 'curBet',
              key: 'curBet',
              render: (text, item) => {
                return <Input className='table-input' value={text} onChange={(e) => { changeCurBet(e.target.value, item) }} />
              }
            },
            {
              title: '倍数',
              dataIndex: 'id',
              key: 'id',
              render: (text, item) => {
                return <Row className='times-box' justify='center'>
                  <Col className={item.times == 0.5 ? 'current' : ''} onClick={() => { changeTimes(item, 0.5) }}>0.5倍</Col>
                  <Col className={item.times == 2 ? 'current' : ''} onClick={() => { changeTimes(item, 2) }}>2倍</Col>
                  <Col className={item.times == 10 ? 'current' : ''} onClick={() => { changeTimes(item, 10) }}>10倍</Col>
                </Row>
              }
            }
          ] : [
            {
              title: '号码',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <div className={`num-box ${item.curBet != 0 ? search.get('type') : ''}`} onClick={() => setNum(text)}>{text}</div>
              }
            },
            {
              title: '赔率',
              dataIndex: 'odds',
              key: 'odds',
            },
            {
              title: '',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <Checkbox checked={item.curBet != 0} className={`table-check ${search.get('type')}`} onClick={() => changeCheck(item)}></Checkbox>
              }
            },
            {
              title: '投注',
              dataIndex: 'curBet',
              key: 'curBet',
              render: (text, item) => {
                return <Input className='table-input' value={text} onChange={(e) => { changeCurBet(e.target.value, item) }} />
              }
            },
            {
              title: '倍数',
              dataIndex: 'id',
              key: 'id',
              render: (text, item) => {
                return <Row className='times-box' justify='center'>
                  <Col className={item.times == 0.5 ? 'current' : ''} onClick={() => { changeTimes(item, 0.5) }}>0.5倍</Col>
                  <Col className={item.times == 2 ? 'current' : ''} onClick={() => { changeTimes(item, 2) }}>2倍</Col>
                  <Col className={item.times == 10 ? 'current' : ''} onClick={() => { changeTimes(item, 10) }}>10倍</Col>
                </Row>
              }
            }
          ]}
            dataSource={leftList}
            pagination={false}></Table>
        </Col>
        <Col>
          <Table columns={search.get('id') ? [
            {
              title: '号码',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <div className={`num-box ${item.curBet != 0 ? search.get('type') : ''}`} onClick={() => setNum(text)}>{text}</div>
              }
            },
            {
              title: '赔率',
              dataIndex: 'odds',
              key: 'odds',
            },
            {
              title: '已投注',
              dataIndex: 'bet',
              key: 'bet',
            },
            {
              title: '',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <Checkbox checked={item.curBet != 0} className={`table-check ${search.get('type')}`} onClick={() => changeCheck(item)}></Checkbox>
              }
            },
            {
              title: '投注',
              dataIndex: 'curBet',
              key: 'curBet',
              render: (text, item) => {
                return <Input value={text} className='table-input' onChange={(e) => { changeCurBet(e.target.value, item) }} />
              }
            },
            {
              title: '倍数',
              dataIndex: 'id',
              key: 'id',
              render: (text, item) => {
                return <Row className='times-box' justify='center'>
                  <Col className={item.times == 0.5 ? 'current' : ''} onClick={() => { changeTimes(item, 0.5) }}>0.5倍</Col>
                  <Col className={item.times == 2 ? 'current' : ''} onClick={() => { changeTimes(item, 2) }}>2倍</Col>
                  <Col className={item.times == 10 ? 'current' : ''} onClick={() => { changeTimes(item, 10) }}>10倍</Col>
                </Row>
              }
            }
          ] : [
            {
              title: '号码',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <div className={`num-box ${item.curBet != 0 ? search.get('type') : ''}`} onClick={() => setNum(text)}>{text}</div>
              }
            },
            {
              title: '赔率',
              dataIndex: 'odds',
              key: 'odds',
            },
            {
              title: '',
              dataIndex: 'num',
              key: 'num',
              render: (text, item) => {
                return <Checkbox checked={item.curBet != 0} className={`table-check ${search.get('type')}`} onClick={() => changeCheck(item)}></Checkbox>
              }
            },
            {
              title: '投注',
              dataIndex: 'curBet',
              key: 'curBet',
              render: (text, item) => {
                return <Input value={text} className='table-input' onChange={(e) => { changeCurBet(e.target.value, item) }} />
              }
            },
            {
              title: '倍数',
              dataIndex: 'id',
              key: 'id',
              render: (text, item) => {
                return <Row className='times-box' justify='center'>
                  <Col className={item.times == 0.5 ? 'current' : ''} onClick={() => { changeTimes(item, 0.5) }}>0.5倍</Col>
                  <Col className={item.times == 2 ? 'current' : ''} onClick={() => { changeTimes(item, 2) }}>2倍</Col>
                  <Col className={item.times == 10 ? 'current' : ''} onClick={() => { changeTimes(item, 10) }}>10倍</Col>
                </Row>
              }
            }
          ]}
            dataSource={rightList}
            pagination={false}></Table>
        </Col>
      </Row>

      {/* 校验 */}
      <Modal className='luckGame-modal'
        title=""
        open={isShowMode}
        footer={null}
        onCancel={hideModeModal}
        width={960}
        centered
        destroyOnClose={true}>
        <div className='modal-title'>【{getGameName()}】</div>
        <div className='bet-mode-title'><span>选择【{getGameName()}】 投注模式</span></div>
        <Row className='mode-list' justify='center'>
          {
            modeList.map((item, index) => {
              return <Col key={`mode${index}`} id={`mode${item.id}`} onClick={() => selectMode(item)}>{item.title}</Col>
            })
          }
        </Row>
      </Modal>
      {/* 查看详情 */}
      <Modal className='luckGame-modal'
        title=""
        open={isShowAdd}
        footer={null}
        onCancel={hideAddMode}
        width={960}
        centered
        destroyOnClose={true}>
        <div className='modal-title'>【{getGameName()}】</div>
        <div className='bet-mode-title'><span>保存 【{getGameName()}】 投注模式</span></div>
        <Input placeholder='请输入模式名称限制10字内' className='mode-input' value={modeTitle} onChange={(e) => setModeTitle(e.target.value)}></Input>
        <Row justify='center' className='num-detail'>
          {
            list.map(item => {
              return <Col id={`modeData${item.num}`} key={`modeData${item.num}`}>
                <div>{item.num}</div>
                <div>{item.curBet}</div>
              </Col>
            })
          }
        </Row>
        <div className='bottom-tip'>共 <span>{(list.filter(item => item.curBet != 0)).length}</span> 注，投注 <span>{shortNumber(total)}</span> 金豆</div>
        <Row className='bottom-btns' justify='center'>
          <Col className='orange' onClick={search.get('modeId') ? luckModeEditFun : luckModeAddFun}>保存模式</Col>
          <Col onClick={hideAddMode}>取消</Col>
        </Row>
      </Modal>
    </div>
  )
})

export default observer(LuckGameBet)