// 娱乐游戏相关接口
import service from ".."

// 获取顶部数据
export function topInfo (params) {
  return service({
    method: 'GET',
    url: "/luck/top_info",
    params
  })
}

// 获取往期数据
export function luckLists (params) {
  return service({
    method: 'GET',
    url: "/luck/lists",
    params
  })
}

// 获取立即投注数据
export function luckBetsInfo (params) {
  return service({
    method: 'GET',
    url: "/luck/bets_info",
    params
  })
}

// 同上期
export function luckPrevious (params) {
  return service({
    method: 'GET',
    url: "/luck/previous",
    params
  })
}

// 投注
export function luckBet (params) {
  return service({
    method: 'GET',
    url: "/luck/bet",
    params
  })
}

// 模式列表
export function luckModeLists (params) {
  return service({
    method: 'GET',
    url: "/luck_mode/lists",
    params
  })
}

// 删除模式
export function luckModeDel (params) {
  return service({
    method: 'GET',
    url: "/luck_mode/del",
    params
  })
}

// 添加模式
export function luckModeAdd (params) {
  return service({
    method: 'GET',
    url: "/luck_mode/add",
    params
  })
}

// 编辑模式
export function luckModeEdit (params) {
  return service({
    method: 'GET',
    url: "/luck_mode/edit",
    params
  })
}

// 28投注记录
export function luckLogs (params) {
  return service({
    method: 'GET',
    url: "/luck/logs",
    params
  })
}

// 查看详情
export function luckInfo (params) {
  return service({
    method: 'GET',
    url: "/luck/info",
    params
  })
}

// 模式赔率
export function luckModeInfo (params) {
  return service({
    method: 'GET',
    url: "/luck_mode/info",
    params
  })
}

// 游戏规则
export function luckRule (params) {
  return service({
    method: 'GET',
    url: "/luck/rule",
    params
  })
}

// 我的自动追号
export function luckChaseInfo (params) {
  return service({
    method: 'GET',
    url: "/luck_chase/info",
    params
  })
}

// 开启自动追号
export function luckChaseStart (params) {
  return service({
    method: 'GET',
    url: "/luck_chase/start",
    params
  })
}

// 停止自动追号
export function luckChaseStop (params) {
  return service({
    method: 'GET',
    url: "/luck_chase/stop",
    params
  })
}

// 我的自动投注
export function luckStakeInfo (params) {
  return service({
    method: 'GET',
    url: "/luck_stake/info",
    params
  })
}

// 开启自动追号
export function luckStakeStart (params) {
  return service({
    method: 'GET',
    url: "/luck_stake/start",
    params
  })
}

// 停止自动追号
export function luckStakeStop (params) {
  return service({
    method: 'GET',
    url: "/luck_stake/stop",
    params
  })
}

// 自动投注输赢模式
export function luckModeSave (params) {
  return service({
    method: 'GET',
    url: "/luck_mode/save",
    params
  })
}

// 走势
export function luckHistory (params) {
  return service({
    method: 'GET',
    url: "/luck/history",
    params
  })
}

// 模式详情
export function luckModePInfo (params) {
  return service({
    method: 'GET',
    url: "/luck_mode/pinfo",
    params
  })
}
