// 个人中心相关接口
import service from ".."


// 顶部用户信息
export function guessInfo (params) {
  return service({
    method: 'GET',
    url: "/account/info",
    params
  })
}

// 设置昵称
export function setUname (params) {
  return service({
    method: 'GET',
    url: "/account/set_uname",
    params
  })
}

// 我的卡密
export function cardLogs (params) {
  return service({
    method: 'GET',
    url: "/account/my_card",
    params
  })
}

// 使用豆卡
export function cardDeal (params) {
  return service({
    method: 'GET',
    url: "/account/use_card",
    params
  })
}

// 获取金豆记录
export function logGold (params) {
  return service({
    method: 'GET',
    url: "/account/gold",
    params
  })
}

// 签到等级列表
export function levelRule (params) {
  return service({
    method: 'GET',
    url: "/account/level_rule",
    params
  })
}

// 签到记录
export function signLog (params) {
  return service({
    method: 'GET',
    url: "/account/sign_log",
    params
  })
}


// 执行签到
export function signDeal (params) {
  return service({
    method: 'GET',
    url: "/account/sign",
    params
  })
}

// 28投注记录
export function luckLogs (params) {
  return service({
    method: 'GET',
    url: "/luck/logs",
    params
  })
}

// 修改登录密码
export function resetPwd (params) {
  return service({
    method: 'GET',
    url: "/account/reset_pwd",
    params
  })
}

// 修改支付密码
export function resetPayPwd (params) {
  return service({
    method: 'GET',
    url: "/account/reset_pay_pwd",
    params
  })
}

// 游戏到银行
export function playToBank (params) {
  return service({
    method: 'GET',
    url: "/account/play_to_bank",
    params
  })
}

// 银行到游戏
export function bankToPlay (params) {
  return service({
    method: 'GET',
    url: "/account/bank_to_play",
    params
  })
}

// 划转记录
export function bankPlayLog (params) {
  return service({
    method: 'GET',
    url: "/account/bank_play_log",
    params
  })
}

// 系统头像
export function defaultHead (params) {
  return service({
    method: 'GET',
    url: "/account/default_head",
    params
  })
}

// 设置头像
export function setHead (params) {
  return service({
    method: 'GET',
    url: "/account/set_head",
    params
  })
}

