import { Row, Col, message, Carousel, Table, Avatar } from 'antd'
import './index.scss'
import { useStore } from '@/store'
import { goods } from '@/request/api'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import coinIcon from '@/assets/coin-icon.png'


function Change () {
  useEffect(() => {
    // 获取数据
    goodsFun()
  }, [])

  // 首页数据
  let [list, setList] = useState([])
  const goodsFun = async () => {
    const res = await goods()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setList(res.data.list)
    }
  }

  return (
    <div className='change-container'>
      <div className="banner-contaner">
        <Carousel autoplay>
          <div className='banner-img'><img src={require('@/assets/change/banner.jpg')} /></div>
        </Carousel>
      </div>
      <div className='change-main'>
        <Row className='change-list'>
          {
            list.map((item, index) => {
              return (
                <Col key={`goods${index}`}>
                  <div className='goods-img'><img src={item.img} /></div>
                  <h6 className='item-title'>{item.title}</h6>
                  <Row justify='space-between' className='change-box' align='middle'>
                    <Col>
                      <span>{item.price}</span>
                      <img src={coinIcon} className='coin-icon' />
                    </Col>
                    <Col>
                      <Link className='change-btn' to={`/changeDetail?id=${item.id}`}>立即兑换</Link>
                    </Col>
                  </Row>
                </Col>
              )
            })
          }
        </Row>
      </div>
    </div>
  )
}

export default Change