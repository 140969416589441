import { Row, Col, Table, Modal, message, Checkbox, Radio, Input, Space, Tooltip, Pagination, Select, Avatar, Empty, DatePicker, Switch } from 'antd'
import { CaretUpOutlined, CaretDownOutlined, InfoCircleFilled, SlidersFilled, DatabaseFilled } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { shortNumber } from '@/utils'
import clipboard from 'clipboard'
import './index.scss'
import { hbaoLists, hbaoIcons, addGroup, applyByPwd, hbaoMyList, hbaoDisbandList, hbaoApplyList, hbaoDisband, hbaoGinfo, hbaoEditGroup, hbaoExamine, hbaoQuitDisband, hbaoQuitOut, hbaoApply, hbaoJoinList, hbaoMyApply, hbaoStatistic, hbaoDetail, hbaoRule, grabLog, hbaoLog, hbaoInfo } from '@/request/api'
import { useEffect, useState } from 'react'
import nav1Icon from '@/assets/hbao/nav1.png'
import nav1ActiveIcon from '@/assets/hbao/nav1-active.png'
import nav2Icon from '@/assets/hbao/nav2.png'
import nav2ActiveIcon from '@/assets/hbao/nav2-active.png'
import nav3Icon from '@/assets/hbao/nav3.png'
import nav3ActiveIcon from '@/assets/hbao/nav3-active.png'
import nav4Icon from '@/assets/hbao/nav4.png'
import nav4ActiveIcon from '@/assets/hbao/nav4-active.png'
import nav5Icon from '@/assets/hbao/nav5.png'
import nav5ActiveIcon from '@/assets/hbao/nav5-active.png'
import searchIcon from '@/assets/hbao/search-icon.png'
import addIcon from '@/assets/hbao/add-icon.png'
import joinIcon from '@/assets/hbao/join-icon.png'
import safeIcon from '@/assets/hbao/safe-icon.png'
import numIcon from '@/assets/hbao/num-icon.png'
import nameIcon from '@/assets/hbao/name-icon.png'
import coinIcon from '@/assets/coin-icon.png'
import setIcon from '@/assets/hbao/set-icon.png'
import outIcon from '@/assets/hbao/out-icon.png'
import Chat from '@/components/Chat'
import moment from 'moment'


const { RangePicker } = DatePicker
function Hbao () {
  const { common28Store } = useStore()
  const [search] = useSearchParams()

  useEffect(() => {
    changeNav(search.get('nav'), search.get('id'))
  }, [search.get('nav')])

  useEffect(() => {
    hbaoIconsFun()

    const copy = new clipboard('.copy-text')
    copy.on('success', e => {
      if (e.text == '复制已选') {
        message.warning('请先选择要复制的卡密')
        return
      }
      if (e.text == '一键复制') {
        message.warning('本页没有未使用的卡密')
        return
      }
      message.success('复制成功')
    })
    copy.on('error', e => {
      message.error('复制失败')
    })
    return () => {
      copy.destroy()
    }
  }, [])

  let [nav, setNav] = useState('nav1')
  const [curGameId, setCurGameId] = useState('')
  const changeNav = (clickStr, id) => {
    setNav(clickStr)
    if (clickStr == 'nav1') {
      hbaoListsFun({
        'keyword': keyword,
        'order_key': order_key,
        'order_by': order_by,
        page: 1
      })
    } else if (clickStr == 'nav2') {
      hbaoMyListFun({
        page: 1
      })
      hbaoDisbandListFun({
        page: 1
      })
      hbaoApplyListFun({
        page: 1,
        limit_level: -1
      })
    } else if (clickStr == 'nav3') {
      hbaoJoinListFun({
        page: 1
      })
      hbaoMyApplyFun({
        page: 1
      })
    } else if (clickStr == 'nav4') {
      hbaoStatisticFun({
        sdate: statisticSdate,
        edate: statisticEdate,
        page: statisticPage
      })
    } else if (clickStr == 'nav5') {
      hbaoDetailFun({
        hbid: detailHbid,
        qid: detailQid,
        page: detailPage
      })
    } else if (clickStr == 'nav6') {
      grabLogFun({
        hbid: grabHbid,
        qid: grabQid,
        page: grabPage
      })
    } else if (clickStr == 'nav7') {
      hbaoLogFun({
        hbid: hbaoLogHbid,
        qid: hbaoLogQid,
        page: hbaoLogPage
      })
    } else if (clickStr == 'nav8') {
      hbaoRuleFun()
    } else if (clickStr == 'enterGame') {
      setCurGameId(id)
    }
  }


  // 红包大厅
  const [hbaoList, setHbaoList] = useState([])
  const [hbaoPage, setHbaoPage] = useState(1)
  const [hbaoTotal, setHbaoTotal] = useState(0)
  const [hbaoLimit, setHbaoLimit] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [order_key, setOrderKey] = useState('')
  const [order_by, setOrderBy] = useState('desc')
  const hbaoListsFun = async (params) => {
    const { keyword, order_key, order_by, page } = params
    const res = await hbaoLists({
      keyword,
      order_key,
      order_by,
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setHbaoList(res.data.list)
      setHbaoPage(page)
      setHbaoTotal(res.data.total)
      setHbaoLimit(res.data.limit)
    }
  }
  //排序
  const changeOrder = (orderKey) => {
    let orderBy = ''
    if (orderKey != order_key) {
      setOrderKey(orderKey)
      setOrderBy('desc')
      orderBy = 'desc'
    } else {
      orderBy = order_by == 'desc' ? 'asc' : 'desc'
      setOrderBy(orderBy)
    }
    setHbaoPage(1)
    hbaoListsFun({
      keyword: keyword,
      order_by: orderBy,
      order_key: orderKey,
      page: 1
    })
  }

  // 红包图标
  const [hbaoIconList, setHbaoIconList] = useState([])
  const hbaoIconsFun = async (params) => {
    const res = await hbaoIcons()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setHbaoIconList(res.data.list)
      setIconImg(res.data.list[0])
    }
  }
  // 选择图标
  const [iconImg, setIconImg] = useState('')
  const [isOpenIcon, setIsOpenIcon] = useState(false)
  const handleOpenChange = (newOpen) => {
    setIsOpenIcon(newOpen)
  }
  // 创建新群
  const [title, setTitle] = useState('')
  const [join_type, setJoinType] = useState(1)
  const [join_pwd, setJoinPwd] = useState('')
  const [limit_level, setLimitLevel] = useState(-1)
  const [golds, setGolds] = useState([5000, 10000, 20000, 50000, 100000, 200000])
  const [searchType, setSearchType] = useState(['search_title', 'search_uid', 'search_qid'])
  const [limit_level_num, setLimitLevelNum] = useState('')
  const selectGolds = (num) => {
    let isInclude = golds.filter((item) => item == num).length != 0
    if (!isInclude) {
      setGolds([...golds, num])
    } else {
      setGolds(golds.filter(item => item != num))
    }
  }
  //新建群弹窗
  const [isShowCreateModal, setIsShowCreateModal] = useState(false)
  const showCreateModal = () => {
    setIconImg(hbaoIconList[0])
    setIsShowCreateModal(true)
  }
  const hideCreateModal = () => {
    setIconImg('')
    setTitle('')
    setJoinType(1)
    setJoinPwd('')
    setLimitLevel(-1)
    setGolds([5000, 10000, 20000, 50000, 100000, 200000])
    setSearchType(['search_title', 'search_uid', 'search_qid'])
    setLimitLevelNum('')
    setCurQInfo({})
    setIsShowCreateModal(false)
  }
  const addGroupFun = async () => {
    if (!title) {
      message.warning('请输入群名称')
      return
    }
    if (!join_type) {
      message.warning('请选择加群方式')
      return
    }
    if (join_type == 3 && !join_pwd) {
      message.warning('请输入6位口令')
      return
    }
    if (searchType.length == 0) {
      message.warning('请选择搜索方式')
      return
    }
    if (!limit_level) {
      message.warning('请选择加群等级')
      return
    }
    if (limit_level != -1 && !limit_level_num) {
      message.warning('请输入等级限制')
      return
    }
    if (golds.length == 0) {
      message.warning('请选择红包金额')
      return
    }
    const res = await addGroup({
      title,
      golds: `[${golds.toString()}]`,
      join_type,
      join_pwd,
      limit_level: limit_level == -1 ? '-1' : limit_level_num,
      icon: iconImg,
      search_title: searchType.includes('search_title') ? 1 : 0,
      search_uid: searchType.includes('search_uid') ? 1 : 0,
      search_qid: searchType.includes('search_qid') ? 1 : 0,
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('创建成功')
      hbaoListsFun({
        'keyword': keyword,
        'order_key': order_key,
        'order_by': order_by,
        page: 1
      })
      hideCreateModal()
    }
  }
  // 输入口令
  const changePwd = (e) => {
    let curInput = e.target.value
    if (!!curInput) {
      if (e.target.id == 'pwd1') {
        document.getElementById('pwd2').focus()
      }
      if (e.target.id == 'pwd2') {
        document.getElementById('pwd3').focus()
      }
      if (e.target.id == 'pwd3') {
        document.getElementById('pwd4').focus()
      }
      if (e.target.id == 'pwd4') {
        document.getElementById('pwd5').focus()
      }
      if (e.target.id == 'pwd5') {
        document.getElementById('pwd6').focus()
      }
    } else {
      if (e.target.id == 'pwd6') {
        document.getElementById('pwd5').focus()
      }
      if (e.target.id == 'pwd5') {
        document.getElementById('pwd4').focus()
      }
      if (e.target.id == 'pwd4') {
        document.getElementById('pwd3').focus()
      }
      if (e.target.id == 'pwd3') {
        document.getElementById('pwd2').focus()
      }
      if (e.target.id == 'pwd2') {
        document.getElementById('pwd1').focus()
      }
    }
  }
  // 口令加群
  const [isShowJoinPwd, setIsShowJoinPwd] = useState(false)
  const showJoinPwd = () => {
    setIsShowJoinPwd(true)
    document.getElementById('pwd1').focus()
  }
  const hideJoinPwd = () => {
    setIsShowJoinPwd(false)
  }
  const applyByPwdFun = async () => {
    let arr = []
    arr.push(document.getElementById('pwd1').value)
    arr.push(document.getElementById('pwd2').value)
    arr.push(document.getElementById('pwd3').value)
    arr.push(document.getElementById('pwd4').value)
    arr.push(document.getElementById('pwd5').value)
    arr.push(document.getElementById('pwd6').value)
    if (arr.join('').length == 0 || arr.join('').length < 0) {
      message.warning('请输入加群口令')
      return
    }
    const res = await applyByPwd({
      join_pwd: arr.join('')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('操作成功')
      hideJoinPwd()
      toLink(`/hbao?nav=enterGame&id=${res.data.qid}`)
    }
  }

  //我创建的群
  const [myListType, setMyListType] = useState('my')
  const [myList, setMyList] = useState([])
  const [myListPage, setMyListPage] = useState(1)
  const [myListTotal, setMyListTotal] = useState(0)
  const [myListLimit, setMyListLimit] = useState(0)
  const hbaoMyListFun = async (params) => {
    const { page } = params
    const res = await hbaoMyList({
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setMyList(res.data.list)
      setMyListTotal(res.data.total)
      setMyListLimit(res.data.limit)
      setMyListPage(page)
    }
  }

  //解散中的群
  const [disbandList, setDisbandList] = useState([])
  const [disbandListPage, setDisbandListPage] = useState(1)
  const [disbandListTotal, setDisbandListTotal] = useState(0)
  const [disbandListLimit, setDisbandListLimit] = useState(0)
  const hbaoDisbandListFun = async (params) => {
    const { page } = params
    const res = await hbaoDisbandList({
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setDisbandList(res.data.list)
      setDisbandListTotal(res.data.total)
      setDisbandListLimit(res.data.limit)
      setDisbandListPage(page)
    }
  }
  // 群设置
  const [curQInfo, setCurQInfo] = useState({})
  const hbaoGinfoFun = async (id, callback) => {
    const res = await hbaoGinfo({
      qid: id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      callback(res.data)
    }
  }
  const showSetDialog = (params) => {
    const { title, join_type, icon, search_title, search_qid, search_uid, limit_level, golds, id } = params
    let searchTypeArr = []
    search_title == 1 && searchTypeArr.push('search_title')
    search_qid == 1 && searchTypeArr.push('search_qid')
    search_uid == 1 && searchTypeArr.push('search_uid')
    setCurQInfo({ ...params, qid: id })
    setTitle(title)
    setJoinType(join_type)
    setJoinPwd('')
    setLimitLevel(limit_level == -1 ? -1 : 1)
    setGolds(JSON.parse(golds))
    setIconImg(icon)
    setSearchType(searchTypeArr)
    setLimitLevelNum(limit_level == -1 ? '' : limit_level)
    showCreateModal()
  }
  // 解散群
  const [isShowCancelModal, setIsShowCancelModal] = useState(false)
  const showCancelModal = (clickItem) => {
    setCurQInfo(clickItem)
    setIsShowCancelModal(true)
  }
  const hideCancelModal = () => {
    setIsShowCancelModal(false)
  }
  const hbaoDisbandFun = async () => {
    const res = await hbaoDisband({
      qid: curQInfo.qid
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('提交成功')
      hideCancelModal()
      setCurQInfo({})
      hbaoMyListFun()
    }
  }
  // 编辑群
  const hbaoEditGroupFun = async () => {
    if (!title) {
      message.warning('请输入群名称')
      return
    }
    if (!join_type) {
      message.warning('请选择加群方式')
      return
    }
    if (join_type == 3 && !join_pwd) {
      message.warning('请输入6位口令')
      return
    }
    if (searchType.length == 0) {
      message.warning('请选择搜索方式')
      return
    }
    if (!limit_level) {
      message.warning('请选择加群等级')
      return
    }
    if (limit_level != -1 && !limit_level_num) {
      message.warning('请输入等级限制')
      return
    }
    if (golds.length == 0) {
      message.warning('请选择红包金额')
      return
    }
    const res = await hbaoEditGroup({
      qid: curQInfo.id,
      title,
      golds: `[${golds.toString()}]`,
      join_type,
      join_pwd,
      limit_level: limit_level == -1 ? '-1' : limit_level_num,
      icon: iconImg,
      search_title: searchType.includes('search_title') ? 1 : 0,
      search_uid: searchType.includes('search_uid') ? 1 : 0,
      search_qid: searchType.includes('search_qid') ? 1 : 0,
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('修改成功')
      hbaoMyListFun()
      hideCreateModal()
    }
  }

  // 申请列表
  const [applyList, setApplyList] = useState([])
  const [applyPage, setApplyPage] = useState(1)
  const [applyTotal, setApplyTotal] = useState(0)
  const [applyLimit, setApplyLimit] = useState(0)
  const [applyLimitLevel, setApplyLimitLevel] = useState('-1')
  const hbaoApplyListFun = async (params) => {
    const { limit_level, page } = params
    const res = await hbaoApplyList({
      limit_level,
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setApplyList(res.data.list)
      setApplyTotal(res.data.total)
      setApplyLimit(res.data.limit)
    }
  }
  const [isShowApplyModal, setIsShowApplyModal] = useState(false)
  const showApplyModal = () => {
    setIsShowApplyModal(true)
  }
  const hideApplyModal = () => {
    hbaoApplyListFun({
      limit_level: -1,
      page: 1
    })
    setIsShowApplyModal(false)
  }

  // 入群审核
  const hbaoExamineFun = async (params) => {
    const { id, type } = params
    const res = await hbaoExamine({
      id,
      type
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      hbaoApplyListFun({
        limit_level: applyLimitLevel,
        page: applyPage
      })
      message.success('操作成功')
    }
  }

  // 取消解散
  const hbaoQuitDisbandFun = async (id) => {
    const res = await hbaoQuitDisband({
      qid: id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      hbaoMyListFun({
        page: myListPage
      })
      hbaoDisbandListFun({
        page: disbandListPage
      })
      message.success('取消解散成功')
    }
  }
  // 退出群
  const [isShowQuitModal, setIsShowQuitModal] = useState(false)
  const showQuitModal = (clickItem) => {
    setCurQInfo(clickItem)
    setIsShowQuitModal(true)
  }
  const hideQuitModal = () => {
    setCurQInfo({})
    setIsShowQuitModal(false)
  }
  const hbaoQuitOutFun = async (id) => {
    const res = await hbaoQuitOut({
      qid: id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('退出成功')
      hbaoJoinListFun({
        page: joinListPage
      })
      hbaoListsFun({
        'keyword': keyword,
        'order_key': order_key,
        'order_by': order_by,
        page: hbaoPage
      })
      hideQuitModal()
    }
  }

  // 申请入群
  const hbaoApplyFun = async (id) => {
    const res = await hbaoApply({
      qid: id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('操作成功')
      hbaoListsFun({
        'keyword': keyword,
        'order_key': order_key,
        'order_by': order_by,
        page: hbaoPage
      })
    }
  }

  //我创建的群
  const [joinListType, setJoinListType] = useState('join')
  const [joinList, setJoinList] = useState([])
  const [joinListPage, setJoinListPage] = useState(1)
  const [joinListTotal, setJoinListTotal] = useState(0)
  const [joinListLimit, setJoinListLimit] = useState(0)
  const hbaoJoinListFun = async (params) => {
    const { page } = params
    const res = await hbaoJoinList({
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setJoinList(res.data.list)
      setJoinListTotal(res.data.total)
      setJoinListLimit(res.data.limit)
      setJoinListPage(page)
    }
  }

  //解散中的群
  const [myApplyList, setMyApplyList] = useState([])
  const [myApplyListPage, setMyApplyListPage] = useState(1)
  const [myApplyListTotal, setMyApplyListTotal] = useState(0)
  const [myApplyListLimit, setMyApplyListLimit] = useState(0)
  const hbaoMyApplyFun = async (params) => {
    const { page } = params
    const res = await hbaoMyApply({
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setMyApplyList(res.data.list)
      setMyApplyListTotal(res.data.total)
      setMyApplyListLimit(res.data.limit)
      setMyApplyListPage(page)
    }
  }

  // 群红包统计
  const [statisticList, setStatisticList] = useState([])
  const [statisticPage, setStatisticPage] = useState(1)
  const [statisticTotal, setStatisticTotal] = useState(0)
  const [statisticLimit, setStatisticLimit] = useState(0)
  const [statisticSdate, setStatisticSdate] = useState('')
  const [statisticEdate, setStatisticEdate] = useState('')
  const hbaoStatisticFun = async (params) => {
    const { sdate, edate, page } = params
    const res = await hbaoStatistic({
      sdate,
      edate,
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setStatisticList(res.data.list)
      setStatisticPage(page)
      setStatisticTotal(res.data.total)
      setStatisticLimit(res.data.limit)
    }
  }

  // 改变搜索日期
  const changeDate = (dates, dateStrings) => {
    setStatisticSdate(dateStrings[0])
    setStatisticEdate(dateStrings[1])
  }

  // 群红包记录
  const [detailList, setDetailList] = useState([])
  const [detailPage, setDetailPage] = useState(1)
  const [detailTotal, setDetailTotal] = useState(0)
  const [detailLimit, setDetailLimit] = useState(0)
  const [detailQid, setDetailQid] = useState('')
  const [detailHbid, setDetailHbid] = useState('')
  const hbaoDetailFun = async (params) => {
    const { hbid, qid, page } = params
    const res = await hbaoDetail({
      hbid,
      qid,
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setDetailList(res.data.list)
      setDetailPage(page)
      setDetailTotal(res.data.total)
      setDetailLimit(res.data.limit)
    }
  }

  const navigate = useNavigate()
  const toLink = (path) => {
    if (nav == 'enterGame') {
      window.location.href = path
    } else {
      navigate(path)
    }
  }

  // 玩法说明
  const [ruleList, setRuleList] = useState([])
  const hbaoRuleFun = async () => {
    const res = await hbaoRule()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setRuleList(res.data.list)
    }
  }

  // 抢红包记录
  const [grabList, setGrabList] = useState([])
  const [grabPage, setGrabPage] = useState(1)
  const [grabTotal, setGrabTotal] = useState(0)
  const [grabLimit, setGrabLimit] = useState(0)
  const [grabQid, setGrabQid] = useState('')
  const [grabHbid, setGrabHbid] = useState('')
  const grabLogFun = async (params) => {
    const { hbid, qid, page } = params
    const res = await grabLog({
      hbid,
      qid,
      page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setGrabList(res.data.list)
      setGrabPage(page)
      setGrabTotal(res.data.total)
      setGrabLimit(res.data.limit)
    }
  }

  // 发红包记录
  const [hbaoLogList, setHbaoLogList] = useState([])
  const [hbaoLogPage, setHbaoLogPage] = useState(1)
  const [hbaoLogTotal, setHbaoLogTotal] = useState(0)
  const [hbaoLogLimit, setHbaoLogLimit] = useState(0)
  const [hbaoLogQid, setHbaoLogQid] = useState('')
  const [hbaoLogHbid, setHbaoLogHbid] = useState('')
  const hbaoLogFun = async (params) => {
    const { hbid, qid, page } = params
    const res = await hbaoLog({
      hbid,
      qid,
      page,
      status
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setHbaoLogList(res.data.list)
      setHbaoLogPage(page)
      setHbaoLogTotal(res.data.total)
      setHbaoLogLimit(res.data.limit)
    }
  }

  // 切换状态
  const [status, setStatus] = useState(0)
  const changeStatus = (value) => {
    setStatus(value)
  }

  // 红包详情
  const [hbaoNum, setHbaoNum] = useState(0)
  const [hbaoDeal, setHbaoDeal] = useState(0)
  const [hbaoGold, setHbaoGold] = useState(0)
  const [hbaoStatus, setHbaoStatus] = useState(0)
  const [hbaoDetailList, setHbaoDetailList] = useState([])
  const [hbaoDetailRule, setHbaoDetailRule] = useState([])
  const [curHbaoId, setCurHbaoId] = useState(0)
  const hbaoInfoFun = async (id) => {
    setCurHbaoId(id)
    const res = await hbaoInfo({
      hbid: id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setHbaoNum(res.data.num)
      setHbaoDeal(res.data.deal)
      setHbaoGold(res.data.gold)
      setHbaoStatus(res.data.status)
      setHbaoDetailList(res.data.list)
      setHbaoDetailRule(res.data.rule)
      showHbaoDetail()
    }
  }
  const [isShowHbaoDetail, setIsShowHbaoDetail] = useState(false)
  const showHbaoDetail = () => {
    setIsShowHbaoDetail(true)
  }
  const hideHbaoDetail = () => {
    setIsShowHbaoDetail(false)
  }

  // 获取红包状态
  const getHbaoStatus = (status) => {
    if (status == 1) {
      return '可抢'
    } else if (status == 2) {
      return '已抢完'
    } else if (status == 3) {
      return '已过期'
    } else if (status == 4) {
      return '已抢过'
    }
  }

  return (
    <div className='hbao-container'>
      <Row className='hbao-main' justify='space-between'>
        <Col className='left-nav'>
          <div className='nav-menu'>
            <div className='title'>扫雷红包</div>
            <Row align='middle' className={nav == 'nav1' ? 'current' : ''} onClick={() => { toLink('/hbao?nav=nav1') }}>
              <Col>
                {
                  nav == 'nav1' && <img src={nav1ActiveIcon} className='nav-icon' />
                }
                {
                  nav != 'nav1' && <img src={nav1Icon} className='nav-icon' />
                }
              </Col>
              <Col>红包大厅</Col>
            </Row>
            <Row align='middle' className={nav == 'nav2' ? 'current' : ''} onClick={() => { toLink('/hbao?nav=nav2') }}>
              <Col>
                {
                  nav == 'nav2' && <img src={nav2ActiveIcon} className='nav-icon' />
                }
                {
                  nav != 'nav2' && <img src={nav2Icon} className='nav-icon' />
                }
              </Col>
              <Col>创建的群</Col>
            </Row>
            <Row align='middle' className={nav == 'nav3' ? 'current' : ''} onClick={() => { toLink('/hbao?nav=nav3') }}>
              <Col>
                {
                  nav == 'nav3' && <img src={nav3ActiveIcon} className='nav-icon' />
                }
                {
                  nav != 'nav3' && <img src={nav3Icon} className='nav-icon' />
                }
              </Col>
              <Col>加入的群</Col>
            </Row>
            <Row align='middle' className={nav == 'nav4' ? 'current' : ''} onClick={() => { toLink('/hbao?nav=nav4') }}>
              <Col>
                {
                  nav == 'nav4' && <img src={nav4ActiveIcon} className='nav-icon' />
                }
                {
                  nav != 'nav4' && <img src={nav4Icon} className='nav-icon' />
                }
              </Col>
              <Col>红包统计</Col>
            </Row>
            <Row align='middle' className={nav == 'nav6' ? 'current' : ''} onClick={() => { toLink('/hbao?nav=nav6') }}>
              <Col>
                <SlidersFilled className='font-icon' />
              </Col>
              <Col>抢包记录</Col>
            </Row>
            <Row align='middle' className={nav == 'nav7' ? 'current' : ''} onClick={() => { toLink('/hbao?nav=nav7') }}>
              <Col>
                <DatabaseFilled className='font-icon' />
              </Col>
              <Col>发包记录</Col>
            </Row>
            <Row align='middle' className={nav == 'nav8' ? 'current' : ''} onClick={() => { toLink('/hbao?nav=nav8') }}>
              <Col>
                <InfoCircleFilled className='font-icon' />
              </Col>
              <Col>玩法说明</Col>
            </Row>
          </div>
        </Col>
        {
          nav == 'nav1' &&
          <Col className='right-main'>
            <Row justify='space-between' className='search-box nav1'>
              <Col>
                <Row>
                  <Col>
                    <Input.Search
                      placeholder="输入群名称 群ID"
                      allowClear
                      enterButton="搜索"
                      prefix={<img src={searchIcon} className='search-icon' />}
                      onSearch={(e) => {
                        setKeyword(e)
                        hbaoListsFun({
                          keyword: e,
                          order_by,
                          order_key,
                          page: 1
                        })
                      }}
                      className='search-input' />
                  </Col>
                  <Col className='sort-col'
                    onClick={() => changeOrder('id')}>
                    {order_by == 'asc' && order_key == 'id' && <CaretUpOutlined className='sort-icon' />}
                    {(order_by == 'desc' || order_key != 'id') && <CaretDownOutlined className='sort-icon' />}
                    <span className={order_key == 'id' ? 'orange' : ''}>创建时间</span>
                  </Col>
                  <Col className='sort-col'
                    onClick={() => changeOrder('person')}>
                    {order_by == 'asc' && order_key == 'person' && <CaretUpOutlined className='sort-icon' />}
                    {(order_by == 'desc' || order_key != 'person') && <CaretDownOutlined className='sort-icon' />}
                    <span className={order_key == 'person' ? 'orange' : ''}>在线人数</span>
                  </Col>
                  <Col className='sort-col'
                    onClick={() => changeOrder('max-gold')}>
                    {order_by == 'asc' && order_key == 'max-gold' && <CaretUpOutlined className='sort-icon' />}
                    {(order_by == 'desc' || order_key != 'max-gold') && <CaretDownOutlined className='sort-icon' />}
                    <span className={order_key == 'max-gold' ? 'orange' : ''}>红包大小</span>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className='add-btn' onClick={showCreateModal}>
                    <img src={addIcon} className='add-icon' />
                    <span>创建新群</span>
                  </Col>
                  <Col className='key-btn' onClick={showJoinPwd}>
                    <img src={joinIcon} className='key-icon' />
                    <span>口令加群</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='list-box'>
              {
                hbaoList.length != 0 &&
                hbaoList.map(item => {
                  return (
                    <Col key={item.id}>
                      <Row justify='space-between' className='list-header' align='middle'>
                        <Col>
                          {
                            item.status == 2 &&
                            <>
                              <span className='header-tip blue'>我加入的</span>
                              <img src={outIcon} className='out-icon' onClick={() => showQuitModal({ ...item, qid: item.id })} />
                            </>
                          }
                          {
                            item.status == 1 &&
                            <>
                              <span className='header-tip'>我创建的</span>
                              <img src={setIcon} className='set-icon' onClick={() => hbaoGinfoFun(item.id, showSetDialog)} />
                            </>
                          }
                          {
                            item.status == 6 &&
                            <>
                              <img src={safeIcon} className='safe-icon' />
                              <img src={joinIcon} className='key-icon' />
                            </>
                          }
                        </Col>
                        <Col>ID：{item.id}</Col>
                      </Row>
                      <Row className='middle-box' align='middle'>
                        <Col>
                          <p className='font1'>{item.title}</p>
                          <p className='font2'>
                            <img src={nameIcon} />
                            <span>{item.uname}</span>
                          </p>
                          <p className='font3'>
                            <img src={coinIcon} />
                            <span>{shortNumber(item.max_gold)}</span>
                          </p>
                        </Col>
                        <Col className='list-icon'>
                          <img src={item.icon} />
                        </Col>
                      </Row>
                      <Row justify='space-between' align='middle' className='bottom-box'>
                        {(item.status == 5 || item.status == 6) && <Col className='btn' onClick={() => { item.status == 6 ? applyByPwdFun() : hbaoApplyFun(item.id) }}>申请加群</Col>}
                        {item.status == 4 && <Col className='btn' onClick={() => hbaoApplyFun(item.id)}>加群</Col>}
                        {(item.status == 1 || item.status == 2) && <Col className='btn orange' onClick={() => toLink(`/hbao?nav=enterGame&id=${item.id}`)}>进入游戏</Col>}
                        {item.status == 3 && <Col className='btn yellow' >审核中</Col>}
                        <Col>
                          <Row className='font4' align='middle'>
                            {item.limit_level != -1 && <Col>{item.limit_level}级以上加入</Col>}
                            <Col>
                              <img src={numIcon} className='num-icon' />
                              <span>{item.person}人游戏中</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )
                })
              }
            </Row>
            {hbaoList.length == 0 && <Empty centered className='empty-box' />}
            {hbaoList.length != 0 && <Pagination current={hbaoPage} total={hbaoTotal} size={hbaoLimit} centered onChange={(page) => { hbaoListsFun({ keyword, order_by, order_key, page }) }} />}
            {/* 口令加群 */}
            <Modal className='hbao-modal'
              title=""
              open={isShowJoinPwd}
              footer={null}
              onCancel={hideJoinPwd}
              width={520}
              closable={false}
              centered="true"
              destroyOnClose={true}>
              <h6 className='modal-title'>口令加群</h6>
              <p className='pwd-modal-tip'>请输入群口令</p>
              <p className='pwd-modal-subtip'>(字母不区分大小写，默认大写)</p>
              <Space className='pwds-box' align='center'>
                <Input autoComplete='off' onChange={changePwd} maxLength={1} id='pwd1' />
                <Input autoComplete='off' onChange={changePwd} maxLength={1} id='pwd2' />
                <Input autoComplete='off' onChange={changePwd} maxLength={1} id='pwd3' />
                <Input autoComplete='off' onChange={changePwd} maxLength={1} id='pwd4' />
                <Input autoComplete='off' onChange={changePwd} maxLength={1} id='pwd5' />
                <Input autoComplete='off' onChange={changePwd} maxLength={1} id='pwd6' />
              </Space>
              <Row className='modal-btn' align='middle' justify='center'>
                <Col className='orange-btn' onClick={applyByPwdFun}>确认</Col>
                <Col className='gray-btn' onClick={hideJoinPwd}>取消</Col>
              </Row>
            </Modal>
          </Col>
        }
        {
          nav == 'nav2' &&
          <Col className='right-main'>
            <Row justify='space-between' className='search-box nav2'>
              <Col>
                <Row>
                  <Col className={`tab-item ${myListType == 'my' ? 'current' : ''}`} onClick={() => setMyListType('my')}>我创建的群（{myListTotal}）</Col>
                  <Col className={`tab-item ${myListType == 'disband' ? 'current' : ''}`} onClick={() => setMyListType('disband')}>解散中的群（{disbandListTotal}）</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {applyList.length != 0 && <Col className='apply_btn' onClick={showApplyModal}>申请列表({applyList.length})</Col>}
                  <Col className='add-btn' onClick={showCreateModal}>
                    <img src={addIcon} className='add-icon' />
                    <span>创建新群</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            {
              myListType == 'my' &&
              <>
                <Row className='list-box'>
                  {
                    myList.length != 0 &&
                    myList.map(item => {
                      return (
                        <Col key={item.id}>
                          <Row justify='space-between' className='list-header' align='middle'>
                            <Col>
                              {
                                item.status == 2 &&
                                <>
                                  <span className='header-tip blue'>我加入的</span>
                                  <img src={outIcon} className='out-icon' />
                                </>
                              }
                              {
                                item.status == 1 &&
                                <>
                                  <span className='header-tip'>我创建的</span>
                                  <img src={setIcon} className='set-icon' />
                                </>
                              }
                              {
                                item.status == 6 &&
                                <>
                                  <img src={safeIcon} className='safe-icon' />
                                  <img src={joinIcon} className='key-icon' />
                                </>
                              }
                            </Col>
                            <Col>ID：{item.qid}</Col>
                          </Row>
                          <Row className='middle-box' align='middle'>
                            <Col>
                              <p className='font1'>{item.title}</p>
                              <p className='font2'>
                                <img src={nameIcon} />
                                <span>{common28Store.uinfo.uname}</span>
                              </p>
                              <p className='font3'>
                                <img src={coinIcon} />
                                <span>{shortNumber(item.max_gold)}</span>
                              </p>
                            </Col>
                            <Col className='list-icon'>
                              <img src={item.icon} />
                            </Col>
                          </Row>
                          <Row justify='space-between' align='middle' className='bottom-box'>
                            <Col className='btn orange' onClick={() => toLink(`/hbao?nav=enterGame&id=${item.qid}`)}>进入游戏</Col>
                            <Col>
                              <Row className='font4' align='middle'>
                                <Col className='set-btn' onClick={() => hbaoGinfoFun(item.qid, showSetDialog)}>设置</Col>
                                <Col className='cancel-btn' onClick={() => showCancelModal(item)}>解散群</Col>
                                <Col>
                                  <img src={numIcon} className='num-icon' />
                                  <span>{item.person}人游戏中</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )
                    })
                  }
                </Row>
                {myList.length == 0 && <Empty centered className='empty-box' />}
                {myList.length != 0 && <Pagination current={myListPage} total={myListTotal} size={myListLimit} centered onChange={(page) => { hbaoMyListFun({ page }) }} />}
              </>
            }
            {
              myListType == 'disband' &&
              <>
                <Row className='list-box'>
                  {
                    disbandList.length != 0 &&
                    disbandList.map(item => {
                      return (
                        <Col key={item.id}>
                          <Row justify='space-between' className='list-header' align='middle'>
                            <Col>
                              {
                                item.status == 2 &&
                                <>
                                  <span className='header-tip blue'>我加入的</span>
                                  <img src={outIcon} className='out-icon' />
                                </>
                              }
                              {
                                item.status == 1 &&
                                <>
                                  <span className='header-tip'>我创建的</span>
                                  <img src={setIcon} className='set-icon' />
                                </>
                              }
                              {
                                item.status == 6 &&
                                <>
                                  <img src={safeIcon} className='safe-icon' />
                                  <img src={joinIcon} className='key-icon' />
                                </>
                              }
                            </Col>
                            <Col>ID：{item.qid}</Col>
                          </Row>
                          <Row className='middle-box' align='middle'>
                            <Col>
                              <p className='font1'>{item.title}</p>
                              <p className='font2'>
                                <img src={nameIcon} />
                                <span>{common28Store.uinfo.uname}</span>
                              </p>
                              <p className='font3'>
                                <img src={coinIcon} />
                                <span>{shortNumber(item.max_gold)}</span>
                              </p>
                            </Col>
                            <Col className='list-icon'>
                              <img src={item.icon} />
                            </Col>
                          </Row>
                          <Row justify='space-between' align='middle' className='bottom-box'>
                            <Col className='btn cancel' onClick={() => hbaoQuitDisbandFun(item.qid)}>取消解散</Col>
                            <Col>
                              <Row className='font4' align='middle'>
                                <Col>
                                  <img src={numIcon} className='num-icon' />
                                  <span>{item.person}人游戏中</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )
                    })
                  }
                </Row>
                {disbandList.length == 0 && <Empty centered className='empty-box' />}
                {disbandList.length != 0 && <Pagination current={disbandListPage} total={disbandListTotal} size={disbandListLimit} centered onChange={(page) => { hbaoMyListFun({ page }) }} />}
              </>
            }
            {/* 解散确认 */}
            <Modal className='hbao-modal'
              title=""
              open={isShowCancelModal}
              footer={null}
              onCancel={hideCancelModal}
              width={520}
              closable={false}
              centered
              destroyOnClose={true}>
              <h6 className='modal-title'>解散确认</h6>
              <p className='cancel-modal-tip'>确定解散当前群？</p>
              <div className='cancel-modal-main'>
                <Row>
                  <Col className='cancel-label'>群名称： </Col>
                  <Col className='cancel-con'>{curQInfo.title}</Col>
                </Row>
                <Row>
                  <Col className='cancel-label'>群ID：  </Col>
                  <Col className='cancel-con'>{curQInfo.qid}</Col>
                </Row>
                <Row>
                  <Col className='cancel-label'>创建时间：  </Col>
                  <Col className='cancel-con'>{curQInfo.cdate}</Col>
                </Row>
                <Row>
                  <Col className='cancel-label'>加群人数：   </Col>
                  <Col className='cancel-con'>{curQInfo.person}人</Col>
                </Row>
              </div>
              <ul className='cancel-ruler'>
                <li>解散说明：</li>
                <li> 1. 解散群提交后，相关群内未抢红包将原路退回；</li>
                <li className='red'> 2. 解散群申请提交24小时后会自动解散，在此之前可申请取消；</li>
                <li>3. 群成功解散后，会重新释放建群数量限制。</li>
              </ul>
              <Row className='modal-btn' align='middle' justify='center'>
                <Col className='orange-btn' onClick={hbaoDisbandFun}>申请解散</Col>
                <Col className='gray-btn' onClick={hideCancelModal}>取消</Col>
              </Row>
            </Modal>
          </Col>
        }
        {
          nav == 'nav3' &&
          <Col className='right-main'>
            <Row justify='space-between' className='search-box nav2'>
              <Col>
                <Row>
                  <Col className={`tab-item ${joinListType == 'join' ? 'current' : ''}`} onClick={() => setJoinListType('join')}>我加入的群（{joinListTotal}）</Col>
                  <Col className={`tab-item ${joinListType == 'myApply' ? 'current' : ''}`} onClick={() => setJoinListType('myApply')}>申请中的群（{myApplyListTotal}）</Col>
                </Row>
              </Col>
              <Col>
              </Col>
            </Row>
            {
              joinListType == 'join' &&
              <>
                <Row className='list-box'>
                  {
                    joinList.length != 0 &&
                    joinList.map(item => {
                      return (
                        <Col key={item.id}>
                          <Row justify='space-between' className='list-header' align='middle'>
                            <Col>
                              {
                                item.status == 2 &&
                                <>
                                  <span className='header-tip blue'>我加入的</span>
                                  <img src={outIcon} className='out-icon' />
                                </>
                              }
                              {
                                item.status == 1 &&
                                <>
                                  <span className='header-tip'>我创建的</span>
                                  <img src={setIcon} className='set-icon' />
                                </>
                              }
                              {
                                item.status == 6 &&
                                <>
                                  <img src={safeIcon} className='safe-icon' />
                                  <img src={joinIcon} className='key-icon' />
                                </>
                              }
                            </Col>
                            <Col>ID：{item.qid}</Col>
                          </Row>
                          <Row className='middle-box' align='middle'>
                            <Col>
                              <p className='font1'>{item.title}</p>
                              <p className='font2'>
                                <img src={nameIcon} />
                                <span>{common28Store.uinfo.uname}</span>
                              </p>
                              <p className='font3'>
                                <img src={coinIcon} />
                                <span>{shortNumber(item.max_gold)}</span>
                              </p>
                            </Col>
                            <Col className='list-icon'>
                              <img src={item.icon} />
                            </Col>
                          </Row>
                          <Row justify='space-between' align='middle' className='bottom-box'>
                            <Col className='btn orange' onClick={() => toLink(`/hbao?nav=enterGame&id=${item.qid}`)}>进入游戏</Col>
                            <Col>
                              <Row className='font4' align='middle'>
                                <Col className='cancel-btn' onClick={() => showQuitModal(item)}>退出该群</Col>
                                <Col>
                                  <img src={numIcon} className='num-icon' />
                                  <span>{item.person}人游戏中</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )
                    })
                  }
                </Row>
                {joinList.length == 0 && <Empty centered className='empty-box' />}
                {joinList.length != 0 && <Pagination current={joinListPage} total={joinListTotal} size={joinListLimit} centered onChange={(page) => { hbaoJoinListFun({ page }) }} />}
              </>
            }
            {
              joinListType == 'myApply' &&
              <>
                <Row className='list-box'>
                  {
                    myApplyList.length != 0 &&
                    myApplyList.map(item => {
                      return (
                        <Col key={item.id}>
                          <Row justify='space-between' className='list-header yellow' align='middle'>
                            <Col>
                            </Col>
                            <Col>ID：{item.qid}</Col>
                          </Row>
                          <Row className='middle-box' align='middle'>
                            <Col>
                              <p className='font1'>{item.title}</p>
                              <p className='font2'>
                                <img src={nameIcon} />
                                <span>{common28Store.uinfo.uname}</span>
                              </p>
                              <p className='font3'>
                                <img src={coinIcon} />
                                <span>{shortNumber(item.max_gold)}</span>
                              </p>
                            </Col>
                            <Col className='list-icon'>
                              <img src={item.icon} />
                            </Col>
                          </Row>
                          <div className='myApply-date'>发起时间：{item.cdate}</div>
                          <Row justify='space-between' align='middle' className='bottom-box'>
                            <Col className='font5'>审核中</Col>
                            <Col>
                              <Row className='font4' align='middle'>
                                <Col>
                                  <img src={numIcon} className='num-icon' />
                                  <span>{item.person}人游戏中</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )
                    })
                  }
                </Row>
                {myApplyList.length == 0 && <Empty centered className='empty-box' />}
                {myApplyList.length != 0 && <Pagination current={myApplyListPage} total={myApplyListTotal} size={myApplyListLimit} centered onChange={(page) => { hbaoMyApplyFun({ page }) }} />}
              </>
            }
          </Col>
        }
        {
          nav == 'nav4' &&
          <Col className='right-main'>
            <div className='orange-title'>红包统计</div>
            <Row className='date-search' align='middle'>
              <Col><RangePicker onChange={changeDate} /></Col>
              <Col className='search-btn' onClick={() => hbaoStatisticFun({ sdate: statisticSdate, edate: statisticEdate, page: 1 })}>搜索</Col>
            </Row>
            <Table className='hbao-table'
              columns={[
                {
                  title: '日期',
                  dataIndex: 'date',
                  key: 'date'
                },
                {
                  title: '被抢红包',
                  dataIndex: 'robb_gold',
                  key: 'robb_gold',
                  render: (text) => {
                    return <>
                      <span>{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '埋雷收入',
                  dataIndex: 'mine_win',
                  key: 'mine_win',
                  render: (text) => {
                    return <>
                      <span>{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '抢到红包',
                  dataIndex: 'grab_gold',
                  key: 'grab_gold',
                  render: (text) => {
                    return <>
                      <span>{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '踩雷支出',
                  dataIndex: 'min_lose',
                  key: 'min_lose',
                  render: (text) => {
                    return <>
                      <span>{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '当日盈亏',
                  dataIndex: 'win_lose',
                  key: 'win_lose',
                  render: (text) => {
                    return <>
                      <span>{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                }
              ]}
              dataSource={statisticList}
              pagination={false}
            >
            </Table>
            {statisticList.length != 0 && <Pagination current={statisticPage} total={statisticTotal} size={statisticLimit} centered onChange={(page) => { hbaoStatisticFun({ page, sdate: statisticSdate, edate: statisticEdate }) }} />}
          </Col>
        }
        {
          nav == 'nav5' &&
          <Col className='right-main'>
            <div className='orange-title'>红包记录</div>
            <Row className='date-search' align='middle'>
              <Col className='first'>
                <Input placeholder='红包ID' value={detailHbid} onChange={(e) => { setDetailHbid(e.target.value) }} />
              </Col>
              <Col>
                <Input placeholder='群ID' value={detailQid} onChange={(e) => { setDetailQid(e.target.value) }} />
              </Col>
              <Col className='search-btn' onClick={() => hbaoDetailFun({ hbid: detailHbid, qid: detailQid, page: 1 })}>搜索</Col>
            </Row>
            <Table className='hbao-table'
              columns={[
                {
                  title: '时间',
                  dataIndex: 'mdate',
                  key: 'mdate'
                },
                {
                  title: '红包ID',
                  dataIndex: 'hbid',
                  key: 'hbid'
                },
                {
                  title: '红包群ID',
                  dataIndex: 'qid',
                  key: 'qid'
                },
                {
                  title: '类型',
                  dataIndex: 'type',
                  key: 'type',
                  render: (text) => {
                    return text == 1 ? '抢红包' : '被抢红包'
                  }
                },
                {
                  title: '金豆变化',
                  dataIndex: 'win_lose',
                  key: 'win_lose'
                }
              ]}
              dataSource={detailList}
              pagination={false}
            >
            </Table>
            {detailList.length != 0 && <Pagination current={detailPage} total={detailTotal} size={detailLimit} centered onChange={(page) => { hbaoDetailFun({ page, qid: detailQid, hbid: detailHbid }) }} />}
          </Col>
        }
        {
          nav == 'nav6' &&
          <Col className='right-main'>
            <div className='orange-title'>抢红包记录</div>
            <Row className='date-search' align='middle'>
              <Col className='first'>
                <Input placeholder='红包ID' value={grabHbid} onChange={(e) => { setGrabHbid(e.target.value) }} />
              </Col>
              <Col>
                <Input placeholder='群ID' value={grabQid} onChange={(e) => { setGrabQid(e.target.value) }} />
              </Col>
              <Col className='search-btn' onClick={() => grabLogFun({ hbid: grabHbid, qid: grabQid, page: 1 })}>搜索</Col>
            </Row>
            <Table className='hbao-table'
              columns={[
                {
                  title: '红包群',
                  dataIndex: 'qid',
                  key: 'qid',
                  render: (text, item) => {
                    return <span>[{text}]{item.title}</span>
                  }
                },
                {
                  title: '红包ID',
                  dataIndex: 'sign',
                  key: 'sign',
                  width: '160px',
                  render: (text) => {
                    return <div style={{ whiteSpace: 'nowrap' }}>{text}<span className='check-btn' onClick={() => hbaoInfoFun(text)}>检验</span></div>
                  }
                },
                {
                  title: '发包者',
                  dataIndex: 'uname',
                  key: 'uname'
                },
                {
                  title: '抢包获得',
                  dataIndex: 'gold',
                  key: 'gold',
                  className: 'nowrap',
                  render: (text) => {
                    return <>
                      <span className={text.replace(',', '') > 0 ? 'orange' : 'green'}>{text.replace(',', '') > 0 ? '+' : ''}{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '踩雷赔付',
                  dataIndex: 'lose',
                  key: 'lose',
                  className: 'nowrap',
                  render: (text) => {
                    return <>
                      <span className={text.replace(',', '') < 0 ? 'green' : 'orange'}>{text.replace(',', '') > 0 ? '+' : ''}{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '抢包盈亏',
                  dataIndex: 'win_lose',
                  key: 'win_lose',
                  className: 'nowrap',
                  render: (text) => {
                    return <>
                      <span className={text.replace(',', '') < 0 ? 'green' : 'orange'}>{text.replace(',', '') > 0 ? '+' : ''}{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '抢包时间',
                  dataIndex: 'mdate',
                  key: 'mdate'
                }
              ]}
              dataSource={grabList}
              pagination={false}
            >
            </Table>
            {grabList.length != 0 && <Pagination current={grabPage} total={grabTotal} size={grabLimit} centered onChange={(page) => { grabLogFun({ page, qid: grabQid, hbid: grabHbid }) }} />}
          </Col>
        }
        {
          nav == 'nav7' &&
          <Col className='right-main'>
            <div className='orange-title'>发红包记录</div>
            <Row className='date-search' align='middle'>
              <Col className='first'>
                <Input placeholder='红包ID' value={hbaoLogHbid} onChange={(e) => { setHbaoLogHbid(e.target.value) }} />
              </Col>
              <Col>
                <Input placeholder='群ID' value={hbaoLogQid} onChange={(e) => { setHbaoLogQid(e.target.value) }} />
              </Col>
              <Col>
                <Select
                  defaultValue={0}
                  style={{ width: 130 }}
                  onChange={changeStatus}
                  options={[
                    { value: 0, label: '红包状态' },
                    { value: 1, label: '领取中' },
                    { value: 2, label: '已领完' },
                    { value: 3, label: '已过期' },
                  ]}
                />
              </Col>
              <Col className='search-btn' onClick={() => hbaoLogFun({ hbid: hbaoLogHbid, qid: hbaoLogQid, page: 1 })}>搜索</Col>
            </Row>
            <Table className='hbao-table'
              columns={[
                {
                  title: '红包群',
                  dataIndex: 'qid',
                  key: 'qid',
                  render: (text, item) => {
                    return <span>[{text}]{item.title}</span>
                  }
                },
                {
                  title: '红包ID',
                  dataIndex: 'sign',
                  key: 'sign',
                  width: '160px',
                  render: (text) => {
                    return <div style={{ whiteSpace: 'nowrap' }}>{text}<span className='check-btn' onClick={() => hbaoInfoFun(text)}>检验</span></div>
                  }
                },
                {
                  title: '金豆数',
                  dataIndex: 'gold',
                  key: 'gold',
                  render: (text) => {
                    return <span>{text}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></span>
                  }
                },
                {
                  title: '已抢/总数',
                  dataIndex: 'num',
                  key: 'num',
                  render: (text, item) => {
                    return <span>{text - item.balance}/{text}</span>
                  }
                },
                {
                  title: '实际盈亏',
                  dataIndex: 'win_lose',
                  key: 'win_lose',
                  render: (text) => {
                    return <span className={text > 0 ? 'orange' : 'green'}>{text > 0 ? '+' : ''}{text}</span>
                  }
                },
                {
                  title: '发包时间',
                  dataIndex: 'cdate',
                  key: 'cdate'
                },
                {
                  title: '状态',
                  dataIndex: 'status',
                  key: 'status'
                }
              ]}
              dataSource={hbaoLogList}
              pagination={false}
            >
            </Table>
            {hbaoLogList.length != 0 && <Pagination current={hbaoLogPage} total={hbaoLogTotal} size={hbaoLogLimit} centered onChange={(page) => { hbaoLogFun({ page, qid: hbaoLogQid, hbid: hbaoLogHbid }) }} />}
          </Col>
        }
        {
          nav == 'nav8' &&
          <Col className='right-main'>
            <div className='orange-title'>红包玩法说明</div>
            <ul className='rule-box'>
              {
                ruleList.map((item, index) => {
                  return <li key={`rule${index}`}>{index + 1}、{item}</li>
                })
              }
            </ul>
          </Col>
        }
        {
          nav == 'enterGame' &&
          <Col className='right-main'>
            <Chat id={curGameId} />
          </Col>
        }
      </Row>
      {/* 新建群&编辑群 */}
      <Modal className='hbao-modal'
        title=""
        open={isShowCreateModal}
        footer={null}
        onCancel={hideCreateModal}
        width={720}
        closable={false}
        centered>
        <h6 className='modal-title'>{curQInfo.qid ? '设置群' : '新建群'}</h6>
        <Row className='modal-form' align='middle'>
          <Col>群名称：</Col>
          <Col>
            <Input showCount placeholder='请输入群名称请输入群名称请输入群' maxLength={16} className='modal-input' value={title} onChange={(e) => {
              setTitle(e.target.value)
            }} />
          </Col>
        </Row>
        <Row className='modal-form' align='middle'>
          <Col>加群方式：</Col>
          <Col>
            <Radio.Group onChange={(e) => {
              setJoinType(e.target.value)
            }}
              value={join_type}>
              <Radio value={1}>无需审核</Radio>
              <Radio value={2}>群主审核</Radio>
              <Radio value={3}>口令加群</Radio>
            </Radio.Group>
          </Col>
        </Row>
        {
          join_type == 3 &&
          <Row className='modal-form pwd' align='middle'>
            <Col></Col>
            <Col>
              <Input value={join_pwd} showCount maxLength={6} placeholder='请设置六位数字或字母乱序口令' className='modal-input' onChange={(e) => {
                setJoinPwd(e.target.value)
              }} />
            </Col>
          </Row>
        }
        <Row className='modal-form' align='middle'>
          <Col>搜索方式：</Col>
          <Col>
            <Checkbox.Group options={[
              {
                label: '群名称',
                value: 'search_title',
              },
              {
                label: 'ID',
                value: 'search_qid',
              },
              {
                label: '群主ID',
                value: 'search_uid',
              },
            ]}
              onChange={(e) => {
                setSearchType(e)
              }}
              value={searchType} />
          </Col>
        </Row>
        <Row className='modal-form' align='top'>
          <Col>加群等级：</Col>
          <Col>
            <Radio.Group value={limit_level} onChange={(e) => { setLimitLevel(e.target.value); setLimitLevelNum('') }}>
              <Space direction="vertical">
                <Radio value={-1}>不限等级</Radio>
                <Radio value={1}><span>限制加群等级</span><Input className='modal-level' value={limit_level_num} onChange={(e) => { setLimitLevelNum(e.target.value) }} /><span>级含以上</span></Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row className='modal-form' align='top'>
          <Col>红包金额：</Col>
          <Col>
            <Row className='modal-num'>
              <Col onClick={() => selectGolds(5000)} className={golds.includes(5000) ? 'current' : ''}>5000金豆</Col>
              <Col onClick={() => selectGolds(10000)} className={golds.includes(10000) ? 'current' : ''}>10000金豆</Col>
              <Col onClick={() => selectGolds(20000)} className={golds.includes(20000) ? 'current' : ''}>20000金豆</Col>
              <Col onClick={() => selectGolds(50000)} className={golds.includes(50000) ? 'current' : ''}>50000金豆</Col>
              <Col onClick={() => selectGolds(100000)} className={golds.includes(100000) ? 'current' : ''}>100000金豆</Col>
              <Col onClick={() => selectGolds(200000)} className={golds.includes(200000) ? 'current' : ''}>200000金豆</Col>
            </Row>
          </Col>
          <Col className='modal-num-tip'>（选择发红包的面额可多选）</Col>
        </Row>
        <Row className='modal-form' align='top'>
          <Col>建群说明：</Col>
          <Col>
            <ul className='modal-rule'>
              <li>1.建群次数会随着用户等级提升而增加；</li>
              <li className='red'>2.群名称不得包含任何广告宣传或联系方式，否则永久失去建群资格！</li>
              <li>3.群主可获得群内所有已抢红包 2‰的流水奖励，次日自动结算发放。</li>
            </ul>
          </Col>
        </Row>
        <Row className='modal-btn' align='middle' justify='center'>
          <Col className='orange-btn' onClick={curQInfo.qid ? hbaoEditGroupFun : addGroupFun}>{curQInfo.qid ? '提交' : '创建'}</Col>
          <Col className='gray-btn' onClick={hideCreateModal}>取消</Col>
          {/* <Col className='modal-add-num'>建群名额：3/10</Col> */}
        </Row>
        {/* 图标 */}
        <div className='modal-icon-box' id='modal-icon-box'>
          <p className='icon-img'><img src={iconImg} /></p>
          <Tooltip className='icon-select-box'
            placement="bottomRight"
            open={isOpenIcon}
            title={
              <Row className='modal-icon-img' align='middle'>
                {
                  hbaoIconList.map(item => {
                    return <Col key={item} onClick={() => { setIconImg(item); setIsOpenIcon(false) }}><img src={item} /></Col>
                  })
                }
              </Row>
            }
            trigger="click"
            getPopupContainer={() => document.getElementById('modal-icon-box')}
            onOpenChange={handleOpenChange}>
            <Row justify='center' className='select-icon-tip' align='middle' onClick={() => { setIsOpenIcon(true) }}>
              <Col>选择图标</Col>
              <Col><CaretDownOutlined /></Col>
            </Row>
          </Tooltip >
        </div>
      </Modal>
      {/* 申请列表 */}
      <Modal className='hbao-modal'
        title=""
        open={isShowApplyModal}
        footer={null}
        onCancel={hideApplyModal}
        width={770}
        closable={false}
        centered>
        <h6 className='modal-title'>申请列表</h6>
        <Row justify='space-between' className='apply-search'>
          <Col>
            <span>等级：</span>
            <Select
              className='level-select'
              value={applyLimitLevel}
              style={{
                width: 120,
              }}
              options={[
                {
                  value: '-1',
                  label: '不限等级',
                }, {
                  value: '1',
                  label: '1级',
                }, {
                  value: '2',
                  label: '2级',
                }, {
                  value: '3',
                  label: '3级',
                }, {
                  value: '4',
                  label: '4级',
                }, {
                  value: '5',
                  label: '5级',
                }, {
                  value: '6',
                  label: '6级',
                }, {
                  value: '7',
                  label: '7级',
                }, {
                  value: '8',
                  label: '8级',
                }, {
                  value: '9',
                  label: '9级',
                }, {
                  value: '10',
                  label: '10级',
                }
              ]}
              onChange={(value) => { hbaoApplyListFun({ limit_level: value, page: 1 }); setApplyPage(1); setApplyLimitLevel(value) }}
            />
          </Col>
          <Col>
            {
              applyList.length != 0 &&
              <Row className='oper-btns'>
                <Col onClick={() => hbaoExamineFun({ id: 0, type: 1 })}>一键同意</Col>
                <Col onClick={() => hbaoExamineFun({ id: 0, type: 1 })} className='red'>一键拒绝</Col>
              </Row>
            }
          </Col>
        </Row>
        {
          applyList.map((item, index) => {
            return (
              <Row justify='space-between' className='apply-list' align='middle' key={`applyList${index}`}>
                <Col>
                  <Row align='middle'>
                    <Col>
                      <Avatar size={60} src={item.head} />
                    </Col>
                    <Col className='apply-info'>
                      <Row justify='space-between'>
                        <Col>
                          <span className='font1'>{item.uname}</span>
                          <span className='font2'>V{item.level}</span>
                        </Col>
                        <Col>{item.cdate}</Col>
                      </Row>
                      <div className='font3'>申请加群：{item.title}（ID:{item.qid})</div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className='list-oper-btns'>
                    <Col onClick={() => hbaoExamineFun({ id: item.id, type: 1 })}>同意</Col>
                    <Col onClick={() => hbaoExamineFun({ id: item.id, type: 0 })} className='red'>拒绝</Col>
                  </Row>
                </Col>
              </Row>
            )
          })
        }
        {applyList.length != 0 && <Pagination current={applyPage} centered total={applyTotal} pageSize={applyLimit} onChange={(page) => hbaoApplyListFun({ limit_level: applyLimitLevel, page })} />}
        {applyList.length == 0 && <Empty />}
      </Modal>
      {/* 退出确认 */}
      <Modal className='hbao-modal'
        title=""
        open={isShowQuitModal}
        footer={null}
        onCancel={hideQuitModal}
        width={520}
        closable={false}
        centered
        destroyOnClose={true}>
        <h6 className='modal-title'>退出确认</h6>
        <p className='cancel-modal-tip'>确定退出当前群？</p>
        <div className='cancel-modal-main'>
          <Row>
            <Col className='cancel-label'>群名称： </Col>
            <Col className='cancel-con'>{curQInfo.title}</Col>
          </Row>
          <Row>
            <Col className='cancel-label'>群ID：  </Col>
            <Col className='cancel-con'>{curQInfo.qid}</Col>
          </Row>
          <Row>
            <Col className='cancel-label'>创建时间：  </Col>
            <Col className='cancel-con'>{curQInfo.cdate}</Col>
          </Row>
          <Row>
            <Col className='cancel-label'>加群人数：   </Col>
            <Col className='cancel-con'>{curQInfo.person}人</Col>
          </Row>
        </div>
        <div className='quit-ruler'>退群说明：退群提交后，相关群内本人发布的未抢红包将原路退回</div>
        <Row className='modal-btn' align='middle' justify='center'>
          <Col className='orange-btn' onClick={() => hbaoQuitOutFun(curQInfo.qid)}>确认</Col>
          <Col className='gray-btn' onClick={hideQuitModal}>取消</Col>
        </Row>
      </Modal>
      {/* 红包校验 */}
      <Modal className='ucenter-modal'
        title=""
        open={isShowHbaoDetail}
        footer={null}
        onCancel={hideHbaoDetail}
        width={900}
        centered="true"
        destroyOnClose={true}>
        <h6 className='modal-title'>校验详情（红包ID：{curHbaoId}）</h6>
        <Row className='level-info'>
          <Col>红包：{hbaoNum}个</Col>
          <Col>已抢：{hbaoDeal}个</Col>
          <Col>金豆：{shortNumber(hbaoGold)}</Col>
          <Col>状态：{getHbaoStatus(hbaoStatus)}</Col>
        </Row>
        <Table className='level-table'
          columns={[
            {
              title: '玩家',
              dataIndex: 'uname',
              key: 'uname'
            },
            {
              title: '抢到',
              dataIndex: 'gold',
              key: 'gold',
              render: (text) => {
                return <span className={text.replace(',', '') > 0 ? 'orange' : 'green'}>{text}</span>
              }
            },
            {
              title: '踩雷',
              dataIndex: 'lose',
              key: 'lose',
              render: (text) => {
                if (text) {
                  return <span className={text.replace(',', '') > 0 ? 'orange' : 'green'}>{text}</span>
                } else {
                  return '--'
                }
              }
            },
            {
              title: '时间',
              dataIndex: 'mdate',
              key: 'mdate',
              render: (text) => {
                return moment(text * 1000).format('MM-DD HH:mm:ss')
              }
            }
          ]}
          dataSource={hbaoDetailList}
          pagination={false}
        >
        </Table>
        <Row className='level-title' align='middle'>
          <Col className='title-icon'></Col>
          <Col>等级说明</Col>
        </Row>
        <ul className='level-rule'>
          {
            hbaoDetailRule.map((item, index) => {
              return <li dangerouslySetInnerHTML={{ __html: item }} key={`friendRule${index}`}></li>
            })
          }
        </ul>
      </Modal>
    </div>
  )
}

export default observer(Hbao)