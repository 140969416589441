import { Row, Col, message, Avatar, Popover, Modal, Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { useStore } from '@/store'
import { useEffect, useState } from 'react'
import { loginOut } from '@/request/api'
import { shortNumber } from '@/utils'
import logoIcon from '@/assets/logo.png'
import './index.scss'
import refreshIcon from '@/assets/top-login/refresh.png'
import logoutIcon from '@/assets/top-login/logout.png'
const down_qrcode = `https:///static/img/down.png`

function TopLogin (props) {
  const { common28Store, utokenStore } = useStore()
  const navigate = useNavigate()
  useEffect(() => {
    common28Store.setUserInfo(() => {
      if (common28Store.uinfo.alert.length != 0) {
        setMessage(common28Store.uinfo.alert)
        showDialog()
      }
    })
  }, [])

  const loginOutFun = async () => {
    const res = await loginOut()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      localStorage.removeItem('txhAuthKey')
      utokenStore.setUtoken()
      window.location.href = '/login'
    }
  }

  const refreshFun = () => {
    window.location.reload()
  }

  const toInnerPath = (path) => {
    navigate(path)
  }

  //消息弹窗
  let [curMsgIndex, setCurMsgIndex] = useState(0)
  let [messageArr, setMessage] = useState([{ title: '', content: '' }])
  let [isShowDialog, setIsShowDialog] = useState(false)
  const showDialog = () => {
    setIsShowDialog(true)
  }

  const hideDialog = () => {
    setIsShowDialog(false)
  }

  // 切换弹窗信息
  const changeMsg = (type) => {
    if (type == 'prev') {
      setCurMsgIndex(curMsgIndex - 1)
    }
    if (type == 'next') {
      setCurMsgIndex(curMsgIndex + 1)
    }
  }


  return (
    <div className='top-login'>
      <Row justify='space-between' align='middle'>
        <Col className='left-part'>
          <Link to='/'><img src={logoIcon} className='logo-icon' /></Link>
        </Col>
        <Col className='right-part'>
          <Row className='user-info'>
            {
              !props.utoken &&
              <>
                <Col className='right-item'>
                  <Link to='/login' className='link orange'>请登录</Link>
                </Col>
                <Col className='right-item'>
                  <Link to='/register' className='link'>免费注册</Link>
                </Col>
              </>
            }
            {
              props.utoken &&
              <>
                <Col className='id-box'>
                  <Avatar size={40} src={common28Store.uinfo.head}></Avatar>
                  <span className='uname-text'>{common28Store.uinfo.uname}</span>
                  <span className='id-text'>ID：{common28Store.uinfo.id}</span>
                </Col>
                <Col>
                  <span>等级：</span>
                  <span className='orange'>Lv{common28Store.uinfo.level}</span>
                </Col>
                <Col>
                  <span>金豆：</span>
                  <span className='orange'>{shortNumber(common28Store.uinfo.gold)}</span>
                  <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                </Col>
                <Col>
                  <span>保险柜：</span>
                  <span className='orange'>{shortNumber(common28Store.uinfo.bank)}</span>
                  <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                </Col>
                <Col className='point-box orange' onClick={() => toInnerPath('/activity')}>充值</Col>
                <Col className='point-box' onClick={refreshFun}>
                  <img src={refreshIcon} className='top-icon' />
                  <span>刷新</span>
                </Col>
                <Col className='point-box' onClick={loginOutFun}>
                  <img src={logoutIcon} className='top-icon' />
                  <span className='orange'>退出</span>
                </Col>
              </>
            }
            <Popover placement="bottomRight" trigger='click' content={<img src={require('@/assets/top-login/down.jpg')} className='download-img' />}>
              <Col className='red'>下载APP</Col>
            </Popover>
          </Row>
        </Col>
      </Row>
      {/* 弹框 */}
      < Modal className='index-modal'
        title=""
        open={isShowDialog}
        footer={null}
        onCancel={hideDialog}>
        <div className='modal-title'>通知</div>
        <h6 className='notice-title'>{messageArr[curMsgIndex].title}</h6>
        <div className='notice-content' dangerouslySetInnerHTML={{ __html: messageArr[curMsgIndex].content }}></div>
        <div className='index-dialog-btns'>
          {curMsgIndex != 0 && <Button onClick={() => changeMsg('prev')}>上一条</Button>}
          {curMsgIndex == 0 && <Button disabled>上一条</Button>}
          {curMsgIndex != messageArr.length - 1 && <Button onClick={() => changeMsg('next')}>下一条</Button>}
          {curMsgIndex == messageArr.length - 1 && <Button disabled>下一条</Button>}
        </div>
      </Modal >
    </div>
  )
}

export default observer(TopLogin)