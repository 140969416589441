import { Row, Col, message, Modal, Table, Avatar, Pagination } from 'antd'
import './index.scss'
import { useSearchParams, Link } from 'react-router-dom'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { luckRule } from '@/request/api/index'
import { getGameName } from '@/utils'

let indexArr = [2, 5, 8, 11, 14, 17, 3, 6, 9, 12, 15, 18, 4, 7, 10, 13, 16, 19]
const LuckGameRule = React.forwardRef((props, ref) => {
  const [search] = useSearchParams()

  // 组件已挂载，可以安全使用useImperativeHandle
  useImperativeHandle(ref, () => ({
    // 自定义的ref行为
    childMethod: (previous) => {
      // 子组件的方法
      if (previous) {
        previous.source = previous.source.split(',')
        setData(previous)
      }
    },
    isRule: true
  }))


  // 数据处理
  const [sumArr, setSumArr] = useState([0, 0, 0])
  const [curInfo, setCurInfo] = useState({ source: [] })
  const setData = (clickItem) => {
    indexArr.map((item, index) => {
      if (index < 6) {
        sumArr[0] += +clickItem.source[item - 1]
      }
      if (index >= 6 && index < 12) {
        sumArr[1] += +clickItem.source[item - 1]
      }
      if (index >= 12 && index < 18) {
        sumArr[2] += +clickItem.source[item - 1]
      }
    })
    setSumArr(sumArr)
    setCurInfo(clickItem)
  }

  return (
    <>
      <div className='luckGame-rule-box'>
        <div className={`tabel-title ${search.get('type')}`}>【{getGameName()}】 第 <span>{curInfo.id}</span> 期 官网开奖号码 20个</div>
        <Row className='table-row1'>
          <Col>号码</Col>
          {
            curInfo.source.map(item => {
              return <Col id={`source${item}`} key={`source${item}`}>{item}</Col>
            })
          }
        </Row>
        <Row className='table-row2'>
          <Col>玩法</Col>
          <Col>分 <span className={`light ${search.get('type')}`}>3</span> 区进行计算</Col>
        </Row>
        <Row className='table-row3'>
          <Col>取位</Col>
          {
            indexArr.map(item => {
              return <Col key={`index${item}`}>{item}</Col>
            })
          }
        </Row>
        <Row className='table-row3'>
          <Col>号数</Col>
          {
            indexArr.map(item => {
              return <Col key={`num${item}`}>{curInfo.source[item - 1]}</Col>
            })
          }
        </Row>
        <Row className='table-row4'>
          <Col>求和</Col>
          {
            sumArr.map((item, index) => {
              return <Col key={`sum${index}`}>{item}</Col>
            })
          }
        </Row>
        <Row className='table-row4'>
          <Col>计算</Col>
          <Col>取尾数</Col>
          <Col>取尾数</Col>
          <Col>取尾数</Col>
        </Row>
        <Row className='table-row4'>
          <Col>结果</Col>
          {
            sumArr.map((item, index) => {
              return <Col key={`last${index}`}>{Math.floor(item % 10)}</Col>
            })
          }
        </Row>
        <Row justify='center' align='middle' className='num-box'>
          <Col className='num-bg1'>{curInfo.num1}</Col>
          <Col className='minus-icon'>+</Col>
          <Col className='num-bg2'>{curInfo.num2}</Col>
          <Col className='minus-icon'>+</Col>
          <Col className='num-bg3'>{curInfo.num3}</Col>
          <Col className='minus-icon'>=</Col>
          <Col className='num-bg4'>{curInfo.num}</Col>
        </Row>
      </div>
      <Row className='activity-title' align='middle'>
        <Col className='title-icon'></Col>
        <Col>活动说明</Col>
      </Row>
      <ul className='activity-rule'>
        <li>投入数字不超过20计为有效流水；</li>
      </ul>
    </>
  )
})

export default LuckGameRule