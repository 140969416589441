import { Row, Col, message, Carousel, Modal, Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import newsIcon from '@/assets/news-icon.png'
import './index.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'
import { Link } from 'react-router-dom'
import { indexInfo } from '@/request/api'
import { useEffect, useState } from 'react'
import coopIcon1 from '@/assets/home/coop/icon1.png'
import coopIcon2 from '@/assets/home/coop/icon2.png'
import coopIcon3 from '@/assets/home/coop/icon3.png'
import coopIcon4 from '@/assets/home/coop/icon4.png'
import coopIcon5 from '@/assets/home/coop/icon5.png'
import coopIcon6 from '@/assets/home/coop/icon6.png'
import gameImg1 from '@/assets/home/game/img1.png'
import gameImg2 from '@/assets/home/game/img2.png'
import gameImg3 from '@/assets/home/game/img3.png'
import gameImg4 from '@/assets/home/game/img4.png'
import gameImg5 from '@/assets/home/game/img5.png'
import gameImg6 from '@/assets/home/game/img6.png'
import gameImg7 from '@/assets/home/game/img7.png'
import gameImg8 from '@/assets/home/game/img8.png'
import gameImg9 from '@/assets/home/game/img9.png'
import gameImg10 from '@/assets/home/game/img10.png'
import bannerImg from '@/assets/home/banner.jpg'

const gameArr = [
  {
    title: '飞车音速',
    img: gameImg1
  },
  {
    title: '魔界塔Demon Tower',
    img: gameImg2
  },
  {
    title: '暴走骑士团',
    img: gameImg3
  },
  {
    title: '划时代·卡牌手游',
    img: gameImg4
  },
  {
    title: '逗趣主公',
    img: gameImg5
  },
  {
    title: '诸神征伐·守护雅典娜',
    img: gameImg6
  },
  {
    title: '小小突击队',
    img: gameImg7
  },
  {
    title: '太古妖皇决',
    img: gameImg8
  },
  {
    title: '万灵公主',
    img: gameImg9
  },
  {
    title: '万灵之王',
    img: gameImg10
  }
]
function Home () {
  const { utokenStore } = useStore()
  useEffect(() => {
    // 获取数据
    indexInfoFun()
  }, [])

  // 首页数据
  let [curMsgIndex, setCurMsgIndex] = useState(0)
  let [bannerArr, setBanner] = useState([])
  let [newsArr, setNews] = useState([])
  let [cooperateArr, setCooperate] = useState([])
  const indexInfoFun = async () => {
    const res = await indexInfo({ type: 1 })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      let { banner, news, message, games, cooperate } = res.data
      setBanner(banner)
      setNews(news)
      setCooperate(cooperate)
      localStorage.setItem('28isShowLuck', res.data.show_luck)
    }
  }


  return (
    <>
      <div className="banner-contaner">
        <Carousel autoplay>
          <div className='banner-img'><img src={bannerImg} /></div>
        </Carousel>
      </div>
      <div className='index-main'>
        <div className='step-box'></div>
        <Row className='news-box' justify='space-between'>
          <Col>
            <img src={newsIcon} className='news-icon' />
            <span className='orange'>公告：</span>
            <Carousel arrows={false} autoplay={true} dots={false} dotPosition="left" autoplaySpeed={2000}>
              {
                newsArr.map(
                  (item, index) =>
                    <div className='news-list' key={`news${index}`}>
                      <Link to={`/notice?id=${item.id}`}>{item.title}</Link>
                    </div>)
              }

            </Carousel>
          </Col>
          <Col>
            <Link className='more-btn' to='/notice'><span>更多</span><RightOutlined /></Link>
          </Col>
        </Row>
        {/* 游戏 */}
        <Row justify='space-between' className='game-title'>
          <Col>游戏试玩</Col>
        </Row>
        <Row className='game-list'>
          {
            gameArr.map(
              (item, index) =>
                <Col key={`game${index}`} onClick={() => message.warning('请下载APP体验试玩赚钱')}>
                  <img src={item.img} alt="" className='game-img' />
                  <div className='game-title'>
                    <div className='font'>{item.title}</div>
                    <Link className='btn'>开始试玩</Link>
                  </div>
                </Col>
            )
          }
        </Row>
        {/* 合作伙伴 */}
        <div className='coop-title'><span>合作伙伴</span></div>
      </div>
      <Row className='coop-list' justify='center'>
        <Col>
          <img src={coopIcon1} />
        </Col>
        <Col>
          <img src={coopIcon2} />
        </Col>
        <Col>
          <img src={coopIcon3} />
        </Col>
        <Col>
          <img src={coopIcon4} />
        </Col>
        <Col>
          <img src={coopIcon5} />
        </Col>
        <Col>
          <img src={coopIcon6} />
        </Col>
      </Row>
    </>
  )
}

export default observer(Home)