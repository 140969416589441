import { Row, Col, message, Input, Button, Radio, Table, Avatar, Pagination } from 'antd'
import './index.scss'
import { useSearchParams, Link } from 'react-router-dom'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { luckHistory } from '@/request/api/index'
import { getGameName } from '@/utils'

const LuckGameTrend = React.forwardRef((props, ref) => {
  const [search] = useSearchParams()
  let [searchNumber, setSearchNumber] = useState(100)
  useEffect(() => {
    getData({
      number: searchNumber
    })
  }, [])



  // 组件已挂载，可以安全使用useImperativeHandle
  useImperativeHandle(ref, () => ({
    // 自定义的ref行为
    childMethod: () => {
      // 子组件的方法
    }
  }))



  // 获取数据
  let [avg, setAvg] = useState([])
  let [real, setReal] = useState([])
  let [code, setCode] = useState([])
  let [number, setNumber] = useState([])
  let [list, setList] = useState([])
  const getData = async (params) => {
    const res = await luckHistory({ ...params, type: search.get('type') })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setAvg(res.data.avgs)
      setReal(res.data.real)
      setCode(res.data.num)
      setNumber(res.data.number)
      setList(res.data.list)
    }
  }

  return (
    <div className="trend-container">
      <div className='trend-main'>
        <Radio.Group
          className='radio-box'
          name="radiogroup"
          defaultValue={100}
          onChange={e => getData({
            number: e.target.value
          })}
          options={[
            { value: 100, label: '近100期' },
            { value: 200, label: '近200期' },
            { value: 300, label: '近300期' },
            { value: 400, label: '近400期' },
            { value: 500, label: '近500期' },
          ]}
        />
        <table className='trend-table'>
          <tr>
            <th>平均间隔</th>
            {
              avg.map((item, index) => {
                return <th key={`avg${index}`}>{item}</th>
              })
            }
            <th key={`avg10000`}></th>
            <th key={`avg10001`}></th>
            <th key={`avg10002`}></th>
            <th key={`avg10003`}></th>
            <th key={`avg10004`}></th>
          </tr>
          <tr>
            <th>实际间隔</th>
            {
              real.map((item, index) => {
                return <th key={`real${index}`}>{item}</th>
              })
            }
            <th key={`real10000`}></th>
            <th key={`real10001`}></th>
            <th key={`real10002`}></th>
            <th key={`real10003`}></th>
            <th key={`real10004`}></th>
          </tr>
          <tr>
            <th>出现次数</th>
            {
              number.map((item, index) => {
                return <th key={`number${index}`}>{item}</th>
              })
            }
            <th colSpan='2' key={`number10000`}>尾数</th>
            <th colSpan='3' key={`number10001`}>余数</th>
          </tr>
          <tr>
            <th>期号</th>
            {
              code.map((item, index) => {
                return <th key={`code${index}`}><div className='th-num'>{item}</div></th>
              })
            }
            <th key={`code10000`}>单</th>
            <th key={`code10001`}>双</th>
            <th key={`code10002`}>中</th>
            <th key={`code10003`}>边</th>
            <th key={`code10004`}>大</th>
            <th key={`code10005`}>小</th>
            <th key={`code10006`}>小</th>
            <th key={`code10007`}>大</th>
            <th key={`code10008`}>/3</th>
            <th key={`code10009`}>/4</th>
            <th key={`code100010`}>/5</th>
          </tr>
          {
            list.map((item, index) => {
              return <tr key={`list${index}`}>
                <td>{item.id}</td>
                <td className='yellow'><div className={item.num == 0 ? 'gray' : ''}>{item.num == 0 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 1 ? 'green-blue' : ''}>{item.num == 1 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 2 ? 'orange' : ''}>{item.num == 2 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 3 ? 'dark-green' : ''}>{item.num == 3 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 4 ? 'dark-blue' : ''}>{item.num == 4 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 5 ? 'dark-red' : ''}>{item.num == 5 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 6 ? 'green-blue' : ''}>{item.num == 6 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 7 ? 'light-blue' : ''}>{item.num == 7 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 8 ? 'orange' : ''}>{item.num == 8 ? item.num : ''}</div></td>
                <td className='yellow'><div className={item.num == 9 ? 'dark-green' : ''}>{item.num == 9 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 10 ? 'red' : ''}>{item.num == 10 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 11 ? 'green-blue' : ''}>{item.num == 11 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 12 ? 'gray' : ''}>{item.num == 12 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 13 ? 'orange' : ''}>{item.num == 13 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 14 ? 'dark-green' : ''}>{item.num == 14 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 15 ? 'red' : ''}>{item.num == 15 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 16 ? 'green-blue' : ''}>{item.num == 16 ? item.num : ''}</div></td>
                <td className='green'><div className={item.num == 17 ? 'gray' : ''}>{item.num == 17 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 18 ? 'orange' : ''}>{item.num == 18 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 19 ? 'dark-green' : ''}>{item.num == 19 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 20 ? 'red' : ''}>{item.num == 20 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 21 ? 'dark-blue' : ''}>{item.num == 21 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 22 ? 'gray' : ''}>{item.num == 22 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 23 ? 'pink' : ''}>{item.num == 23 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 24 ? 'dark-green' : ''}>{item.num == 24 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 25 ? 'green-blue' : ''}>{item.num == 25 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 26 ? 'orange' : ''}>{item.num == 26 ? item.num : ''}</div></td>
                <td className='blue'><div className={item.num == 27 ? 'gray' : ''}>{item.num == 27 ? item.num : ''}</div></td>
                {
                  item.mark.map((itemMark, index) => {
                    let colorClass = ''
                    if (index == 0 && !!itemMark) {
                      colorClass = 'dark-pink'
                    }
                    if (index == 1 && !!itemMark) {
                      colorClass = 'pink'
                    }
                    if (index == 2 && !!itemMark) {
                      colorClass = 'deep-red'
                    }
                    if (index == 3 && !!itemMark) {
                      colorClass = 'red'
                    }
                    if (index == 4 && !!itemMark) {
                      colorClass = 'light-yellow'
                    }
                    if (index == 5 && !!itemMark) {
                      colorClass = 'dark-blue'
                    }
                    if (index == 6 && !!itemMark) {
                      colorClass = 'light-blue'
                    }
                    if (index == 7 && !!itemMark) {
                      colorClass = 'dark-yellow'
                    }
                    if (index == 8 && !!itemMark) {
                      colorClass = 'light-yellow'
                    }
                    if (index == 9 && !!itemMark) {
                      colorClass = 'deep-red'
                    }
                    if (index == 10 && !!itemMark) {
                      colorClass = 'purple'
                    }
                    return <td key={`itemMark${index}`}><div className={itemMark == -1 ? '' : colorClass}>{itemMark == -1 ? '' : itemMark}</div></td>
                  })
                }
              </tr>
            })
          }
        </table>
      </div>
    </div>
  )
})

export default LuckGameTrend