import { Row, Col, message, Modal, Table, Avatar, Pagination } from 'antd'
import moment from 'moment'
import './index.scss'
import { useSearchParams, Link } from 'react-router-dom'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { luckLogs, luckInfo } from '@/request/api/index'
import { getGameName, shortNumber } from '@/utils'

const LuckGameBetList = React.forwardRef((props, ref) => {
  const [search] = useSearchParams()


  useEffect(() => {
    // 获取数据
    luckLogsFun({
      page: 1
    })
  }, [])



  // 组件已挂载，可以安全使用useImperativeHandle
  useImperativeHandle(ref, () => ({
    // 自定义的ref行为
    childMethod: () => {
      // 子组件的方法
      luckLogsFun({
        page: page
      })
    }
  }))


  // 数据列表
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(0)
  const luckLogsFun = async (params) => {
    const res = await luckLogs({
      type: search.get('type'),
      page: params.page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setList(res.data.list)
      setPage(params.page)
      setTotal(res.data.total)
      setLimit(res.data.limit)
    }
  }

  //查看详情
  const [isShowDetail, setIsShowDetail] = useState(false)
  const [curObj, setCurObj] = useState({ list: [] })
  const showDetail = (id) => {
    luckInfoFun(id)
    setIsShowDetail(true)
  }
  const hideDetail = () => {
    setCurObj({ list: [] })
    setIsShowDetail(false)
  }
  const luckInfoFun = async (id) => {
    const res = await luckInfo({
      type: search.get('type'),
      id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      res.data.cid = id
      setCurObj(res.data)
    }
  }

  return (
    <>
      <Table className='luck-table'
        columns={[
          {
            title: '期号',
            dataIndex: 'cid',
            key: 'cid',
          },
          {
            title: '投注时间',
            dataIndex: 'cdate',
            key: 'cdate'
          },
          {
            title: '投注方式',
            dataIndex: 'type',
            key: 'type',
            render: (text) => {
              return text == 1 ? '手动' : '自动'
            }
          },
          {
            title: '开奖号码',
            dataIndex: 'source',
            key: 'source',
            render: (text, item) => {
              if (item.status != 1) {
                return '— —'
              } else {
                return <>
                  <span className={`table-num ${search.get('type')}`}>{item.num1}</span>
                  <span className='table-minus'>+</span>
                  <span className={`table-num ${search.get('type')}`}>{item.num2}</span>
                  <span className='table-minus'>+</span>
                  <span className={`table-num ${search.get('type')}`}>{item.num3}</span>
                  <span className='table-minus'>=</span>
                  <span className={`table-result ${search.get('type')}`}>{item.num}</span>
                </>
              }
            }
          },
          {
            title: '消耗金豆',
            dataIndex: 'bet',
            key: 'bet',
            render: (text) => {
              return shortNumber(text)
            }
          },
          {
            title: '获得金豆',
            dataIndex: 'prize',
            key: 'prize',
            render: (text) => {
              return shortNumber(text)
            }
          },
          {
            title: '盈亏',
            dataIndex: 'win_lose',
            key: 'win_lose',
            render: (text, item) => {
              return text < 0 ? <span className='green'>{shortNumber(text)}</span> : <span className={`red ${search.get('type')}`}>+{shortNumber(text)}</span>
            }
          },
          {
            title: '操作',
            dataIndex: 'status',
            key: 'status',
            render: (text, item) => {
              return <span className='blue-btn' onClick={() => { showDetail(item.cid) }}>查看详情</span>
            }
          }
        ]}
        dataSource={list}
        pagination={false}>
      </Table>
      {list.length != 0 && <Pagination className={`luck-page ${search.get('type')}`} current={page} pageSize={limit} total={total} onChange={(page) => { luckLogsFun({ page }) }} pageSizeOptions={[]} />}

      {/* 查看详情 */}
      <Modal className='luckGame-modal'
        title=""
        open={isShowDetail}
        footer={null}
        onCancel={hideDetail}
        width={960}
        centered
        destroyOnClose={true}>
        <div className='modal-title'>【{getGameName()}】</div>
        <div className='bet-mode-title'><span>【{getGameName()}】 第 <span className={`orange ${search.get('type')}`}>{curObj.cid}</span> 期 订单详情</span></div>
        <Row justify='center' className='num-detail'>
          {
            curObj.list.map((item, index) => {
              if (item.length > 2) {
                return <Col key={`detail${index}`} id={`detail${index}`} className={`orange ${search.get('type')}`}>
                  <div>{item[0]}</div>
                  <div>{item[1]}</div>
                  <div>{item[2]}</div>
                  <div>{item[3]}</div>
                  <div>{item[4]}</div>
                </Col>
              } else {
                return <Col key={`detail${index}`} id={`detail${index}`}>
                  <div>{item[0]}</div>
                  <div>{item[1]}</div>
                </Col>
              }
            })
          }
        </Row>
        <div className={`bottom-tip ${search.get('type')}`}>共 <span>{curObj.number}</span> 个数字，投注 <span>{shortNumber(curObj.bet)}</span> 金豆，盈亏 <span className={curObj.win_lose > 0 ? 'red' : 'green'}>{curObj.win_lose > 0 ? '+' : ''}{shortNumber(curObj.win_lose)}</span> 金豆</div>
      </Modal>
    </>
  )
})

export default LuckGameBetList