import { Row, Col, message, Carousel, Table, Avatar } from 'antd'
import './index.scss'
import { rank } from '@/request/api'
import { useEffect, useState } from 'react'
import bannerImg from '@/assets/rank/banner.png'
import coinIcon from '@/assets/coin-icon.png'


function Rank () {
  useEffect(() => {
    // 获取数据
    rankFun()
  }, [])

  // 首页数据
  let [todayList, setTodayList] = useState([])
  let [yesterdayList, setYesterdayList] = useState([])
  let [ruleList, setRule] = useState([])
  const rankFun = async () => {
    const res = await rank()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      let { today, yesterday, rule } = res.data
      setTodayList(today.list)
      setYesterdayList(yesterday.list)
      setRule(rule)
    }
  }

  const columns = [
    {
      title: '排名',
      dataIndex: 'rank',
      key: 'rank',
      render: (num) => {
        if (num == 1) {
          return <div className='rank-1'></div>
        } else if (num == 2) {
          return <div className='rank-2'></div>
        } else if (num == 3) {
          return <div className='rank-3'></div>
        } else {
          return <div className='rank-num'>{num}</div>
        }
      }
    },
    {
      title: '用户',
      dataIndex: 'uname',
      key: 'uname',
      render: (uname, item) => {
        return (
          <Row align='middle'>
            <Col className='avatar-box'>
              <Avatar src={item.head} size={32}></Avatar>
            </Col>
            <Col className='uname'>{uname}</Col>
          </Row>
        )
      }
    },
    {
      title: '赢豆',
      dataIndex: 'win',
      key: 'win',
      render: (win) => {
        return (
          <Row align='middle' className='gold'>
            <Col>{win}</Col>
            <Col>
              <img src={coinIcon} className='coin-icon' />
            </Col>
          </Row>
        )
      }
    },
    {
      title: '奖励',
      dataIndex: 'prize',
      key: 'prize',
      render: (prize) => {
        return (
          <Row align='middle' className='gold'>
            <Col>{prize}</Col>
            <Col>
              <img src={coinIcon} className='coin-icon' />
            </Col>
          </Row>
        )
      }
    }
  ]



  return (
    <div className='rank-container'>
      <div className="banner-contaner">
        <Carousel autoplay>
          <div className='banner-img'><img src={bannerImg} /></div>
        </Carousel>
      </div>
      <Row className='rank-box' justify='space-between'>
        <Col>
          <h6 className='rank-title'>今日风云榜</h6>
          <div className='rank-list-box'>
            <Table dataSource={todayList}
              columns={columns}
              pagination={{ simple: true, pageSize: 15, position: ['bottomCenter'] }}>
            </Table>
          </div>
        </Col>
        <Col className='yesterday'>
          <h6 className='rank-title'>昨日风云榜</h6>
          <div className='rank-list-box'>
            <Table dataSource={yesterdayList}
              columns={columns}
              pagination={{ simple: true, pageSize: 15, position: ['bottomCenter'] }}>
            </Table>
          </div>
        </Col>
      </Row>
      <h6 className='rule-title'>活动说明</h6>
      <ul className='rule-list'>
        {
          ruleList.map((item, index) => {
            return <li key={`ruleList${index}`}>{index + 1}、{item}</li>
          })
        }
      </ul>
    </div>
  )
}

export default Rank