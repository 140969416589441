import { Avatar, Row, Col, Pagination, Select, Table, Modal, InputNumber, Input, Form, Popover, Button, message, Radio } from 'antd'
import { observer } from 'mobx-react-lite'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useStore } from '@/store'
import { Link, useSearchParams } from 'react-router-dom'
import clipboard from 'clipboard'
import coinIcon from '@/assets/coin-icon.png'
import './index.scss'
import { logGold, setUname, cardLogs, cardDeal, levelRule, signLog, signDeal, luckLogs, resetPwd, resetPayPwd, playToBank, bankToPlay, bankPlayLog, luckInfo, defaultHead, setHead } from '@/request/api'
import { useEffect, useState } from 'react'
import { scrollToTop, shortNumber, toPath } from '@/utils/index'


function UCenter () {
  const { common28Store } = useStore()
  const [search] = useSearchParams()

  useEffect(() => {
    getLevelRule({
      page: 1
    })

    if (search.get('nav') == 'myCards') {
      changeNav('nav3')
    }

    const copy = new clipboard('.copy-text')
    copy.on('success', e => {
      if (e.text == '复制已选') {
        message.warning('请先选择要复制的卡密')
        return
      }
      if (e.text == '一键复制') {
        message.warning('本页没有未使用的卡密')
        return
      }
      message.success('复制成功')
    })
    copy.on('error', e => {
      message.error('复制失败')
    })
    return () => {
      copy.destroy()
    }
  }, [])

  let [nav, setNav] = useState('nav1')
  const changeNav = (clickStr) => {
    setNav(clickStr)
    if (clickStr == 'nav2') {
      setEditName(common28Store.uinfo.uname)
    } else if (clickStr == 'nav3') {
      getCards({
        code,
        status,
        id_user,
        page: cardPage
      })
    } else if (clickStr == 'nav4') {
      getGold({
        page: 1,
        action: -1
      })
    } else if (clickStr == 'nav5') {
      luckLogsFun({
        page: 1,
        type: 'js30'
      })
    } else if (clickStr == 'nav6') {
      bankPlayLogFun({
        page: 1
      })
    } else if (clickStr == 'nav7') {
      getLevelRule({
        page: 1
      })
      getSignLog({
        page: 1
      })
    }
  }

  // 我的资料
  const [editName, setEditName] = useState()
  const saveUname = async () => {
    if (!editName) {
      message.warning('请输入昵称')
      return
    }
    const res = await setUname({
      uname: editName
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('保存成功')
      common28Store.setUserInfo()
    }
  }

  // 我的卡密
  let [code, setCode] = useState(0)
  let [status, setStatus] = useState(0)
  let [id_user, setIdUser] = useState('')
  let [cardPage, setCardPage] = useState(1)
  let [cardList, setCardList] = useState([])
  let [cardTotal, setCardTotal] = useState(0)
  let [notUse, setNotUse] = useState([])
  const getCards = async (params) => {
    const res = await cardLogs({
      ...params
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      res.data.list.map(item => item.key = item.code)
      let notUseItems = res.data.list.map(item => { if (item.status == 2) { return item.code } })
      setNotUse(notUseItems.filter(item => !!item))
      res.data.list.map((item, index) => {
        item.index = index + 1
      })
      setCardList(res.data.list)
      setCardTotal(res.data.total)
    }
  }

  // 卡已选择
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys)
    }
  }

  // 卡搜索
  const cardSearch = (values) => {
    setCode(values.code)
    setStatus(values.status)
    setIdUser(values.id_user)
    setCardPage(1)
    setSelectedRowKeys([])
    getCards({
      ...values,
      page: 1
    })
  }

  // 翻页
  const cardChangePage = (type) => {
    if (type == 'prev') {
      if (cardPage == 1) {
        return
      }
      setCardPage(cardPage - 1)
      getCards({
        page: cardPage - 1,
        status,
        id_user,
        code
      })
    }
    if (type == 'next') {
      if (cardList.length < 50) {
        return
      }
      setCardPage(cardPage + 1)
      getCards({
        page: cardPage + 1,
        status,
        id_user,
        code
      })
    }
  }

  // 使用豆卡
  let [isShowUseCardModal, setIsShowUseCardModal] = useState(false)
  const [codeInput, setCodeInput] = useState('')
  const showUseCardModal = () => {
    setIsShowUseCardModal(true)
  }
  const hideUseCardModal = () => {
    setCodeInput('')
    setIsShowUseCardModal(false)
  }
  const cardFun = async (curAction) => {
    if (!codeInput) {
      message.warning('请输入卡密')
      return
    }
    const res = await cardDeal({
      code: codeInput.split('\n').join('|'),
      action: curAction
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setCodeInput(res.data.list.join('\n'))
      common28Store.setUserInfo()
      setIsShowUseCardModal(true)
    }
  }

  // 获取金豆
  const [goldList, setGoldList] = useState([])
  const [uinfo, setUinfo] = useState({ sign: { is_sign: '' } })
  const [gold_page, setGoldPage] = useState(1)
  const [goldActionList, setGoldActionList] = useState([])
  const [goldAction, setGoldAction] = useState(-1)
  const getGold = async (params) => {
    const res = await logGold({
      ...params
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setGoldList(res.data.list)
      if (goldActionList.length == 0) {
        let action = []
        res.data.action.map((item, index) => {
          action.push({
            value: item.id,
            label: item.name
          })
        })
        setGoldActionList(JSON.parse(JSON.stringify(action)))
      }
    }
  }

  // 金豆翻页
  const changeGoldPage = (type) => {
    if (type == 'prev') {
      if (gold_page == 1) {
        return
      }
      setGoldPage(gold_page - 1)
      getGold({
        page: gold_page - 1,
        action: goldAction
      })
    }
    if (type == 'next') {
      if (goldList.length < 15) {
        return
      }
      setGoldPage(gold_page + 1)
      getGold({
        page: gold_page + 1,
        action: goldAction
      })
    }
    scrollToTop()
  }
  // 金豆列表筛选
  const goldChangeSelect = (e) => {
    setGoldAction(e)
    setGoldPage(1)
    getGold({
      action: e,
      page: 1
    })
  }

  //28投注记录
  const [luckList, setLuckList] = useState([])
  const [luckLimit, setLuckLimit] = useState(10)
  const [luckTotal, setLuckTotal] = useState(10)
  const [luckGame, setLuckGame] = useState('28')
  const [luckType, setLuckType] = useState('js30')
  const [luckPage, setLuckPage] = useState(1)
  const luckLogsFun = async (params) => {
    const res = await luckLogs({
      ...params
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setLuckList(res.data.list)
      setLuckLimit(res.data.limit)
      setLuckTotal(res.data.total)
    }
  }
  // 投注记录翻页
  const changeLuckPage = (page) => {
    setLuckPage(page)
    luckLogsFun({
      page,
      type: luckType
    })
    scrollToTop()
  }
  // 投注列表筛选
  const luckFiltFun = (params) => {
    const { game, type } = params
    setLuckGame(game)
    setLuckType(type)
    setLuckPage(1)
    luckLogsFun({
      page: 1,
      type
    })
  }

  //签到等级信息
  const [levelList, setLevelList] = useState([])
  const [levelRuleList, setLevelRuleList] = useState([])
  const [myInfo, setMyInfo] = useState({})
  const getLevelRule = async () => {
    const res = await levelRule()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setLevelList(res.data.list)
      setLevelRuleList(res.data.rule)
      setMyInfo(res.data.my)
    }
  }

  // 签到记录
  const [isSign, setIsSign] = useState(true)
  const [signList, setSignList] = useState([])
  const [signPage, setSignPage] = useState(1)
  const getSignLog = async (params) => {
    const res = await signLog({
      ...params
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      const { issign, list } = res.data
      setIsSign(issign == 1 ? true : false)
      setSignList(list)
    }
  }
  // 签到翻页
  const changeSignPage = (type) => {
    if (type == 'prev') {
      if (signPage == 1) {
        return
      }
      setSignPage(signPage - 1)
      getSignLog({
        page: signPage - 1
      })
    }
    if (type == 'next') {
      if (goldList.length < 40) {
        return
      }
      setSignPage(signPage + 1)
      getSignLog({
        page: signPage + 1
      })
    }
  }
  // 执行签到
  const signDealFun = async () => {
    const res = await signDeal()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      common28Store.setUserInfo()
      setSignPage(1)
      getSignLog({
        page: 1
      })
      message.success('签到成功')
    }
  }

  // 修改登录密码
  const resetPwdFun = async (params) => {
    const { old_pwd, pwd, repwd } = params
    if (!old_pwd) {
      message.warning('请输入原密码')
      return
    }
    if (!pwd) {
      message.warning('请输入新密码')
      return
    }
    if (!repwd) {
      message.warning('请输入确认密码')
      return
    }
    if (pwd != repwd) {
      message.warning('新密码与确认密码不一致')
      return
    }
    const res = await resetPwd({
      ...params
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('登录密码修改成功')
    }
  }

  // 修改支付密码
  const resetPayPwdFun = async (params) => {
    const { old_pwd, pwd, repwd } = params
    if (!old_pwd) {
      message.warning('请输入原密码')
      return
    }
    if (!pwd) {
      message.warning('请输入新密码')
      return
    }
    if (!repwd) {
      message.warning('请输入确认密码')
      return
    }
    if (pwd != repwd) {
      message.warning('新密码与确认密码不一致')
      return
    }
    const res = await resetPayPwd({
      ...params
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('登录密码修改成功')
    }
  }

  // 保险柜-游戏钱包
  const [goldNumType, setGoldNumType] = useState('')
  const [goldNum, setGoldNum] = useState('')
  const [goldPwd, setGoldPwd] = useState('')
  const changeGoldType = (type) => {
    setGoldNumType(type)
    setGoldNum(Math.floor(common28Store.uinfo.gold / type))
  }
  const playToBankFun = async () => {
    if (!goldNum) {
      message.warning('请输入数量')
      return
    }
    if (!goldPwd) {
      message.warning('请输入安全密码')
      return
    }
    const res = await playToBank({
      gold: goldNum,
      pay_pwd: goldPwd
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('操作成功')
      common28Store.setUserInfo()
    }
  }

  // 保险柜-银行钱包
  const [bankNumType, setBankNumType] = useState('')
  const [bankNum, setBankNum] = useState('')
  const [bankPwd, setBankPwd] = useState('')
  const changeBankType = (type) => {
    setBankNumType(type)
    setBankNum(Math.floor(common28Store.uinfo.bank / type))
  }
  const bankToPlayFun = async () => {
    if (!bankNum) {
      message.warning('请输入数量')
      return
    }
    if (!bankPwd) {
      message.warning('请输入安全密码')
      return
    }
    const res = await bankToPlay({
      gold: bankNum,
      pay_pwd: bankPwd
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('操作成功')
      common28Store.setUserInfo()
    }
  }

  // 划转记录
  const [bankPlayList, setBankPlayList] = useState([])
  const bankPlayLogFun = async (params) => {
    const res = await bankPlayLog({
      page: params.page
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setBankPlayList(res.data.list)
    }
  }

  // 翻页
  const [bankPage, setBankPage] = useState(1)
  const bankChangePage = (type) => {
    if (type == 'prev') {
      if (bankPage == 1) {
        return
      }
      setBankPage(bankPage - 1)
      bankPlayLogFun({
        page: bankPage - 1
      })
    }
    if (type == 'next') {
      if (bankPlayList.length < 50) {
        return
      }
      setBankPage(bankPage + 1)
      bankPlayLogFun({
        page: bankPage + 1
      })
    }
  }

  // 查看投注详情
  const [isShowDetail, setIsShowDetail] = useState(false)
  const [curObj, setCurObj] = useState({ list: [] })
  const showDetail = (id) => {
    luckInfoFun(id)
    setIsShowDetail(true)
  }
  const hideDetail = () => {
    setCurObj({ list: [] })
    setIsShowDetail(false)
  }
  const luckInfoFun = async (id) => {
    const res = await luckInfo({
      type: luckType,
      id
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      res.data.cid = id
      setCurObj(res.data)
    }
  }

  const getName = () => {
    let type = luckType
    if (type == 'js30') {
      return '极速30秒'
    }
    if (type == 'xy60') {
      return '比特60秒'
    }
    if (type == 'kx90') {
      return '开心90秒'
    }
    if (type == 'sk120') {
      return '斯洛伐克120秒'
    }
    if (type == 'jnd210') {
      return '加拿大210秒'
    }
    if (type == 'tw300') {
      return '台湾蛋蛋300秒'
    }
  }

  // 获取头像
  const [curHead, setCurHead] = useState(common28Store.uinfo.head)
  const [defaultHeads, setDefaultHeads] = useState([])
  const defaultHeadFun = async () => {
    const res = await defaultHead()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      setDefaultHeads(res.data.list)
      setIsShowHeads(true)
    }
  }
  const [isShowHeads, setIsShowHeads] = useState(false)
  const showHeads = () => {
    setCurHead(common28Store.uinfo.head)
    if (defaultHeads.length == 0) {
      defaultHeadFun()
    }
    setIsShowHeads(true)
  }
  const hideHeads = () => {
    setIsShowHeads(false)
  }

  const changeHead = (clickHead) => {
    setCurHead(clickHead)
  }

  // 设置头像
  const setHeadFun = async () => {
    const res = await setHead({
      head: curHead
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      message.success('操作成功')
      common28Store.setUserInfo()
      hideHeads()
    }
  }

  // 我等级
  const [isShowMyLevel, setIsShowLevel] = useState(false)
  const showMyLevel = () => {
    setIsShowLevel(true)
  }
  const hideMyLevel = () => {
    setIsShowLevel(false)
  }

  return (
    <div className='ucenter-container'>
      <Row className='ucenter-main' justify='space-between'>
        <Col className='left-nav'>
          <h6 className='nav-title'>会员中心</h6>
          <ul className='nav-menu'>
            <li className={nav == 'nav1' ? 'current' : ''} onClick={() => { changeNav('nav1') }}>我的信息</li>
            <li className={nav == 'nav2' ? 'current' : ''} onClick={() => { changeNav('nav2') }}>我的资料</li>
            <li className={nav == 'nav3' ? 'current' : ''} onClick={() => { changeNav('nav3') }}>我的卡密</li>
            <li className={nav == 'nav4' ? 'current' : ''} onClick={() => { changeNav('nav4') }}>金豆信息</li>
            <li className={nav == 'nav5' ? 'current' : ''} onClick={() => { changeNav('nav5') }}>投注记录</li>
            <li className={nav == 'nav6' ? 'current' : ''} onClick={() => { changeNav('nav6') }}>保险柜</li>
            <li className={nav == 'nav7' ? 'current' : ''} onClick={() => { changeNav('nav7') }}>每日签到</li>
            <li className={nav == 'nav8' ? 'current' : ''} onClick={() => { changeNav('nav8') }}>修改密码</li>
          </ul>
        </Col>
        {
          nav == 'nav1' &&
          <Col className='right-main'>
            <h6 className='right-title'>我的信息</h6>
            <p className='sub-title'>
              <span>我的信息</span>
            </p>
            <Row className='info-box'>
              <Col className='avatar-box'>
                <div className='avatar-img'>
                  <Avatar shape="square" size={86} src={common28Store.uinfo.head} />
                  <img src={require('@/assets/ucenter/avatar.png')} className='edit-icon' onClick={showHeads} />
                </div>
              </Col>
              <Col className='middle-box'>
                <div>
                  <span>我的ID： </span>
                  <span className='balck'>{common28Store.uinfo.id}</span>
                </div>
                <div>
                  <span>我的昵称： </span>
                  <span className='balck'>{common28Store.uinfo.uname}</span>
                </div>
                <div>
                  <span>我的等级： </span>
                  <span className='balck'>LV.{common28Store.uinfo.level}</span>
                  <span onClick={showMyLevel} className='help-icon'>
                    <span>等级规则</span>
                    <QuestionCircleOutlined />
                  </span>
                </div>
              </Col>
              <Col className='right-box'>
                <div>
                  <span>账号类型：</span>
                  <span className='balck'>{common28Store.uinfo.type}</span>
                </div>
                <div>
                  <span>注册手机： </span>
                  <span className='balck'>{common28Store.uinfo.phone}</span>
                </div>
                <div>
                  <span>上次登录时间：</span>
                  <span className='balck'>{common28Store.uinfo.login_time}</span>
                </div>
              </Col>
            </Row>
            <p className='sub-title'>
              <span>我的数据</span>
            </p>
            <Row className='data-box'>
              <Col>
                <p className='data-title'>账户金豆</p>
                <p className='data-num'>{shortNumber(common28Store.uinfo.gold)}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></p>
                <div className='data-bottom'>
                  <Link to='/activity' align='middle'>充值</Link>
                  <span className='split'>|</span>
                  <span onClick={() => { toPath('/changeDetail?id=1') }} align='middle'>兑换豆卡</span>
                  <span className='split'>|</span>
                  <span onClick={showUseCardModal} align='middle'>使用豆卡</span>
                </div>
              </Col>
              <Col>
                <p className='data-title'>银行金豆</p>
                <p className='data-num'>{shortNumber(common28Store.uinfo.bank)}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></p>
                <div className='data-bottom' align='middle' onClick={() => changeNav('nav6')}>存取</div>
              </Col>
              <Col>
                <p className='data-title'>昨日返利</p>
                <p className='data-num'>{shortNumber(common28Store.uinfo.yestoday_rebate)}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></p>
                <div className='data-bottom' align='middle'>详情</div>
              </Col>
              <Col>
                <p className='data-title'>今日流水</p>
                <p className='data-num'>{shortNumber(common28Store.uinfo.flow28)}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></p>
                <div className='data-bottom' align='middle'>详情</div>
              </Col>
              <Col>
                <p className='data-title'>今日盈亏</p>
                <p className='data-num'>{shortNumber(common28Store.uinfo.win_lose)}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></p>
                <div className='data-bottom' align='middle'>详情</div>
              </Col>
              <Col>
                <p className='data-title'>昨日盈亏</p>
                <p className='data-num'>{shortNumber(common28Store.uinfo.yestoday_win_lose)}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></p>
                <div className='data-bottom' align='middle'>详情</div>
              </Col>
            </Row>

            {/* 头像编辑 */}
            <Modal className='ucenter-modal'
              title=""
              open={isShowHeads}
              footer={null}
              onCancel={hideHeads}
              width={465}
              centered
              destroyOnClose={true}>
              <h6 className='modal-title'>更换头像</h6>
              <Row className='head-box'>
                {
                  defaultHeads.map(item => {
                    return <Col key={item} className={curHead == item ? 'current' : ''}>
                      <Avatar shape="square" size={86} src={item} onClick={() => changeHead(item)} />
                    </Col>
                  })
                }
              </Row>
              <Row className='modal-btns' justify='center'>
                <Col onClick={setHeadFun}>确定更换</Col>
                <Col className='gray' onClick={hideHeads}>取消</Col>
              </Row>
            </Modal>

            {/* 我的等级 */}
            <Modal className='ucenter-modal'
              title=""
              open={isShowMyLevel}
              footer={null}
              onCancel={hideMyLevel}
              width={900}
              centered="true"
              destroyOnClose={true}>
              <h6 className='modal-title'>等级规则</h6>
              <Row className='level-info'>
                <Col>我的等级：{myInfo.level}</Col>
                <Col>升级条件：上分{myInfo.charge}</Col>
                <Col>有效流水：{myInfo.flow}</Col>
              </Row>
              <Table className='level-table'
                columns={[
                  {
                    title: '等级',
                    dataIndex: 'level',
                    key: 'level'
                  },
                  {
                    title: '称号',
                    dataIndex: 'name',
                    key: 'name'
                  },
                  {
                    title: '上分要求',
                    dataIndex: 'charge',
                    key: 'charge'
                  },
                  {
                    title: '流水要求',
                    dataIndex: 'flow',
                    key: 'flow'
                  },
                  {
                    title: '签到奖励',
                    dataIndex: 'sign',
                    key: 'sign'
                  },
                  {
                    title: '兑换商品',
                    dataIndex: 'luck',
                    key: 'luck'
                  },
                  {
                    title: '投注返利',
                    dataIndex: 'rebate',
                    key: 'rebate'
                  },
                  {
                    title: '建群数',
                    dataIndex: 'qun',
                    key: 'qun'
                  },
                ]}
                dataSource={levelList}
                pagination={false}
              >
              </Table>
              <Row className='level-title' align='middle'>
                <Col className='title-icon'></Col>
                <Col>等级说明</Col>
              </Row>
              <ul className='level-rule'>
                {
                  levelRuleList.map((item, index) => {
                    return <li dangerouslySetInnerHTML={{ __html: item }} key={`friendRule${index}`}></li>
                  })
                }
              </ul>
            </Modal>
          </Col>
        }
        {
          nav == 'nav2' &&
          <Col className='right-main'>
            <h6 className='right-title'>我的资料</h6>
            <p className='sub-title'>
              <span>我的资料</span>
            </p>
            <div className='form-box'>
              <Row className='form-item' align='middle' justify='center'>
                <Col className='form-lable'>我的昵称：</Col>
                <Col>
                  <Input placeholder='请输入昵称' className='form-input' value={editName} onChange={(e) => setEditName(e.target.value)} />
                </Col>
              </Row>
              <div className='form-btn' onClick={saveUname}>提交保存</div>
            </div>
          </Col>
        }
        {
          nav == 'nav3' &&
          <Col className='right-main'>
            <h6 className='right-title'>我的卡密</h6>
            {/* <p className='sub-title'>
              <span>我的卡密</span>
            </p> */}
            <Row className='search-box' justify='space-between' align='center'>
              <Col>
                <Form layout="inline"
                  className='search-form'
                  onFinish={cardSearch}
                  initialValues={{ status: 0 }}>
                  <Form.Item
                    name="code"
                    label="">
                    <Input placeholder='输入卡密查询' className='search-input' />
                  </Form.Item>
                  <Form.Item
                    name="id_user"
                    label="">
                    <Input placeholder='输入用户ID查询' className='search-input' />
                  </Form.Item>
                  <Form.Item
                    name="status"
                    label="">
                    <Select
                      className='search-input'
                      options={[
                        { value: 0, label: '全部' },
                        { value: 1, label: '已使用' },
                        { value: 2, label: '未使用' },
                      ]}>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" className='search-btn1'>
                      查询
                    </Button>
                    <Button data-clipboard-text={notUse.join('\n')} className='copy-text search-btn2'>复制未使用卡密</Button>
                    {false && <Button data-clipboard-text={selectedRowKeys.join('\n') || '复制已选'} className='copy-text'>复制已选卡密</Button>}
                  </Form.Item>
                </Form>
              </Col>
              <Col className='use-btn' onClick={showUseCardModal}>使用豆卡</Col>
            </Row>
            <Table className='ucenter-table'
              columns={[
                {
                  title: '全选',
                  dataIndex: 'index',
                  key: 'index'
                },
                {
                  title: '卡密',
                  dataIndex: 'code',
                  key: 'code',
                  render: (text) => {
                    return (
                      <Row>
                        <Col>{text}</Col>
                        <Col data-clipboard-text={text} className='copy-text copy-btn'>复制</Col>
                      </Row>
                    )
                  }
                },
                {
                  title: '金豆',
                  dataIndex: 'gold',
                  key: 'gold'
                },
                {
                  title: '卡状态',
                  dataIndex: 'status_note',
                  key: 'status_note',
                  render: (text, item) => {
                    if (item.status == 1) {
                      return <span className='gray'>{text}</span>
                    } else {
                      return <span>{text}</span>
                    }
                  }
                },
                {
                  title: '使用人ID',
                  dataIndex: 'id_user',
                  key: 'id_user',
                  render: (text, item) => {
                    return <span className='red'>{text}</span>
                  }
                },
                {
                  title: '使用时间',
                  dataIndex: 'use_time',
                  key: 'use_time'
                },
                {
                  title: '获取时间',
                  dataIndex: 'in_time',
                  key: 'in_time'
                }
              ]}
              dataSource={cardList}
              rowSelection={rowSelection}
              pagination={false}
            >
            </Table>
            {
              cardList.length != 0 &&
              <Row justify='center' className='page-box' align='middle'>
                <Col>
                  <Button onClick={() => cardChangePage('prev')} disabled={cardPage == 1 ? true : false}>上一页</Button>
                </Col>
                <Col>
                  <Button onClick={() => cardChangePage('next')} disabled={cardList.length < 50 ? true : false}>下一页</Button>
                </Col>
                <Col className='cards-num'>总记录数:{cardTotal}</Col>
              </Row>
            }
          </Col>
        }
        {
          nav == 'nav4' &&
          <Col className='right-main'>
            <h6 className='right-title'>金豆记录</h6>
            {/* <p className='sub-title'>
              <span>金豆记录</span>
            </p> */}
            <Row className='search-box' justify='space-between' align='center'>
              <Col>
                <Form layout="inline"
                  className='search-form'
                  onFinish={() => { }}
                  initialValues={{ action: -1 }}>
                  <Form.Item
                    name="action"
                    label="">
                    <Select
                      className='search-input'
                      options={goldActionList}
                      onChange={goldChangeSelect}>
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Table className='ucenter-table'
              columns={[
                {
                  title: '类型',
                  dataIndex: 'action',
                  key: 'action'
                },
                {
                  title: '金豆数量',
                  dataIndex: 'change',
                  key: 'change',
                  render: (text) => {
                    return <>
                      <span>{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '金豆余额',
                  dataIndex: 'balance',
                  key: 'balance',
                  render: (text) => {
                    return <>
                      <span>{text}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '描述',
                  dataIndex: 'title',
                  key: 'title'
                },
                {
                  title: '时间',
                  dataIndex: 'cdate',
                  key: 'cdate',
                }
              ]}
              dataSource={goldList}
              pagination={false}
            >
            </Table>
            {
              goldList.length != 0 &&
              <Row className='page-box' justify='center'>
                <Col>
                  <Button onClick={() => changeGoldPage('prev')} disabled={gold_page == 1 ? true : false}>上一页</Button>
                </Col>
                <Col>
                  <Button onClick={() => changeGoldPage('next')} disabled={goldList.length < 15 ? true : false}>下一页</Button>
                </Col>
              </Row>
            }
          </Col>
        }
        {
          nav == 'nav5' &&
          <Col className='right-main'>
            <h6 className='right-title'>投注记录</h6>
            {/* <p className='sub-title'>
              <span>投注记录</span>
            </p> */}
            <Row className='search-box' justify='space-between' align='center'>
              <Col>
                <Form layout="inline"
                  className='search-form'
                  onFinish={luckFiltFun}
                  initialValues={{ game: '28', type: 'js30' }}>
                  <Form.Item
                    name="game"
                    label="">
                    <Select
                      className='search-input'
                      options={[
                        {
                          value: '28',
                          label: '经典玩法 '
                        }
                      ]}>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="type"
                    label="">
                    <Select
                      className='search-input'
                      options={[
                        {
                          value: 'js30',
                          label: '极速30秒'
                        },
                        {
                          value: 'xy60',
                          label: '比特60秒 '
                        },
                        {
                          value: 'kx90',
                          label: '开心90秒 '
                        },
                        {
                          value: 'sk120',
                          label: '斯洛伐克120秒 '
                        },
                        {
                          value: 'jnd210',
                          label: '加拿大210秒'
                        },
                        {
                          value: 'tw300',
                          label: '台湾蛋蛋300秒'
                        }
                      ]}>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" className='search-btn1'>
                      查询
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Table className='ucenter-table'
              columns={[
                {
                  title: '开奖时间',
                  dataIndex: 'cdate',
                  key: 'cdate'
                },
                {
                  title: '期号',
                  dataIndex: 'cid',
                  key: 'cid'
                },
                {
                  title: '模式',
                  dataIndex: 'type',
                  key: 'type',
                  render: (text) => {
                    return text == 1 ? '手动' : '自动'
                  }
                },
                {
                  title: '投注',
                  dataIndex: 'bet',
                  key: 'bet',
                },
                {
                  title: '中奖',
                  dataIndex: 'prize',
                  key: 'prize',
                  render: (text) => {
                    return <>
                      <span className={text > 0 ? 'orange' : ''}>{shortNumber(text)}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '赢亏',
                  dataIndex: 'win_lose',
                  key: 'win_lose',
                  render: (text) => {
                    return <>
                      <span className={text > 0 ? 'orange' : 'green'}>{text > 0 ? '+' : ''}{shortNumber(text)}</span>
                      <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                    </>
                  }
                },
                {
                  title: '详情',
                  dataIndex: 'cid',
                  key: 'cid',
                  render: (text) => {
                    return <div className='blue-btn' onClick={() => showDetail(text)}>查看</div>
                  }
                }
              ]}
              dataSource={luckList}
              pagination={false}
            >
            </Table>
            {
              luckList.length != 0 &&
              <Pagination current={luckPage} total={luckTotal} pageSize={luckLimit} className='luck-page' onChange={(page) => changeLuckPage(page)}></Pagination>
            }
            {/* 查看详情 */}
            <Modal className='luckGame-modal'
              title=""
              open={isShowDetail}
              footer={null}
              onCancel={hideDetail}
              width={960}
              centered
              destroyOnClose={true}>

              <div className='modal-title'>【{getName()}】</div>
              <div className='bet-mode-title'><span>【{getName()}】 第 <span className={`orange ${luckType}`}>{curObj.cid}</span> 期 订单详情</span></div>
              <Row justify='center' className='num-detail'>
                {
                  curObj.list.map((item, index) => {
                    if (item.length > 2) {
                      return <Col key={`detail${index}`} id={`detail${index}`} className={`orange ${luckType}`}>
                        <div>{item[0]}</div>
                        <div>{item[1]}</div>
                        <div>{item[2]}</div>
                        <div>{item[3]}</div>
                        <div>{item[4]}</div>
                      </Col>
                    } else {
                      return <Col key={`detail${index}`} id={`detail${index}`}>
                        <div>{item[0]}</div>
                        <div>{item[1]}</div>
                      </Col>
                    }
                  })
                }
              </Row>
              <div className={`bottom-tip ${luckType}`}>共 <span>{curObj.number}</span> 个数字，投注 <span>{shortNumber(curObj.bet)}</span> 金豆，盈亏 <span className={curObj.win_lose > 0 ? 'red' : 'green'}>{curObj.win_lose > 0 ? '+' : ''}{shortNumber(curObj.win_lose)}</span> 金豆</div>
            </Modal>
          </Col>
        }
        {
          nav == 'nav6' &&
          <Col className='right-main'>
            <h6 className='right-title'>保险柜</h6>
            <p className='sub-title'>
              <span>保险柜</span>
            </p>
            <Row justify='space-between' className='bank-box'>
              <Col>
                <p className='bank-title'>游戏钱包</p>
                <p className='bank-coin'>
                  <span>{common28Store.uinfo.gold}</span>
                  <img src={coinIcon} className='coin-icon' />
                </p>
                <p className='bank-sub'>游戏钱包金豆余额</p>
                <Row justify='space-between' className='num-type'>
                  <Col className={goldNumType == '1' ? 'current' : ''} onClick={() => changeGoldType(1)}>全部</Col>
                  <Col className={goldNumType == '2' ? 'current' : ''} onClick={() => changeGoldType(2)}>1/2</Col>
                  <Col className={goldNumType == '3' ? 'current' : ''} onClick={() => changeGoldType(3)}>1/3</Col>
                  <Col className={goldNumType == '4' ? 'current' : ''} onClick={() => changeGoldType(4)}>1/4</Col>
                  <Col className={goldNumType == '10' ? 'current' : ''} onClick={() => changeGoldType(10)}>1/10</Col>
                </Row>
                <Row className='bank-form' align='middle'>
                  <Col className='bank-label'>输入数量：</Col>
                  <Col>
                    <InputNumber placeholder='请输入数量' className='bank-input' value={goldNum} onChange={(e) => {
                      setGoldNumType(''); setGoldNum(e)
                    }}></InputNumber>
                  </Col>
                </Row>
                <Row className='bank-form pwd' align='middle'>
                  <Col className='bank-label'>安全密码：</Col>
                  <Col>
                    <Input.Password placeholder='请输入安全密码' className='bank-input' onChange={(e) => setGoldPwd(e.target.value)} value={goldPwd} />
                  </Col>
                </Row>
                <div className='bank-btn' onClick={playToBankFun}>划转至银行钱包</div>
              </Col>
              <Col>
                <p className='bank-title'>银行钱包</p>
                <p className='bank-coin'>
                  <span>{common28Store.uinfo.bank}</span>
                  <img src={coinIcon} className='coin-icon' />
                </p>
                <p className='bank-sub'>银行钱包金豆余额</p>
                <Row justify='space-between' className='num-type'>
                  <Col className={bankNumType == '1' ? 'current' : ''} onClick={() => changeBankType(1)}>全部</Col>
                  <Col className={bankNumType == '2' ? 'current' : ''} onClick={() => changeBankType(2)}>1/2</Col>
                  <Col className={bankNumType == '3' ? 'current' : ''} onClick={() => changeBankType(3)}>1/3</Col>
                  <Col className={bankNumType == '4' ? 'current' : ''} onClick={() => changeBankType(4)}>1/4</Col>
                  <Col className={bankNumType == '10' ? 'current' : ''} onClick={() => changeBankType(10)}>1/10</Col>
                </Row>
                <Row className='bank-form' align='middle'>
                  <Col className='bank-label'>输入数量：</Col>
                  <Col>
                    <InputNumber placeholder='请输入数量' className='bank-input' value={bankNum} onChange={(e) => {
                      setBankNumType(''); setBankNum(e)
                    }}></InputNumber>
                  </Col>
                </Row>
                <Row className='bank-form pwd' align='middle'>
                  <Col className='bank-label'>安全密码：</Col>
                  <Col>
                    <Input.Password placeholder='请输入安全密码' className='bank-input' onChange={(e) => setBankPwd(e.target.value)} value={bankPwd} />
                  </Col>
                </Row>
                <div className='bank-btn' onClick={bankToPlayFun}>划转至银行钱包</div>
              </Col>
            </Row>
            <p className='sub-title'>
              <span>划转记录</span>
            </p>
            <Table className='ucenter-table'
              columns={[
                {
                  title: '划转时间',
                  dataIndex: 'cdate',
                  key: 'cdate'
                },
                {
                  title: '划转方向',
                  dataIndex: 'type',
                  key: 'type',
                  render: (text) => {
                    return (
                      text == 1 ? '游戏->银行' : '银行->游戏'
                    )
                  }
                },
                {
                  title: '划转数量',
                  dataIndex: 'change',
                  key: 'change',
                  render: (text) => {
                    return (
                      <>
                        <span>{shortNumber(text)}</span>
                        <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                      </>
                    )
                  }
                },
                {
                  title: '银行钱包余额',
                  dataIndex: 'bank',
                  key: 'bank',
                  render: (text) => {
                    return (
                      <>
                        <span>{shortNumber(text)}</span>
                        <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                      </>
                    )
                  }
                },
                {
                  title: '游戏钱包余额',
                  dataIndex: 'gold',
                  key: 'gold',
                  render: (text) => {
                    return (
                      <>
                        <span>{shortNumber(text)}</span>
                        <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                      </>
                    )
                  }
                }
              ]}
              dataSource={bankPlayList}
              pagination={false}
            >
            </Table>
            {
              bankPlayList.length != 0 &&
              <Row justify='center' className='page-box' align='middle'>
                <Col>
                  <Button onClick={() => bankChangePage('prev')} disabled={bankPage == 1 ? true : false}>上一页</Button>
                </Col>
                <Col>
                  <Button onClick={() => bankChangePage('next')} disabled={bankPlayList.length < 50 ? true : false}>下一页</Button>
                </Col>
              </Row>
            }
          </Col>
        }
        {
          nav == 'nav7' &&
          <Col className='right-main'>
            <h6 className='right-title'>每日签到</h6>
            <p className='sub-title'>
              <span>每日签到</span>
            </p>
            <Row className='level-box'>
              {
                levelList.map((item, index) => {
                  return (
                    <Col style={{ width: `${100 / levelList.length}%` }} key={`levelList${index}`}>
                      <div>{item.level}</div>
                      <div>{item.sign}<img src={require('@/assets/coin-icon.png')} className='coin-icon' /></div>
                    </Col>
                  )
                })
              }
            </Row>
            <Row className='sign-btns' justify='center'>
              {
                isSign && <Col className='gray'>今日已签到</Col>
              }
              {
                !isSign && <Col onClick={signDealFun}>立即签到</Col>
              }
            </Row>
            <Table className='ucenter-table'
              columns={[
                {
                  title: '时间',
                  dataIndex: 'cdate',
                  key: 'cdate'
                },
                {
                  title: '等级',
                  dataIndex: 'level',
                  key: 'level'
                },
                {
                  title: '领取金豆',
                  dataIndex: 'prize',
                  key: 'prize',
                  render: (text) => {
                    return (
                      <>
                        <span>+{shortNumber(text)}</span>
                        <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                      </>
                    )
                  }
                },
                {
                  title: '金豆余额',
                  dataIndex: 'gold',
                  key: 'gold',
                  render: (text) => {
                    return (
                      <>
                        <span>{shortNumber(text)}</span>
                        <img src={require('@/assets/coin-icon.png')} className='coin-icon' />
                      </>
                    )
                  }
                }
              ]}
              dataSource={signList}
              pagination={false}
            >
            </Table>
            {
              signList.length != 0 &&
              <Row className='page-box' justify='center'>
                <Col>
                  <Button onClick={() => changeSignPage('prev')} disabled={signPage == 1 ? true : false}>上一页</Button>
                </Col>
                <Col>
                  <Button onClick={() => changeSignPage('next')} disabled={signList.length < 50 ? true : false}>下一页</Button>
                </Col>
              </Row>
            }
          </Col>
        }
        {
          nav == 'nav8' &&
          <Col className='right-main'>
            <h6 className='right-title'>修改密码</h6>
            <p className='sub-title'>
              <span>修改密码</span>
            </p>
            <div className='form-box'>
              <Form className='pwd-form'
                onFinish={resetPwdFun}
                initialValues={{ game: '28', type: 'js30' }}>
                <Form.Item
                  name="old_pwd"
                  label="原密码：">
                  <Input.Password placeholder='请输入原密码' className='form-input' />
                </Form.Item>
                <Form.Item
                  name="pwd"
                  label="新密码：">
                  <Input.Password placeholder='请输入6-18位新密码，支持字母及数字' className='form-input' />
                </Form.Item>
                <Form.Item
                  name="repwd"
                  label="确认密码：">
                  <Input.Password placeholder='请输入6-18位新密码，支持字母及数字' className='form-input' />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className='form-btn'>
                    提交修改
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <p className='sub-title'>
              <span>安全密码</span>
            </p>
            <div className='form-box'>
              <Form className='pwd-form'
                onFinish={resetPayPwdFun}
                initialValues={{ game: '28', type: 'js30' }}>
                <Form.Item
                  name="old_pwd"
                  label="原密码：">
                  <Input.Password placeholder='请输入原密码' className='form-input' />
                </Form.Item>
                <Form.Item
                  name="pwd"
                  label="新密码：">
                  <Input.Password placeholder='请输入6-18位新密码，支持字母及数字' className='form-input' />
                </Form.Item>
                <Form.Item
                  name="repwd"
                  label="确认密码：">
                  <Input.Password placeholder='请输入6-18位新密码，支持字母及数字' className='form-input' />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className='form-btn'>
                    提交修改
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        }
      </Row>
      {/* 使用豆卡 */}
      <Modal className='ucenter-modal'
        title=""
        open={isShowUseCardModal}
        footer={null}
        onCancel={hideUseCardModal}
        width={520}
        closable={false}>
        <h6 className='modal-title'>使用豆卡</h6>
        <Input.TextArea value={codeInput} onChange={e => setCodeInput(e.target.value)} placeholder='输入卡密一行一个，一次最多200个' className='use-input' style={{ resize: 'none' }} />
        <Row className='modal-btns' justify='center'>
          <Col onClick={() => cardFun(1)}>立即使用</Col>
          <Col className='gray' onClick={hideUseCardModal}>取消</Col>
        </Row>
      </Modal >
    </div>
  )
}

export default observer(UCenter)