import { Row, Col, message } from 'antd'
import { Link } from 'react-router-dom'
import footerImg from '@/assets/footer/footer-icon.png'
import logoIcon from '@/assets/footer/logo.png'
import './index.scss'

function Footer () {
  return (
    <div className='footer-box'>
      <div className='logo'>
        <img src={logoIcon} />
      </div>
      {/* <Row justify='center' className='bottom-menu' align='middle'>
        <Col>
          <Link to='/about#part-1'>关于我们</Link>
        </Col>
        <Col>
          <Link to='/about#part-2'>隐私协议</Link>
        </Col>
        <Col>
          <Link to='/help'>常见问题</Link>
        </Col>
        <Col>
          <Link>VIP合伙人</Link>
        </Col>
      </Row> */}
      <div className='line'></div>
      <Row justify='center' align='middle' className='footer-info'>
        <Col>备案号：沪ICP备19009294号</Col>
        <Col>ICP证：沪B2-20230273</Col>
        <Col>文网文：沪网文 [2022] 3072601号</Col>
        <Col>
          <img src={footerImg} />
          <span>公网安备13068403675104号</span>
        </Col>
      </Row>
      <Row justify='center' align='middle' className='footer-info'>
        <Col>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</Col>
      </Row>
    </div>
  )
}

export default Footer