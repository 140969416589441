// 兑换商城相关接口
import service from ".."

// 获取数据
export function goods (params) {
  return service({
    method: 'GET',
    url: "/goods",
    params
  })
}

// 获取商品详情数据
export function goodsDetail (params) {
  return service({
    method: 'GET',
    url: "/goods/info",
    params
  })
}

// 兑换商品
export function exchange (params) {
  return service({
    method: 'GET',
    url: "/goods/exchange",
    params
  })
}