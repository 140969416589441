// 登录相关接口
import service from ".."


// 获取短信验证码(图形码)
export function smsSendByImg (params) {
  return service({
    method: 'GET',
    url: "/send",
    params
  })
}

// 注册
export function register (params) {
  return service({
    method: 'GET',
    url: "/account/register",
    params
  })
}

// 登录
export function login (params) {
  return service({
    method: 'GET',
    url: "/account/login",
    params
  })
}


// 忘记密码
export function findPwd (params) {
  return service({
    method: 'GET',
    url: "/account/find_pwd",
    params
  })
}

// 退出登录
export function loginOut (params) {
  return service({
    method: 'GET',
    url: "/account/login_out",
    params
  })
}




