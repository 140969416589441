import { Row, Col, message, Radio, Form, Select, InputNumber, Modal, Table, Avatar, Pagination } from 'antd'
import './index.scss'
import { useSearchParams, Link } from 'react-router-dom'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { luckChaseInfo, luckChaseStart, luckChaseStop, luckStakeInfo, luckStakeStart, luckStakeStop, luckModeSave } from '@/request/api/index'
import { getGameName } from '@/utils'
import selectIcon from '@/assets/luckGame/select.png'
import selectedIcon from '@/assets/luckGame/selected.png'
import coinIcon from '@/assets/coin-icon.png'
import { set } from 'mobx'

const LuckGameAuto = React.forwardRef((props, ref) => {
  const [search] = useSearchParams()


  useEffect(() => {
    // 获取数据
    luckChaseInfoFun('init')
  }, [])



  // 组件已挂载，可以安全使用useImperativeHandle
  useImperativeHandle(ref, () => ({
    // 自定义的ref行为
    childMethod: () => {
      // 子组件的方法
    }
  }))

  // 获取数据
  const [form] = Form.useForm()
  let [data, setData] = useState({ mode_list: [] })
  const luckChaseInfoFun = async (getType) => {
    const res = await luckChaseInfo({
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      if (getType == 'init') {
        res.data.start_pid = res.data.mode_list[0].id

        form.setFieldsValue({ ...res.data })
      } else {
        res.data.start_pid = data.start_pid
      }
      setData(res.data)
    }
  }

  const changeType = (curType) => {
    if (curType == data.type) {
      return
    }
    setData({ ...data, type: curType })
  }

  const submitFun = async (values) => {
    let res = null
    if (data.status == 2) {
      if (!values.start_pid) {
        message.warning('请选择开始模式')
        return
      }
      res = await luckChaseStart({
        ...values,
        type: search.get('type'),
        method: data.type,
        total_periods: data.tot_periods,
        max_bean: data.max_gold,
        min_bean: data.min_gold,
        spid: data.start_pid
      })
    }
    if (data.status == 1) {
      res = await luckChaseStop({
        type: search.get('type')
      })
    }

    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      luckChaseInfoFun()
      props.context.topInfo()
      message.success(data.status == 2 ? '已开启自动追号' : '已停止自动追号')
    }
  }

  const [autoType, setAutoType] = useState('add')


  //获取数据 
  let [joinData, setJoinData] = useState({ mode_list: [] })
  let [mode_list, setModeList] = useState([])
  const luckStakeInfoFun = async (getType) => {
    const res = await luckStakeInfo({
      type: search.get('type')
    })
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      if (getType == 'init') {
        res.data.start_pid = res.data.mode_list[0].id
        setCurModeTotal(res.data.mode_list[0].total)

        form.setFieldsValue({ ...res.data })
      } else {
        res.data.start_pid = data.start_pid
      }
      setJoinData(res.data)
      setModeList(res.data.mode_list)
    }
  }

  let [curModeTotal, setCurModeTotal] = useState(0)
  const modeChange = (pid) => {
    setCurModeTotal(data.mode_list.filter(item => item.pid == pid)[0].total)
  }

  const changeModeList = (curItem, e, type) => {
    if (type == 'win') {
      mode_list.map(item => {
        if (item.id == curItem.id) {
          item.wid = e
        }
      })
      luckModeSave({
        id: curItem.id,
        win: e,
        lose: curItem.lid,
        type: search.get('type')
      })
    }
    if (type == 'lose') {
      mode_list.map(item => {
        if (item.id == curItem.id) {
          item.lid = e
        }
      })
      luckModeSave({
        id: curItem.id,
        win: curItem.wid,
        lose: e,
        type: search.get('type')
      })
    }
    setModeList(JSON.parse(JSON.stringify(mode_list)))
  }

  const submitJoinFun = async (values) => {
    let res = null
    if (joinData.status == 2) {
      res = await luckStakeStart({
        ...values,
        type: search.get('type'),
        method: joinData.type,
        spid: joinData.start_pid
      })
    }
    if (joinData.status == 1) {
      res = await luckStakeStop({
        type: search.get('type')
      })
    }

    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      luckStakeInfoFun()
      props.context.topInfo()
      message.success(joinData.status == 2 ? '已开启自动投注' : '已停止自动投注')
    }
  }

  const changeAutoType = (value) => {
    setAutoType(value)
    if (value == 'add') {
      luckChaseInfoFun('init')
    } else {
      luckStakeInfoFun('init')
    }
  }


  return (
    <div className='auto-container'>
      <Radio.Group
        block
        options={[
          {
            label: '自动追号',
            value: 'add',
          },
          {
            label: '自动投注',
            value: 'join',
          }
        ]}
        defaultValue="add"
        optionType="button"
        buttonStyle="solid"
        className={`auto-type ${search.get('type')}`}
        onChange={(e) => changeAutoType(e.target.value)}
      />
      {
        autoType == 'add' &&
        <Form className='left-main'
          form={form}
          onFinish={submitFun}>
          <div className='auto-main'>
            <div className='auto-main-con'>
              <Form.Item name='start_pid' label="选择开始模式：">
                <Select className='form-input' placeholder='请选择'>
                  {
                    data.mode_list.map(item => {
                      return <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>
              <Form.Item name='scid' label="开始期数：">
                <InputNumber />
              </Form.Item>
              <Form.Item className='gray-font'>设置倍数，追号倍数最高10倍，单期投入上限2亿金豆</Form.Item>
              <Form.Item name='mult' label="追加倍数：">
                <InputNumber step={0.1} />
              </Form.Item>
              <Form.Item className='gray-font'>达到以下条件之一即停止投入</Form.Item>
              <Form.Item name='tot_periods' label="投入期数：">
                <InputNumber />
              </Form.Item>
              <Row>
                <Col>
                  <Form.Item name='min_gold' label="金豆最小值">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col className='tip'>账户金豆小于该值即停止自动参与（不含保管箱金豆）</Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name='max_gold' label="金豆最大值">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col className='tip'>账户金豆大于该值即停止自动参与（不含保管箱金豆）</Col>
              </Row>
              <Form.Item className='gray-font'>自动追号追中设置</Form.Item>
              <Row className={`select-ops`}>
                <Col onClick={() => changeType(1)}>
                  <span>停止自动追号：</span>
                  {
                    data.type == 1 && <img src={selectedIcon} />
                  }
                  {
                    data.type == 2 && <img src={selectIcon} />
                  }
                </Col>
                <Col onClick={() => changeType(2)}>
                  <span>从开始模式重新开始追号：</span>
                  {
                    data.type == 2 && <img src={selectedIcon} />
                  }
                  {
                    data.type == 1 && <img src={selectIcon} />
                  }
                </Col>
              </Row>
            </div>
          </div>
          <Form.Item>
            {
              data.status == 1 && <button type='primary' className={`auto-btn outline ${search.get('type')}`} htmlType='submit' >停止自动追号</button>
            }
            {
              data.status == 2 && <button type='primary' className={`auto-btn ${search.get('type')}`} htmlType='submit'>开始自动追号</button>
            }
          </Form.Item>
        </Form>
      }
      {
        autoType == 'join' &&
        <Form className='left-main' form={form} onFinish={submitJoinFun}>
          <div className='auto-join-main'>
            <div className='auto-main-con'>
              <Row>
                <Col>
                  <Form.Item name='start_pid' label="起始模式：">
                    <Select className='form-input' placeholder='请选择' onChange={modeChange}>
                      {
                        data.mode_list.map(item => {
                          return <Select.Option value={item.id} key={item.id}>{item.title}</Select.Option>
                        })
                      }

                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name='scid' label="开始期数：">
                <InputNumber />
              </Form.Item>
              <Form.Item name='tot_periods' label="投入期数：">
                <InputNumber />
              </Form.Item>
              <Row>
                <Col>
                  <Form.Item name='min_gold' label="金豆最小值">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col className='tip'>金豆余额小于该值即停止自动参与</Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name='max_gold' label="金豆最大值">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col className='tip'>金豆余额大于该值即停止自动参与</Col>
              </Row>
            </div>
          </div>
          <table className='autojoin-list'>
            <tr>
              <th>参与模式</th>
              <th>参与消耗</th>
              <th>赢后使用模式</th>
              <th>输后使用模式</th>
            </tr>
            {
              mode_list.map(item => {
                return <tr key={`table${item.id}`}>
                  <td>{item.title}</td>
                  <td>{item.total}</td>
                  <td>
                    <Select style={{ width: '180px' }} value={item.wid} onChange={(e) => changeModeList(item, e, 'win')}>
                      {
                        mode_list.map(modeItem => {
                          return <Select.Option value={modeItem.id} key={modeItem.id}>{modeItem.title}</Select.Option>
                        })
                      }

                    </Select>
                  </td>
                  <td>
                    <Select style={{ width: '180px' }} value={item.lid} onChange={(e) => changeModeList(item, e, 'lose')}>
                      {
                        mode_list.map(modeItem => {
                          return <Select.Option value={modeItem.id} key={modeItem.id}>{modeItem.title}</Select.Option>
                        })
                      }

                    </Select>
                  </td>
                </tr>
              })
            }
          </table>
          <Form.Item>
            {
              joinData.status == 1 && <button type='primary' className={`auto-btn outline ${search.get('type')}`} htmlType='submit' >停止自动投注</button>
            }
            {
              joinData.status == 2 && <button type='primary' className={`auto-btn ${search.get('type')}`} htmlType='submit'>开启自动投注</button>
            }
          </Form.Item>
        </Form>
      }
    </div>
  )
})

export default LuckGameAuto