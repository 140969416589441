// 返利活动相关接口
import service from ".."

// VIP列表
export function vip (params) {
  return service({
    method: 'GET',
    url: "/activity/vip",
    params
  })
}

// 购买
export function dealVip (params) {
  return service({
    method: 'GET',
    url: "/activity/deal_vip",
    params
  })
}

// 新人首送
export function newChargeInfo (params) {
  return service({
    method: 'GET',
    url: "/activity/new_charge_info",
    params
  })
}

// 加入活动
export function newChargeDeal (params) {
  return service({
    method: 'GET',
    url: "/activity/new_charge_deal",
    params
  })
}

// 首充返利
export function dailyChargeInfo (params) {
  return service({
    method: 'GET',
    url: "/activity/daily_charge_info",
    params
  })
}

// 亏损返利
export function loseRebateInfo (params) {
  return service({
    method: 'GET',
    url: "/activity/lose_rebate_info",
    params
  })
}

// 投注返利
export function flowRebateInfo (params) {
  return service({
    method: 'GET',
    url: "/activity/flow_rebate_info",
    params
  })
}

// 月度返利
export function monthRebateInfo (params) {
  return service({
    method: 'GET',
    url: "/activity/month_rebate_info",
    params
  })
}

// 下线返利
export function friendRebateInfo (params) {
  return service({
    method: 'GET',
    url: "/activity/friend_rebate_info",
    params
  })
}