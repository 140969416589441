import { Row, Col } from 'antd'
import { useState, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useStore } from '@/store'
import { observer } from 'mobx-react-lite'
import home from '@/assets/nav/home.png'
import homeActive from '@/assets/nav/home-active.png'
import rank from '@/assets/nav/rank.png'
import rankActive from '@/assets/nav/rank-active.png'
import change from '@/assets/nav/change.png'
import changeActive from '@/assets/nav/change-active.png'
import invite from '@/assets/nav/invite.png'
import inviteActive from '@/assets/nav/invite-active.png'
import ucenter from '@/assets/nav/ucenter.png'
import ucenterActive from '@/assets/nav/ucenter-active.png'
import activity from '@/assets/nav/activity.png'
import activityActive from '@/assets/nav/activity-active.png'
import hbao from '@/assets/nav/hbao.png'
import hbaoActive from '@/assets/nav/hbao-active.png'
import luckGame from '@/assets/nav/luckGame.png'
import luckGameActive from '@/assets/nav/luckGame-active.png'
import './index.scss'

function TopNav () {
  const { common28Store, utokenStore } = useStore()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [defaultSelected, setDefaultSelected] = useState(pathname)
  useEffect(() => {
    setDefaultSelected(pathname)
    window.scrollTo(0, 0)
  }, [pathname])


  const toLink = (path) => {
    // window.location.href = path
    if (path != defaultSelected) {
      common28Store.setUserInfo()
    }
    navigate(path)
  }


  return (
    <div className='top-nav'>
      <Row className='top-nav' align='middle'>
        <Col className={defaultSelected == '/' ? 'current' : ''} onClick={() => toLink('/')}>
          {
            defaultSelected == '/' &&
            <img src={homeActive} />
          }
          {
            defaultSelected != '/' &&
            <img src={home} />
          }
          <span>首页</span>
        </Col>
        <Col className={/^\/luck/.test(defaultSelected) ? 'current' : ''} onClick={() => toLink(`/luckList?type=js30&game=28`)}>
          {
            /^\/luck/.test(defaultSelected) &&
            <img src={luckGameActive} />
          }
          {
            !(/^\/luck/.test(defaultSelected)) &&
            <img src={luckGame} />
          }
          <span>娱乐游戏</span>
        </Col>
        <Col className={defaultSelected == '/hbao' ? 'current' : ''} onClick={() => toLink('/hbao?nav=nav1')}>
          {
            defaultSelected == '/hbao' &&
            <img src={hbaoActive} />
          }
          {
            defaultSelected != '/hbao' &&
            <img src={hbao} />
          }
          <span>扫雷红包</span>
        </Col>
        <Col className={defaultSelected == '/activity' ? 'current' : ''} onClick={() => toLink('/activity')}>
          {
            defaultSelected == '/activity' &&
            <img src={activityActive} />
          }
          {
            defaultSelected != '/activity' &&
            <img src={activity} />
          }
          <span>返利活动</span>
        </Col>
        <Col className={defaultSelected == '/change' ? 'current' : ''} onClick={() => toLink('/change')}>
          {
            defaultSelected == '/change' &&
            <img src={changeActive} />
          }
          {
            defaultSelected != '/change' &&
            <img src={change} />
          }
          <span>商城兑换</span>
        </Col>
        <Col className={defaultSelected == '/rank' ? 'current' : ''} onClick={() => toLink('/rank')}>
          {
            defaultSelected == '/rank' &&
            <img src={rankActive} />
          }
          {
            defaultSelected != '/rank' &&
            <img src={rank} />
          }
          <span>排行榜</span>
        </Col>
        <Col className={defaultSelected == '/invite' ? 'current' : ''} onClick={() => toLink('/invite')}>
          {
            defaultSelected == '/invite' &&
            <img src={inviteActive} />
          }
          {
            defaultSelected != '/invite' &&
            <img src={invite} />
          }
          <span>邀请返利</span>
        </Col>
        <Col className={defaultSelected == '/ucenter' ? 'current' : ''} onClick={() => toLink('/ucenter')}>
          {
            defaultSelected == '/ucenter' &&
            <img src={ucenterActive} />
          }
          {
            defaultSelected != '/ucenter' &&
            <img src={ucenter} />
          }
          <span>个人中心</span>
        </Col>
      </Row >
    </div>
  )
}

export default observer(TopNav)